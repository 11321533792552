import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import ProviderContext from './ProviderContext';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const FinancialDetails = (props) => {

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik enableReinitialize initialValues={value.provider} >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                noValidate
                {...props}
              >
                <Card>
                  <CardHeader
                    title="Financial details"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Monthly $ w/ Insurance"
                          name="monthlyFinancialCost"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "monthlyFinancialCost", "value")
                          }
                          }
                          value={values.monthlyFinancialCost || ""}
                          variant="outlined"
                          type="number"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Monthly $ out of pocket"
                          name="privatePayMonthlyRate"
                          onChange={(e) => { value.updateProvider(e, "privatePayMonthlyRate", "value") }}
                          value={values.privatePayMonthlyRate || ""}
                          variant="outlined"
                          type="number"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Insurance Accepted"
                          name="insuranceAccepted"
                          onChange={(e) => { value.updateProvider(e, "insuranceAccepted", "key") }}
                          value={values.insuranceAccepted ? values.insuranceAccepted.key : ""}
                          select
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.booleanvalues.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Insurance Policies Accepted"
                          name="insurancePoliciesAccepted"
                          onChange={(e) => { value.updateProvider(e, "insurancePoliciesAccepted", "value") }}
                          value={values.insurancePoliciesAccepted || ""}
                          variant="outlined"
                          helperText={values.insurancePoliciesAccepted ? `Number of characters ${values.insurancePoliciesAccepted.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer>
  )
};

export default FinancialDetails;
