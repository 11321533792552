import React from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  Container
} from '@material-ui/core';
import IndividualFormPublic from 'src/components/individuals/IndividualFormPublic';
import { useLocation } from 'react-router';

const ProductList = () => {
  /* 2. Get the param */
  const { state } = useLocation();
  let props = JSON.parse(state ? state :'{}')
  return (
    <>
      <Helmet>
        <title>Providers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <IndividualFormPublic {...props}
          />
        </Container>
      </Box>
    </>
  )
};

export default ProductList;
