import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Snackbar
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import restApiService from '../../services/restService';
import CloseIcon from "@material-ui/icons/Close";
import { DELETE_PROVIDER } from '../../utils/Constants';
import { makeStyles } from '@material-ui/styles';

const ProductCard = ({ provider, ...rest }) => {
  const styles = {
    flexRight: {
      display: "flex",
      'justifyContent': 'center',
      'width': '100%',
    }
  };
  const useStyles = makeStyles(() => ({
    formControl: {
      display: "flex",
      'justifyContent': 'space-between',
      'width': '100%',
    },
    bolder: {
      fontWeight: 800,
      color: "#333",
      fontSize: '0.9em'
    },
    alingRight: {
      display: "flex",
      justifyContent: "flex-end",
      color: "#4c4c4c",
      fontSize: '0.9em'
    },
    space: {
      paddingTop: "5px"
    },
    spaceText: {
      padding: "2px 0",
    },
    spaceText2: {
      padding: "15px 0",
    },
    grid: {
      display: 'grid',
      gridTempplateColumns: '1fr 1fr'
    },
    flex: {
      display: 'flex',
      'justifyContent': 'space-between'
    }
  }));


  const { callService } = restApiService();
  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };

  const delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const deleteProvider = (id) => {
    callService('DELETE', DELETE_PROVIDER + id, {}, true)
      .then(response => {
        if (response.status == 200) {
          setMessage("The program was successfully removed.");
          handleToOpen();
          delay(function () {
            window.location.reload();
          }, 1000);
        } else {
          var errors = 'ERRORS: ';
          if (response.data.errors) {
            for (var e of response.data.errors) {
              errors = errors + ' ' + e + '';
            }
          } else {
            errors = errors + response.data.error;
          }
          setMessage(errors)
          handleToOpen();
        }
      });
  }

  const renderActionButtonsRead = () => {
    if (provider.permissions.includes('READ')) {
      return (
        <IconButton color="inherit" onClick={() => navigate('/app/show-provider/' + provider.id)}>
          <VisibilityIcon color="action" />
        </IconButton>
      );
    }
  }

  const renderActionButtonsDelete = () => {
    if (provider.permissions.includes('DELETE')) {
      return (
        <IconButton color="inherit" onClick={() => { setConfirmOpen(true) }}>
          <DeleteIcon color="action" />
        </IconButton>
      );
    }
  }

  const renderActionButtonsChangePassword = () => {
    if (provider.permissions.includes('WRITE')) {
      return (
        <IconButton color="inherit" onClick={() => navigate('/app/edit-provider/' + provider.id)}>
          <EditIcon color="action" />
        </IconButton>
      );
    }
  }
  const toTitleCase = (str) => {
    if (str)
      return str.toString().replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
  }

  const fontSizeAdjust = (programName) => {
      if(programName.length > 50) {
        return "h6"
      } else {
        if (programName.length < 30){
          return "h4"
        } else{
          return "h5"
        }
      }
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height:"unset",
        minHeight: 250
      }}
      {...rest}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 1
          }}
        >
        </Box>
        <div style={styles.flex}>
          <Typography
            align='center'
            color="textPrimary"
            gutterBottom
            variant={fontSizeAdjust(provider.programName)}>
            {provider.programName}
          </Typography>
          <div className={classes.space}>
            <Typography className={classes.spaceText} align="left" color="black" component={'span'} variant="body1">
              <div className={classes.formControl}><span className={classes.bolder}>City: </span><span className={classes.alingRight}>{toTitleCase(provider.city ? provider.city : "-")}</span></div>
            </Typography>
            <Typography className={classes.spaceText} align="left" color="black" component={'span'} variant="body1">
              <div className={classes.formControl}><span className={classes.bolder}>State: </span><span className={classes.alingRight}>{toTitleCase(provider.state ? provider.state.value : "-")}</span></div>
            </Typography>
            <Typography className={classes.spaceText} align="left" color="black" component={'span'} variant="body1">
              <div className={classes.formControl}><span className={classes.bolder}>Referred Before: </span><span className={classes.alingRight}>{toTitleCase(provider.opgReferredBefore ? provider.opgReferredBefore.value : "-")}</span></div>
            </Typography>
            <Typography className={classes.spaceText} align="left" color="black" component={'span'} variant="body1">
              <div className={classes.formControl}><span className={classes.bolder}>Age Range: </span><span className={classes.alingRight}>{toTitleCase(provider.ageRange ? provider.ageRange.value : "-")}</span></div>
            </Typography>
            <Typography className={classes.spaceText} align="left" color="black" component={'span'} variant="body1">
              <div className={classes.formControl}><span className={classes.bolder}>Gender: </span><span className={classes.alingRight}>{toTitleCase(provider.gender ? provider.gender.value : "-" )}</span></div>
            </Typography>
          </div>
        </div>
        <div style={styles.flexRight}>
          {renderActionButtonsRead()}
          {renderActionButtonsDelete()}
          {renderActionButtonsChangePassword()}
          <ConfirmDialog
            title="Delete provider"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={(e) => { deleteProvider(provider.id) }}
          >
            Are you sure you want to delete {provider.programName}  ?
          </ConfirmDialog>
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
            backgroundColor: "red"
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={handleToClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleToClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </CardContent>
    </Card>
  )
};

ProductCard.propTypes = {
  provider: PropTypes.object.isRequired
};

export default ProductCard;
