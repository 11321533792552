import { useState, useEffect } from 'react';
import * as React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Snackbar,
  TextField,
  IconButton,
  Alert
} from '@material-ui/core';
import { GET_ROLE, PUT_USER, GET_USER } from '../../utils/Constants';
import restApiService from '../../services/restService';
import { User } from 'react-feather';
import * as Yup from 'yup';
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

const UserEditFormComponent = (props) => {
  const { callService } = restApiService();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: ''
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');


  useEffect(() => {
    let isMounted = true;
    callService('GET', GET_ROLE, {}, true)
      .then(response => {
        if (isMounted)
          if (response.status == 200) {
            setRoles([...response.data], response.data)
          } else {
            setMessage(response.data.message);
            setSeverity("error")
            handleToOpen();
          }
      });
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (props.id) {
      callService('GET', GET_USER + props.id, {}, true)
        .then(response => {
          if (isMounted)
            if (response.status == 200) {
              let role = response.data.roles[0].id
              response.data.roles = role
              setUser({ ...response.data }, response.data)
            } else {
              setMessage(response.data.message);
              setSeverity("error")
              handleToOpen();
            }
        });
    }
    return () => { isMounted = false };
  }, [])

  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };

  const delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  return (
    <Container maxWidth={false}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...user }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(255).required('First name is required'),
          lastName: Yup.string().max(255).required('Last name is required'),
          roles: Yup.string().max(255).required('Role is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
        })}
        onSubmit={(values, actions) => {
          let request = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roleIds: [parseInt(values.roles)]
          }
          callService('PUT', PUT_USER + props.id, { data: request }, true)
            .then(response => {
              if (response.status === 200) {
                setMessage("The user " + request.email + " has been updated successfully");
                handleToOpen();
                delay(function () {
                  navigate('/app/users');
                }, 3000);
              } else {
                var errors = 'ERRORS: ';
                if (response.data.errors) {
                  for (var e of response.data.errors) {
                    errors = errors + ' ' + e + '';
                  }
                } else {
                  errors = errors + response.data.error;
                }
                setMessage(errors)
                handleToOpen();
                actions.setSubmitting(false);
              }
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      label="First name"
                      name="firstName"
                      onChange={handleChange}
                      required
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      label="Last name"
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email}
                      variant="outlined"
                      type="email"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      id="select-role"
                      name="role"
                      error={Boolean(touched.roles && errors.roles)}
                      helperText={touched.roles && errors.roles}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('roles', e.target.value)
                        handleChange(e)
                      }
                      }
                      label="Select Role"
                      value={values.roles || ""}
                      required
                      variant="outlined"
                      select
                      fullWidth
                    >
                      {roles.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={() => { navigate('/app/users') }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                  onPress={isSubmitting = true}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }} open={open} autoHideDuration={3000} onClose={handleToClose} >
        <Alert variant="filled" onClose={handleToClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserEditFormComponent;
