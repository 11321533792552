import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import UserContext from '../users/UserContext';

const UserListToolbar = (props) => {
  const navigate = useNavigate();
  const routeChange = () =>{
    navigate('/app/add-user');
  }

  return (
    <UserContext.Consumer>
      {
        (value) => (
          <Box {...props}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={routeChange}
              >
                Add user
              </Button>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Box>
                    <Grid container  spacing={3}>
                      <Grid item xs={10}>
                        <Box sx={{ pt: 3 }}>
                          <TextField
                              fullWidth
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon
                                      fontSize="small"
                                      color="action"
                                    >
                                    <SearchIcon />
                                    </SvgIcon>
                                  </InputAdornment>
                                )
                              }}
                              onChange={(e) => {
                                let request = {...value.usersRequest}
                                request.query = e.target.value
                                value.setUsersRequest(request)
                              }}
                              placeholder="Search users"
                              variant="outlined"
                            />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box sx={{ pt: 3 }}>
                          <TextField
                            id="handicap-accessible-select"
                            label="Sort by"
                            SelectProps={{ native: true }}
                            variant="outlined"
                            select
                            fullWidth
                            onChange={(e) => {
                              let request = {...value.usersRequest}
                              switch(parseInt(e.target.value)) {
                                case 1:
                                  request.sorts = [{
                                    asc: true,
                                    orderField: 'firstName'
                                  }]
                                  break;
                                case 2:
                                  request.sorts = [{
                                    asc: false,
                                    orderField: 'firstName'
                                  }]
                                  break;
                                case 3:
                                  request.sorts = [{
                                    asc: true,
                                    orderField: 'lastName'
                                  }]
                                break;
                                case 4:
                                  request.sorts = [{
                                    asc: false,
                                    orderField: 'lastName'
                                  }]
                                break;
                                case 5:
                                  request.sorts = [{
                                    asc: true,
                                    orderField: 'email'
                                  }]
                                break;
                                case 6:
                                  request.sorts = [{
                                    asc: false,
                                    orderField: 'email'
                                  }]
                                break;
                                case 7:
                                  request.sorts = [{
                                    asc: true,
                                    orderField: 'role'
                                  }]
                                break;
                                case 8:
                                  request.sorts = [{
                                    asc: false,
                                    orderField: 'role'
                                  }]
                                break;
                                default:
                                  request.sorts = [{
                                    asc: true,
                                    orderField: 'firstName'
                                  }]
                              }
                              value.setUsersRequest(request);
                            }}
                          >
                            <option key={1} value={1}>{'First Name A-Z'}</option>
                            <option key={2} value={2}>{'First Name Z-A'}</option>
                            <option key={3} value={3}>{'Last Name A-Z'}</option>
                            <option key={4} value={4}>{'Last Name Z-A'}</option>
                            <option key={5} value={5}>{'E-mail A-Z'}</option>
                            <option key={6} value={6}>{'E-mail Z-A'}</option>
                            <option key={7} value={7}>{'Role A-Z '}</option>
                            <option key={8} value={8}>{'Role Z-A '}</option>
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )
      }
    </UserContext.Consumer>
)};

export default UserListToolbar;
