import React, { useEffect, useState, useContext } from "react";
import IndividualsContext from "./IndividualsContext";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import restApiService from "src/services/restService";
import {
  FILTER_STATE,
  FILTER_VALUES,
  GOOGLE_API_KEY,
  PUBLIC_FILTER_VALUES,
} from "src/utils/Constants";
import { Formik } from "formik";
import * as Yup from "yup";

const GeneralInformation = (props) => {
  const contextValue = useContext(IndividualsContext);
  const { placePredictions, placesService, getPlacePredictions } =
    usePlacesService({ apiKey: GOOGLE_API_KEY });
  const { callService } = restApiService();

  const [place, setPlace] = useState();
  const [statesByCountry, setStatesByCountry] = useState({
    1: [],
    2: [],
    3: [],
  });

  const [debounce, setDebounce] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const [delay, setDelay] = useState();

  const [formState, setFormState] = useState({
    firstName: { value: "", required: true, field: "value" },
    lastName: { value: "", required: true, field: "value" },
    groupPractice: { value: "", required: false, field: "value" },
    directPhoneNumber: { value: "", required: true, field: "value" },
    mainPhone: { value: "", required: false, field: "value" },
    emailAddress: { value: "", required: true, field: "value" },
    officeAddress: { value: "", required: true, field: "value" },
    city: { value: "", required: false, field: "value" },
    state: { value: "", required: false, field: "object" },
    zip: { value: "", required: false, field: "value" },
    country: { value: "", required: false, field: "object" },
    type: { value: "", required: true, field: "object" },
    clientAgeRanges: { value: "", required: true, field: "array" },
    website: { value: "", required: false, field: "value" },
    psychologyTodayLink: { value: "", required: false, field: "value" },
    briefSummary: { value: "", required: false, field: "value" },
  });

  const isInvalid = () => {
    let found = false;
    for (const key of Object.keys(formState)) {
      if (
        (formState[key].required && formState[key].value === undefined) ||
        formState.clientAgeRanges.value.length === 0
      ) {
        found = true;
        break;
      }
    }
    return found;
  };

  const handleChangeAndValidation = async (e, value, fieldName, field) => {
    if (debounce) {
      clearTimeout(debounce);
    }
    let id = setTimeout(async () => {
      await value.updateIndividual(e, fieldName, field);
      setFormState((formState) => ({
        ...formState,
        [fieldName]: {
          required: formState[fieldName].required,
          value: e.target.value,
          field: formState[fieldName].field,
        },
      }));
    }, 15);
    setDebounce(id);
  };

  const handleChangeAndValidationWithoutEvent = async (
    value,
    fieldName,
    values
  ) => {
    if (debounce) {
      clearTimeout(debounce);
    }
    let id = setTimeout(async () => {
      await value.updateIndividualWithoutEvent(fieldName, values);
      setFormState((formState) => ({
        ...formState,
        [fieldName]: {
          required: formState[fieldName].required,
          value: values,
          field: formState[fieldName].field,
        },
      }));
    }, 15);
    setDebounce(id);
  };

  useEffect(() => {
    let isMounted = true;
    let url = props.token
      ? PUBLIC_FILTER_VALUES(props.token, FILTER_STATE)
      : FILTER_VALUES + FILTER_STATE;
    callService("GET", url, {}, true, props.token).then((response) => {
      if (isMounted) {
        if (response && response.status === 200) {
          if (formState.country.value) {
            let states = { 1: [...response.data], 2: [], 3: [] };
            setStatesByCountry(states);
          } else {
            let states = { 1: [...response.data], 2: [], 3: [] };
            setStatesByCountry(states);
          }
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (formState.country.value) {
      setFormState((formState) => ({
        ...formState,
        state: {
          value: formState.country.value === 1 ? formState.state.value : 0,
        },
      }));
      if (formState.country.value !== 1) {
        handleChangeAndValidation(
          { target: { value: '' } },
          contextValue,
          "state",
          "object"
        );
      }
    }
  }, [formState.country]);

  useEffect(() => {
    if (contextValue) {
      if (delay) {
        clearTimeout(delay);
      }

      let id = setTimeout(() => {
        contextValue.updateInvalidForm(isInvalid());
      }, 500);

      setDelay(id);
    }
  }, [formState]);

  const handleFormState = (individual) => {
    setFirstTime(false);
    let newState = {};
    for (const key of Object.keys(formState)) {
      newState[key] = {
        ...formState[key],
        value:
          formState[key].field === "value" || formState[key].field === "array"
            ? individual[key]
            : individual[key]
            ? individual[key]["key"]
            : individual[key],
      };
    }
    setFormState(newState);
  };

  useEffect(() => {
    let isMounted = true;
    if (firstTime && isMounted) {
      handleFormState(contextValue.individual);
    }
    return () => {
      isMounted = false;
    };
  }, [contextValue]);

  useEffect(() => {
    if (place) {
      parseOfficeAddress(place, "city", "zip", "state");
    }
  }, [place]);

  const parseOfficeAddress = (
    place,
    fieldCityName,
    fieldZipCode,
    fieldState
  ) => {
    let countryPlaceIndex = place.address_components.findIndex((e) => {
      return e.types.findIndex((t) => t?.toLowerCase() === "country") >= 0;
    });

    let statePlaceIndex = place.address_components.findIndex((e) => {
      return (
        e.types.findIndex(
          (t) => t?.toLowerCase() === "administrative_area_level_1"
        ) >= 0
      );
    });

    let cityPlaceIndex = place.address_components.findIndex((e) => {
      return (
        e.types.findIndex(
          (t) =>
            t?.toLowerCase() === "locality" || t?.toLowerCase() == "sublocality"
        ) >= 0
      );
    });

    let postalCodePlaceIndex = place.address_components.findIndex((e) => {
      return e.types.findIndex((t) => t?.toLowerCase() === "postal_code") >= 0;
    });

    let mapValues = {};

    if (countryPlaceIndex > -1) {
      let t1 = place.address_components[countryPlaceIndex].short_name;
      let key = 3;
      switch (t1.toLowerCase()) {
        case "US".toLowerCase():
          key = 1;
          break;
        case "CA".toLowerCase():
          key = 2;
          break;
        default:
          break;
      }
      let countryIndex = props.country.findIndex((e) => {
        return e.key === key;
      });

      mapValues["country"] = props.country[countryIndex];

      // Checking if country is United States
      if (mapValues["country"].key === 1) {
        if (statePlaceIndex > -1) {
          let t2 = place.address_components[statePlaceIndex].long_name;
          let stateIndex = statesByCountry[mapValues["country"].key].findIndex(
            (e) => {
              return e.value.toLowerCase() === t2.toLowerCase();
            }
          );
          mapValues["state"] =
            statesByCountry[mapValues["country"]?.key][stateIndex];
        }
      }

      if (cityPlaceIndex > -1) {
        mapValues[fieldCityName] =
          place.address_components[cityPlaceIndex].long_name;
      }

      if (postalCodePlaceIndex > -1) {
        mapValues[fieldZipCode] =
          place.address_components[postalCodePlaceIndex].long_name;
      }
      
      let r = [
        {
          value: mapValues["country"].key,
          fieldName: "country",
          keyOrValue: "object",
        },
        {
          value: mapValues[fieldState]?.key || "",
          fieldName: fieldState,
          keyOrValue: "object",
        },
        {
          value: mapValues[fieldZipCode],
          fieldName: fieldZipCode,
          keyOrValue: "value",
        },
        {
          value: mapValues[fieldCityName],
          fieldName: fieldCityName,
          keyOrValue: "value",
        },
      ];
      
      contextValue.updateIndividualBulk(r);
    }
  };

  return (
    <IndividualsContext.Consumer>
      {(value) => (
        <Formik
          enableReinitialize
          initialValues={value.individual}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required(
              "First Name is required and unique"
            ),
            lastName: Yup.string().required("Last Name is required and unique"),
            directPhoneNumber: Yup.string().required(
              "Direct Phone Number is required"
            ),
            emailAddress: Yup.string().required("Email Address is required"),
            officeAddress: Yup.string().required(
              "Office Address is required and unique"
            ),
            type: Yup.string().required("Type is required"),
            clientAgeRanges: Yup.string().required(
              "Client Age Ranges is required"
            ),
          })}
        >
          {({ errors, handleBlur, handleChange, touched, values }) => (
            <form autoComplete="off">
              <Card>
                <CardHeader title="General Information" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="First Name"
                        name="firstName"
                        onBlur={handleBlur}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={
                          `Number of characters ${values.firstName.length}/255` ||
                          (touched.firstName && errors.firstName)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "firstName",
                            "value"
                          );
                        }}
                        value={values.firstName || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Last Name"
                        name="lastName"
                        onBlur={handleBlur}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={
                          `Number of characters ${values.lastName.length}/255` ||
                          (touched.lastName && errors.lastName)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "lastName",
                            "value"
                          );
                        }}
                        value={values.lastName || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Group Practice"
                        name="groupPractice"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.groupPractice && errors.groupPractice
                        )}
                        helperText={
                          `Number of characters ${values.groupPractice.length}/255` ||
                          (touched.groupPractice && errors.groupPractice)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "groupPractice",
                            "value"
                          );
                        }}
                        value={values.groupPractice || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Direct Phone Number"
                        name="directPhoneNumber"
                        error={Boolean(
                          touched.directPhoneNumber && errors.directPhoneNumber
                        )}
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "directPhoneNumber",
                            "value"
                          );
                        }}
                        value={values.directPhoneNumber || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Main Office Number"
                        name="mainPhone"
                        error={Boolean(touched.mainPhone && errors.mainPhone)}
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "mainPhone",
                            "value"
                          );
                        }}
                        value={values.mainPhone || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Email Address"
                        name="emailAddress"
                        type="email"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.emailAddress && errors.emailAddress
                        )}
                        helperText={
                          `Number of characters ${values.emailAddress.length}/255` ||
                          (touched.emailAddress && errors.emailAddress)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "emailAddress",
                            "value"
                          );
                        }}
                        value={values.emailAddress || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        disableClearable
                        fullWidth
                        options={placePredictions ? placePredictions : []}
                        isOptionEqualToValue={(option) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        onChange={(e, v) => {
                          if (v) {
                            placesService?.getDetails(
                              { placeId: v.place_id },
                              (place) => {
                                value.updateIndividual(
                                  {
                                    target: {
                                      value: {
                                        latitude: place.geometry.location.lat(),
                                        longitude:
                                          place.geometry.location.lng(),
                                        officeAddress: v.description,
                                      },
                                    },
                                  },
                                  ["latitude", "longitude", "officeAddress"],
                                  "array"
                                );
                                setFormState((formState) => ({
                                  ...formState,
                                  officeAddress: {
                                    required: formState.officeAddress.required,
                                    value: v.description,
                                    field: formState.officeAddress.field,
                                  },
                                }));
                                setPlace(place);
                              }
                            );
                          } else {
                            value.updateIndividual(
                              {
                                target: {
                                  value: {
                                    latitude: 0,
                                    longitude: 0,
                                    officeAddress: "",
                                  },
                                },
                              },
                              ["latitude", "longitude", "officeAddress"],
                              "array"
                            );
                          }
                        }}
                        onInputChange={(e, values) => {
                          if (e) {
                            getPlacePredictions({ input: e.target.value });
                            handleChange(e);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            label="Office Address"
                            name="officeAddress"
                            variant="outlined"
                          />
                        )}
                        inputValue={values.officeAddress || ""}
                        value={values.officeAddress || ""}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Type"
                        name="type"
                        select
                        error={Boolean(touched.type && errors.type)}
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(e, value, "type", "object");
                        }}
                        value={values.type?.key || values.type || ""}
                        variant="outlined"
                      >
                        {props.type.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="client-age-ranges-1">
                          {props.label}
                        </InputLabel>
                        <Autocomplete
                          multiple
                          id="client-age-ranges"
                          options={props.clientAgeRanges.map(
                            (option) => option.value
                          )}
                          defaultValue={() => {
                            let selectedValues = [];
                            values.clientAgeRanges.forEach(
                              (elementSelected) => {
                                props.clientAgeRanges.forEach((element) => {
                                  if (elementSelected === element.key) {
                                    selectedValues.push(element.value);
                                  }
                                });
                              }
                            );
                            return selectedValues;
                          }}
                          filterSelectedOptions
                          onChange={async (e, selectedValues) => {
                            let newValues = [];
                            debugger
                            props.clientAgeRanges.forEach((element) => {
                              selectedValues.forEach((selectedElement) => {
                                if (selectedElement === element.value)
                                  newValues.push(element.key);
                              });
                            });
                            handleChange(e);
                            handleChangeAndValidationWithoutEvent(
                              value,
                              "clientAgeRanges",
                              newValues
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Client Age Ranges"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Website"
                        name="website"
                        onBlur={handleBlur}
                        error={Boolean(touched.website && errors.website)}
                        helperText={
                          `Number of characters ${values.website.length}/255` ||
                          (touched.website && errors.website)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "website",
                            "value"
                          );
                        }}
                        value={values.website || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Psychology Today Link"
                        name="psychologyTodayLink"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.psychologyTodayLink &&
                            errors.psychologyTodayLink
                        )}
                        helperText={
                          `Number of characters ${values.psychologyTodayLink.length}/255` ||
                          (touched.psychologyTodayLink &&
                            errors.psychologyTodayLink)
                        }
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "psychologyTodayLink",
                            "value"
                          );
                        }}
                        value={values.psychologyTodayLink || ""}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Brief Summary"
                        name="briefSummary"
                        multiline
                        rows={6}
                        error={Boolean(
                          touched.briefSummary && errors.briefSummary
                        )}
                        onChange={async (e) => {
                          handleChange(e);
                          handleChangeAndValidation(
                            e,
                            value,
                            "briefSummary",
                            "value"
                          );
                        }}
                        value={values.briefSummary || ""}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      )}
    </IndividualsContext.Consumer>
  );
};

export default GeneralInformation;
