import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
} from "@material-ui/core";
import IndividualsContext from "./IndividualsContext";
import { Formik } from "formik";

const Shared = ({ singleType }) => {
  const [showInputs, setShowInputs] = useState(false);
  const contextValue = useContext(IndividualsContext);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && contextValue.individual.enabledShared?.key === 1) {
        setShowInputs(true)
    }
    return () => { isMounted = false };
  }, [contextValue])

  return (
    <IndividualsContext.Consumer>
      {(value) => (
        <Formik enableReinitialize initialValues={value.individual}>
          {({ errors, handleBlur, handleChange, touched, values }) => (
            <form autoComplete="off" noValidate>
              <Card>
                <CardHeader title="Shared" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Enable share?"
                        name="enabledShared"
                        select
                        error={Boolean(
                          touched.enabledShared && errors.enabledShared
                        )}
                        value={values.enabledShared?.key || values.enabledShared || ""}
                        onChange={async (e) => {
                            if (e.target.value === 1) {
                                setShowInputs(true)
                            } else {
                                setShowInputs(false)
                            }
                            value.updateIndividual(e, "enabledShared", "object")
                        }}
                      >
                        {singleType.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {showInputs && <Grid item md={6} xs={6}>
                          <TextField
                              fullWidth
                              label="Email"
                              name="enabledSharedData"
                              onBlur={handleBlur}
                              error={Boolean(
                              touched.enabledSharedData &&
                                  errors.enabledSharedData
                              )}
                              helperText={
                              `Number of characters ${values.enabledSharedData.length}/255` ||
                              (touched.enabledSharedData &&
                                  errors.enabledSharedData)
                              }
                              onChange={(e) => {
                              handleChange(e);
                              value.updateIndividual(
                                  e,
                                  "enabledSharedData",
                                  "value"
                              );
                              }}
                              value={values.enabledSharedData || ""}
                              variant="outlined"
                          />
                      </Grid>
                    } 
                    {showInputs && <Grid item md={6} xs={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="enabledSharedDataName"
                            onBlur={handleBlur}
                            error={Boolean(
                            touched.enabledSharedDataName &&
                                errors.enabledSharedDataName
                            )}
                            helperText={
                            `Number of characters ${values.enabledSharedDataName.length}/255` ||
                            (touched.enabledSharedDataName &&
                                errors.enabledSharedDataName)
                            }
                            onChange={(e) => {
                            handleChange(e);
                            value.updateIndividual(
                                e,
                                "enabledSharedDataName",
                                "value"
                            );
                            }}
                            value={values.enabledSharedDataName || ""}
                            variant="outlined"
                        />
                    </Grid>
                    }
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      )}
    </IndividualsContext.Consumer>
  );
};

export default Shared;
