import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import IndividualsContext from "src/components/individuals/IndividualsContext"
import IndividualsListToolbar from "src/components/product/IndividualsListToolbar"
import ProductFilter from "src/components/individuals/FilterComponent"
import IndividualCard from "src/components/product/IndividualCard"
import useDebounce from 'src/components/helpers/debounceTime'
import { INDIVIDUALS_URL } from "src/utils/Constants"
import restApiService from "src/services/restService"
import TablePaginator from "src/components/shared/TablePaginator"


const IndividualsList = ({ individual, ...rest }) => {
  const { callService } = restApiService()
  
  const styles = {
    flexCenter: {
      display: "flex",
      "justifyContent": "center",
      "alignContent": "center",
      "width": "100%",
      "height": "100%"
    },
    grid: {
      display: "flex",
      "justifyContent": "center",
      padding: "50px"
    }
  }
  
  // Data received from the backend
  const [individualsResponse, setIndividualsResponse] = useState({
      listOfElements: [],
      pageNumber: 0,
      totalOfElements: 0,
      totalPages: 0
  })

  const [request, setRequest] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState("")
  const debouncedSearchTerm = useDebounce(request, 500)

  useEffect(() => {
    let isMounted = true
    if (debouncedSearchTerm) {
      callService("POST", INDIVIDUALS_URL, { data: debouncedSearchTerm }, true)
        .then(response => {
          setLoading(false)
          if (isMounted) {
            if (response && response.status === 200) {
              setIndividualsResponse({ ...response.data }, response.data)
              if (response && response.data.listOfElements.length === 0) {
                setMessage("There are no individuals that match your criteria. Please remove some of the filters and try again.")
              } else {
                setMessage("")
              }
            }
          }
        })
    }
    return () => { isMounted = false }
  }, [debouncedSearchTerm])
  
  const handleSearch = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      "searchColumn": value.searchColumn
    }))
  }

  const handleSorts = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      "sorts": value.sorts
    }))
  }

  const handleFilter = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      ...value
    }))
  }

  const handleChangePage = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      ...value
    }))
  }

  return(
    <>
      <Helmet>
        <title>Individuals | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3
      }}>
        <Container maxWidth={false}>
          <IndividualsContext.Provider value={{request: request, handleSearch: handleSearch, handleSorts: handleSorts}}>
            <IndividualsListToolbar individuals={individualsResponse} />
          </IndividualsContext.Provider>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <Box>
                <Grid container spacing={1}>
                  <IndividualsContext.Provider value={{ request: request, handleFilter: handleFilter }}>
                    <ProductFilter />
                  </IndividualsContext.Provider>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={10}>
              <Box sx={{ pt:3 }}>
                <Grid container spacing={3}>
                  {
                    individualsResponse?.listOfElements.map((individual) => (
                      <Grid
                        item
                        key={individual.id}
                        lg={6}
                        md={8}
                        xs={12}
                      >
                        <IndividualCard individual={individual} />
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>

              {!loading && message && 
                <div style={styles.grid}>
                  <div style={styles.flexCenter}>
                    <Typography
                      align="left"
                      color="black"
                      variant="body1"
                    >
                      <span>{message}</span>
                    </Typography>
                  </div>
                </div>
              }

              {loading &&
                <div style={styles.grid}>
                  <div style={styles.flexCenter}>
                    <CircularProgress />
                  </div>
                </div>
              }
              
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3
                }}
              >
                <IndividualsContext.Provider value={{}}>
                  <TablePaginator totalOfElements={individualsResponse.totalOfElements} onPageChange={handleChangePage}></TablePaginator>
                </IndividualsContext.Provider>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default IndividualsList