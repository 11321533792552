import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import IndividualsContext from "src/components/individuals/IndividualsContext";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@material-ui/core";
import restApiService from "src/services/restService";
import roleService from "src/components/helpers/RoleChecker";
import {
  INDIVIDUALS_EXPORT_CSV,
  INDIVIDUALS_EXPORT_PDF,
} from "src/utils/Constants";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    height: "100%",
  },
  flex: {
    display: "flex",
    padding: "0.05rem",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  padding: {
    padding: "0.3rem",
  },
};

const IndividualsListToolbar = ({ individuals }) => {
  const contextValue = useContext(IndividualsContext);

  // EXPORTS
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const { callServiceDownload } = restApiService();

  const renderExports = (value) => {
    if (!localStorage.getItem("authorities").includes("READ")) {
      return (
        <div style={styles.flex}>
          {loadingCSV && (
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <CircularProgress style={styles.padding} />
              </div>
            </div>
          )}

          {loadingPDF && (
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <CircularProgress style={styles.padding} />
              </div>
            </div>
          )}

          {!loadingCSV && (
            <Button
              sx={{ mx: 1 }}
              onClick={() => {
                setLoadingCSV(true);
                callExport(
                  INDIVIDUALS_EXPORT_CSV,
                  value,
                  "application/csv",
                  "individuals.csv"
                );
              }}
            >
              Export CSV
            </Button>
          )}
        </div>
      );
    }
  };

  // Calling exports
  const callExport = (endpoint, request, fileType, fileName) => {
    let req = { ...request };
    req.pageSize = 2147483647;
    req.pageIndex = 0;

    callServiceDownload(
      "POST",
      endpoint,
      { data: req },
      true,
      fileType,
      fileName
    )
      .then(() => {
        setLoadingCSV(false);
        setLoadingPDF(false);
      })
      .catch(() => {
        setLoadingCSV(false);
        setLoadingPDF(false);
      });
  };

  // ADD INDIVIDUALS
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(roleService.isStandar());

  const initialState = () => {
    let searchIndividualText = localStorage.getItem("searchIndividualText");

    if (searchIndividualText != null) {
      return JSON.parse(searchIndividualText);
    } else {
      localStorage.setItem("searchIndividualText", JSON.stringify([]));
      return [];
    }
  };

  const initialStateSorts = () => {
    let sort = localStorage.getItem("sort");
    return sort ? parseInt(sort) : 1;
  };  

  const [words, setWords] = useState(initialState());
  const [sortValue, setSortValue] = useState(initialStateSorts());
  const [sorts, setSorts] = useState();

  useEffect(() => {
    let searchColumn = words;
    contextValue.handleSearch({ searchColumn });
  }, [words]);

  useEffect(() => {
    let request = {};

    switch (parseInt(sortValue)) {
      case 1:
        request.sorts = [
          {
            asc: true,
            orderField: "lastName",
          },
        ];
        break;
      case 2:
        request.sorts = [
          {
            asc: false,
            orderField: "lastName",
          },
        ];
        break;
      case 3:
        request.sorts = [
          {
            asc: true,
            orderField: "groupPractice",
          },
        ];
        break;
      case 4:
        request.sorts = [
          {
            asc: false,
            orderField: "groupPractice",
          },
        ];
        break;
      case 5:
        request.sorts = [
          {
            asc: true,
            orderField: "city",
          },
        ];
        break;
      case 6:
        request.sorts = [
          {
            asc: false,
            orderField: "city",
          },
        ];
        break;
      case 7:
        request.sorts = [
          {
            asc: true,
            orderField: "emailAddress",
          },
        ];
        break;
      case 8:
        request.sorts = [
          {
            asc: false,
            orderField: "emailAddress",
          },
        ];
        break;
      case 9:
        request.sorts = [
          {
            asc: true,
            orderField: "clientAgeRanges",
          },
        ];
        break;
      case 10:
        request.sorts = [
          {
            asc: false,
            orderField: "clientAgeRanges",
          },
        ];
        break;
      default:
        request.sorts = [
          {
            asc: true,
            orderField: "lastName",
          },
        ];
        break;
    }

    setSorts(request.sorts);
  }, [sortValue]);

  useEffect(() => {
    contextValue.handleSorts({ sorts });
  }, [sorts]);

  return (
    <IndividualsContext.Consumer>
      {(value) => (
        <Box {...individuals}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {renderExports(value)}
            {hasPermission && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/app/add-individual")}
              >
                Add individual
              </Button>
            )}
          </Box>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      <Box sx={{ pt: 3 }}>
                        <Autocomplete
                          multiple
                          freeSolo
                          id="tags-outlined"
                          options={words}
                          value={words}
                          onChange={async (e, values) => {
                            setWords(values);
                            localStorage.setItem(
                              "searchIndividualText",
                              JSON.stringify(values)
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search Individuals by Name, Group Practice, Email and Summary"
                              placeholder="Search Individuals by Name, Group Practice, Email and Summary"
                            />
                          )}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box sx={{ pt: 3 }}>
                        <TextField
                          id="handicap-accesible-select"
                          label="Sort by"
                          SelectProps={{ native: true }}
                          variant="outlined"
                          select
                          fullWidth
                          value={sortValue}
                          onChange={async (e) => {
                            localStorage.setItem("sort", e.target.value);
                            setSortValue(e.target.value);
                          }}
                        >
                          <option key={1} value={1}>
                            {"Last Name A-Z"}
                          </option>
                          <option key={2} value={2}>
                            {"Last Name Z-A"}
                          </option>
                          <option key={3} value={3}>
                            {"Group Practice A-Z"}
                          </option>
                          <option key={4} value={4}>
                            {"Group Practice Z-A"}
                          </option>
                          <option key={5} value={5}>
                            {"City A-Z"}
                          </option>
                          <option key={6} value={6}>
                            {"City Z-A"}
                          </option>
                          <option key={7} value={7}>
                            {"Email A-Z"}
                          </option>
                          <option key={8} value={8}>
                            {"Email Z-A"}
                          </option>
                          <option key={9} value={9}>
                            {"Age Ranges A-Z"}
                          </option>
                          <option key={10} value={10}>
                            {"Age Ranges Z-A"}
                          </option>
                        </TextField>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </IndividualsContext.Consumer>
  );
};

export default IndividualsListToolbar;
