import { ROLE_ADMINISTRATOR, ROLE_STANDARD } from "src/utils/Constants"

const checkRole = (props) => {
    const getRoles = () => {
        let roles = localStorage.getItem("authorities")
        if (roles) {
            roles = roles.split(",")
        } else
            roles = []
        return roles
    }
    return (() => {
        let roles = getRoles()
        let found = false
        props.forEach(element => {
            if (roles.indexOf(element) > -1)
                found = true
        });
        return found
    })()
}

const isAdmin = () => {
    return checkRole([ROLE_ADMINISTRATOR])
}
const isStandar = () => {
    return checkRole([ROLE_ADMINISTRATOR, ROLE_STANDARD])
}
const roleService = {
    isAdmin: isAdmin,
    isStandar: isStandar
}

export default roleService;
