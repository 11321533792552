import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Logo from '../components/Logo';
import restApiService from '../services/restService';
import { values } from 'lodash-es';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { PATCH_USER_WITHOUTH_ID } from '../utils/Constants'

const ChangePassword = () => {
  const navigate = useNavigate();
  const { callService } = restApiService();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleToClose = () => {
    setOpen(false);
  };
  
  const handleToOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Login | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.').matches(
                /^.*((?=.*[A-Z]){1}).*$/,"Password must contain at least 1 uppercase characters."
              ),
              confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
            onSubmit={(values, actions) => {
              let request = {
                password: values.password,
                confirmPassword: values.confirmPassword
              }
              callService('PATCH', PATCH_USER_WITHOUTH_ID , { data: request }, true)
                .then(response => {
                  if(response.status == 200){
                    if(response.data.firstLogin){
                      navigate('/change-password');
                    }else{
                      navigate('/app/providers');
                    }
                  }else{
                    setMessage(response.data.message);
                    handleToOpen();
                  }
                });
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Logo />
                <Typography
                    align="left"
                    color="black"
                    variant="body1"
                  >
                  Please change your password:
                </Typography>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm New Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Continue
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
              backgroundColor: "red"
            }}
            open={open}
            autoHideDuration={1000}
            message={message}
            onClose={handleToClose}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleToClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
