import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Grid,
  Slider,
  Autocomplete,
  createFilterOptions,
} from "@material-ui/core";
import restApiService from "../../services/restService";
import {
  POST_PROVIDER,
  GET_PROVIDER,
  PUT_PROVIDER,
  FILTER_BOOL,
  FILTER_GENDER,
  FILTER_AGE_RANGE,
  FILTER_COUNTRY,
  LGBTQ_SERVICE,
  SINGLE_MAYBE,
  SINGLE_OTHER,
  PROGRAM_TYPE,
  BED_ASSINGMENT,
  SHARED,
  OPPORTUNITY_TYPE,
  THERAPY_TYPE,
  MANAGEMENT_TYPE,
  PUBLIC_FILTER_VALUES_DATA,
  PUBLIC_FILTER_VALUES,
  PUBLIC_PUT_PROVIDER,
  GOOGLE_API_KEY,
  FILTER_VALUES_DATA,
  FILTER_VALUES,
  FILTER_STATE,
  FILTER_CITY,
  FILTER_ZIP,
} from "../../utils/Constants";
import ProviderContext from "../provider/ProviderContext";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import { forEach, isArray } from "lodash-es";
import CollapsableItem from "../shared/CollapsableItem";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const useStyles = makeStyles(() => ({
  formControl: {
    display: "flex",
    width: "100%",
  },
  withSpace: {
    withSpace: "unset",
  },
  chip: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  chipSpace: {
    "& > *": {
      margin: "2px",
    },
  },
  chipTitle: {
    fontWeight: 500,
    color: "#333",
  },
  margin: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  clear: {
    display: "inline-block",
    cursor: "pointer",
    margin: "0 !important",
    paddingTop: "15px",
  },
  chipText: {},
  format: {
    color: "#333 !important",
    margin: "2px !important",
    display: "flex !important",
    height: "100% !important",
    padding: "25px 0",
  },
}));

const cleanFilter = {
  address: { value: "" },
  coordinates: { op: "<=>", value: [] },
  programType: { op: "#", value: "" },
  gender: { op: "#", value: "" },
  ageRange: { op: "#", value: "" },
  insuranceAccepted: { op: "#", value: "" },
  lgbtqServiceType: { op: "#", value: [] },
  bedAssigmentType: { op: "#", value: "" },
  elecPermitted: { op: "#", value: "" },
  smokingPermitted: { op: "#", value: "" },
  handicapAccessible: { op: "#", value: "" },
  medComplexityAccepted: { op: "#", value: "" },
  acceptCommitments: { op: "#", value: "" },
  managementType: { op: "#", value: [] },
  opportunityType: { op: "#", value: [] },
  city: { op: "#", value: [] },
  zip: { op: "#", value: [] },
  country: { op: "#", value: [] },
  offerMedAssistedTreatment: { op: "#", value: "" },
};

const validate = (filter) => {
  let keys = Object.keys(cleanFilter);
  let found = true;
  keys.forEach((key) => {
    if (!filter[key]) found = false;
  });
  return found;
};

const FilterComponent = (props) => {
  let address = localStorage.getItem("addressSelected");
  const [addressStateSelected, setAddressSelected] = useState(
    address ? address : ""
  );
  const [addressState, setAddress] = useState(address ? address : "");

  const intialState = () => {
    let searchProvider = localStorage.getItem("searchProvider");

    if (searchProvider != null) {
      return validate(JSON.parse(searchProvider))
        ? JSON.parse(searchProvider)
        : cleanFilter;
    } else {
      localStorage.setItem("searchProvider", JSON.stringify(cleanFilter));
      return cleanFilter;
    }
  };

  const { callService } = restApiService();
  const contextValue = useContext(ProviderContext);
  const [stateClear, setStateClear] = useState(false);
  const [distance, setDistance] = useState(50);
  const [showdistance, setshowdistance] = useState(address ? true : false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: GOOGLE_API_KEY,
    });

  const classes = useStyles();
  const [formState, setFormState] = useState(intialState());
  const [stringState, setStringState] = useState("");
  const [stringCity, setstringCity] = useState("");
  const [stringzips, setstringzips] = useState("");

  const [state, setState] = useState([]);
  const [booleanvalues, setBooleanValues] = useState([]);
  const [programTypes, setProgramtypes] = useState([]);
  const [singleMaybe, setSingleMaybe] = useState([]);
  const [lgbtqServiceType, setLgbtqServiceType] = useState([]);
  const [bedAssigmentTypeList, setBedAssigmentTypeList] = useState([]);
  const [opportunityTypeList, setOpTypeList] = useState([]);
  const [managementTypeList, setManagementTypeList] = useState([]);
  const [gender, setGenders] = useState([]);
  const [ageRange, setAgeRange] = useState([]);
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [zips, setZips] = useState([]);

  // const [lgbtqFriendly, setLgbtqFriendly] = useState([]);
  // const [separateByGenderList, setSeparateByGenderList] = useState([]);
  // const [therapyHoursTypeList, setTherapyHoursTypeList] = useState([]);
  // const [singleType, setSingleType] = useState([]);

  useEffect(() => {
    let isMounted = true;
    Promise.all([
      /*0*/ callService("GET", FILTER_VALUES_DATA + LGBTQ_SERVICE, {}, true),
      /*1*/ callService("GET", FILTER_VALUES_DATA + SINGLE_MAYBE, {}, true),
      /*2*/ callService("GET", FILTER_VALUES_DATA + SINGLE_OTHER, {}, true),
      /*3*/ callService("GET", FILTER_VALUES_DATA + PROGRAM_TYPE, {}, true),
      /*4*/ callService("GET", FILTER_VALUES_DATA + BED_ASSINGMENT, {}, true),
      /*5*/ callService("GET", FILTER_VALUES + FILTER_BOOL, {}, true),
      /*6*/ callService("GET", FILTER_VALUES + OPPORTUNITY_TYPE, {}, true),
      /*7*/ callService("GET", FILTER_VALUES + THERAPY_TYPE, {}, true),
      /*8*/ callService("GET", FILTER_VALUES + MANAGEMENT_TYPE, {}, true),
      /*9*/ callService("GET", FILTER_VALUES_DATA + SHARED, {}, true),
      /*10*/ callService("GET", FILTER_VALUES + FILTER_GENDER, {}, true),
      /*11*/ callService("GET", FILTER_VALUES + FILTER_AGE_RANGE, {}, true),
      /*12*/ callService("GET", FILTER_VALUES + FILTER_COUNTRY, {}, true),
      /*13*/ callService("GET", FILTER_VALUES + FILTER_STATE, {}, true),
      /*12*/ callService("GET", FILTER_VALUES + FILTER_CITY, {}, true),
      /*13*/ callService("GET", FILTER_VALUES + FILTER_ZIP, {}, true),
    ]).then((response) => {
      if (isMounted)
        if (response && response[0].status == 200) {
          setLgbtqServiceType([...response[0].data]);
          // setLgbtqFriendly([...response[1].data])
          setSingleMaybe([...response[1].data]);
          // setSeparateByGenderList([...response[2].data])
          setProgramtypes([...response[3].data]);
          setBedAssigmentTypeList([...response[4].data]);
          setBooleanValues([...response[5].data]);
          setOpTypeList([...response[6].data]);
          // setTherapyHoursTypeList([...response[7].data])
          setManagementTypeList([...response[8].data]);
          // setSingleType([...response[9].data])
          setGenders([...response[10].data]);
          setAgeRange([...response[11].data]);
          setCountry([...response[12].data]);
        }
    });
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (contextValue) {
      changeQuery(contextValue);
      let found = 0;
      for (const key of Object.keys(formState)) {
        if (isArray(formState[key].value)) {
          if (formState[key].isRange) {
            if (
              formState[key].value[0] != formState[key].initial[0] ||
              formState[key].value[1] != formState[key].initial[1]
            ) {
              found = found + 1;
            }
          } else {
            found = found + formState[key].value.length;
          }
        } else {
          if (formState[key].value != "") {
            found = found + 1;
          }
        }
      }

      if (found > 0) {
        setStateClear(true);
      } else {
        setStateClear(false);
      }
    }
  }, [formState]);

  const transformFilter = (state, value, fieldName) => {
    let query = {};
    if (state.value == "") {
      return query;
    } else {
      query[fieldName] = {};
      let f = query[fieldName];
      f.operation = state.op;
      f.value = state.value;
      query[fieldName] = f;
      return query;
    }
  };

  const transformFilterArray = (state, value, fieldName) => {
    let query = {};
    if (
      state.value.length == 0 ||
      (state.isRange &&
        state.value[0] == state.initial[0] &&
        state.value[1] == state.initial[1])
    ) {
      return query;
    } else {
      query[fieldName] = {};
      let f = query[fieldName];
      f.operation = state.op;
      f.value = state.value.join(",");
      query[fieldName] = f;
      return query;
    }
  };

  const handleDelete = (deleteID, fieldName) => {
    let newValue;
    if (isArray(formState[fieldName].value)) {
      if (formState[fieldName].isRange) {
        newValue = formState[fieldName].initial;
      } else {
        let index = formState[fieldName].value.indexOf(deleteID);
        formState[fieldName].value.splice(index, 1);
        newValue = formState[fieldName].value;
      }
    } else {
      newValue = "";
    }
    localStorage.setItem(
      "searchProvider",
      JSON.stringify({
        ...formState,
        [fieldName]: {
          op: formState[fieldName].op,
          initial: formState[fieldName].initial,
          isRange: formState[fieldName].isRange,
          value: newValue,
        },
      })
    );
    setFormState((formState) => ({
      ...formState,
      [fieldName]: {
        op: formState[fieldName].op,
        initial: formState[fieldName].initial,
        isRange: formState[fieldName].isRange,
        value: newValue,
      },
    }));
  };

  const onChangeFilterM = (event, fieldName, value) => {
    let r = {
      ...formState,
      [fieldName]: {
        op: formState[fieldName].op,
        initial: formState[fieldName].initial,
        isRange: formState[fieldName].isRange,
        isArray: formState[fieldName].isArray,
        value: !formState[fieldName].isArray
          ? event.target.value
          : event.target.value
          ? JSON.parse("[" + event.target.value + "]")
          : undefined,
      },
    };
    localStorage.setItem("searchProvider", JSON.stringify(r));
    setFormState(r);
  };

  const changeCooordinates = (e, lat, long) => {
    let newArr = [lat, long, distance];
    localStorage.setItem(
      "searchProvider",
      JSON.stringify({
        ...formState,
        coordinates: {
          op: formState["coordinates"].op,
          value: newArr,
        },
      })
    );
    setFormState((formState) => ({
      ...formState,
      coordinates: {
        op: formState["coordinates"].op,
        value: newArr,
      },
    }));
  };
  const changeDistance = (distance) => {
    let newArr = [
      formState["coordinates"].value[0],
      formState["coordinates"].value[1],
      distance,
    ];
    localStorage.setItem(
      "searchProvider",
      JSON.stringify({
        ...formState,
        coordinates: {
          op: formState["coordinates"].op,
          value: newArr,
        },
      })
    );
    setFormState((formState) => ({
      ...formState,
      coordinates: {
        op: formState["coordinates"].op,
        value: newArr,
      },
    }));
  };
  const changeQuery = async (value) => {
    let query = {};
    Object.keys(formState).forEach((key) => {
      if (isArray(formState[key].value)) {
        query = Object.assign(
          query,
          transformFilterArray(formState[key], value, key)
        );
      } else {
        let respons = transformFilter(formState[key], value, key);
        query = Object.assign(query, respons);
      }
    });
    value.handleFilter({ query });
  };

  const addressChip = () => {
    if (addressStateSelected) {
      localStorage.setItem("addressSelected", addressStateSelected);
      return (
        <Typography component={"span"} id="bed" gutterBottom>
          <div className={classes.chipTitle}>Address:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              key={formState.bedAssigmentType.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                setshowdistance(false);
                setAddress("");
                localStorage.setItem("addressSelected", "");
                localStorage.setItem(
                  "searchProvider",
                  JSON.stringify({
                    ...formState,
                    coordinates: {
                      op: formState["coordinates"].op,
                      value: [],
                    },
                  })
                );
                setFormState((formState) => ({
                  ...formState,
                  coordinates: {
                    op: formState["coordinates"].op,
                    value: [],
                  },
                }));
                setDistance(50);
              }}
              label={addressStateSelected}
            />
          </div>
        </Typography>
      );
    }
  };
  const distanceChip = () => {
    if (distance)
      return (
        <Typography component={"span"} id="bed" gutterBottom>
          <div className={classes.chipTitle}>Distance:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              key={formState.bedAssigmentType.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                setshowdistance(false);
                setAddress("");
                localStorage.setItem(
                  "searchProvider",
                  JSON.stringify({
                    ...formState,
                    coordinates: {
                      op: formState["coordinates"].op,
                      value: [],
                    },
                  })
                );
                setFormState((formState) => ({
                  ...formState,
                  coordinates: {
                    op: formState["coordinates"].op,
                    value: [],
                  },
                }));
                setDistance(50);
              }}
              label={distance + " miles"}
            />
          </div>
        </Typography>
      );
  };

  const countryChips = () => {
    if (country.length > 0)
      if (formState?.country.value.length > 0) {
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>Country:</div>
            <div>
              {formState.country.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    id={data}
                    onDelete={() => {
                      handleDelete(data, "country", contextValue);
                    }}
                    label={((data) => {
                      let index = country.findIndex((s) => s.key === data);
                      return country[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
      }
  };
  const cityChips = () => {
    if (cities.length > 0) {
      if (formState?.city.value.length > 0)
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>City:</div>
            <div>
              {formState.city.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    onDelete={() => {
                      handleDelete(data, "city", contextValue);
                    }}
                    label={((data) => {
                      let index = cities.findIndex((s) => s.key === data);
                      return cities[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
    }
  };
  const stateChips = () => {
    if (state.length > 0) {
      if (formState?.state.value.length > 0)
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>State:</div>
            <div>
              {formState.state.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    onDelete={() => {
                      handleDelete(data, "state", contextValue);
                    }}
                    label={((data) => {
                      let index = state.findIndex((s) => s.key === data);
                      return state[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
    }
  };

  const zipChips = () => {
    if (zips.length > 0) {
      if (formState?.zip.value.length > 0)
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>ZIP Code:</div>
            <div>
              {formState.zip.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    onDelete={() => {
                      handleDelete(data, "zip", contextValue);
                    }}
                    label={((data) => {
                      let index = zips.findIndex((s) => s.key === data);
                      return zips[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
    }
  };
  const genderChips = () => {
    if (formState?.gender.value)
      return (
        <Typography component={"span"} id="gender" gutterBottom>
          <div className={classes.chipTitle}>Gender(s):</div>
          <div>
            <Chip
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.gender.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(formState.gender.value, "gender", contextValue);
              }}
              label={((data) => {
                let index = gender.findIndex((s) => s.key == data);
                if (index > -1) return gender[index].value;
              })(formState.gender.value)}
            />
          </div>
        </Typography>
      );
  };

  const programTypesChips = () => {
    if (formState?.programType.value)
      return (
        <Typography component={"span"} id="programTypes" gutterBottom>
          <div className={classes.chipTitle}>Program Type:</div>
          <div>
            <Chip
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.programType.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.programType.value,
                  "programType",
                  contextValue
                );
              }}
              label={((data) => {
                let index = programTypes.findIndex((s) => s.key == data);
                if (index > -1) return programTypes[index].value;
              })(formState.programType.value)}
            />
          </div>
        </Typography>
      );
  };
  const ageChips = () => {
    if (formState?.ageRange.value)
      return (
        <Typography component={"span"} id="ageRange" gutterBottom>
          <div className={classes.chipTitle}>Ages:</div>
          <div>
            <Chip
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.ageRange.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.ageRange.value,
                  "ageRange",
                  contextValue
                );
              }}
              label={((data) => {
                let index = ageRange.findIndex((s) => s.key == data);
                if (index > -1) return ageRange[index].value;
              })(formState.ageRange.value)}
            />
          </div>
        </Typography>
      );
  };

  const bedChips = () => {
    if (formState?.bedAssigmentType.value)
      return (
        <Typography component={"span"} id="bed" gutterBottom>
          <div className={classes.chipTitle}>Bed Assigment:</div>
          <div>
            <Chip
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.bedAssigmentType.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.bedAssigmentType.value,
                  "bedAssigmentType",
                  contextValue
                );
              }}
              label={((data) => {
                let index = bedAssigmentTypeList.findIndex(
                  (s) => s.key == data
                );
                if (index > -1) return bedAssigmentTypeList[index].value;
              })(formState.bedAssigmentType.value)}
            />
          </div>
        </Typography>
      );
  };

  const lgbtqChips = () => {
    if (lgbtqServiceType.length > 0)
      if (formState?.lgbtqServiceType.value.length > 0) {
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>LGBTQ+ Services:</div>
            <div>
              {formState.lgbtqServiceType.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    id={data}
                    onDelete={() => {
                      handleDelete(data, "lgbtqServiceType", contextValue);
                    }}
                    label={((data) => {
                      let index = lgbtqServiceType.findIndex(
                        (s) => s.key === data
                      );
                      return lgbtqServiceType[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
      }
  };

  const opportunityChip = () => {
    if (opportunityTypeList.length > 0)
      if (formState?.opportunityType.value.length > 0) {
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>Edu/Voc Opportunities:</div>
            <div>
              {formState.opportunityType.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    id={data}
                    onDelete={() => {
                      handleDelete(data, "opportunityType", contextValue);
                    }}
                    label={((data) => {
                      let index = opportunityTypeList.findIndex(
                        (s) => s.key === data
                      );
                      return opportunityTypeList[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
      }
  };

  const managementChip = () => {
    if (managementTypeList.length > 0)
      if (formState?.managementType.value.length > 0) {
        return (
          <Typography component={"span"} id="insuranceAccepted" gutterBottom>
            <div className={classes.chipTitle}>Psych & Rx Mgmt:</div>
            <div>
              {formState.managementType.value.map((data) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(209, 121, 5, 0.66)",
                      },
                    }}
                    className={classes.chipText}
                    key={data}
                    variant="outlined"
                    size="small"
                    id={data}
                    onDelete={() => {
                      handleDelete(data, "managementType", contextValue);
                    }}
                    label={((data) => {
                      let index = managementTypeList.findIndex(
                        (s) => s.key === data
                      );
                      return managementTypeList[index].value;
                    })(data)}
                  />
                );
              })}
            </div>
          </Typography>
        );
      }
  };
  const insuranceAcceptedChips = () => {
    if (formState?.insuranceAccepted.value)
      return (
        <Typography component={"span"} id="insuranceAccepted" gutterBottom>
          <div className={classes.chipTitle}>Insurance Accepted:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.insuranceAccepted.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.insuranceAccepted.value,
                  "insuranceAccepted",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.insuranceAccepted.value)}
            />
          </div>
        </Typography>
      );
  };

  const elecPermittedChips = () => {
    if (formState?.elecPermitted.value)
      return (
        <Typography component={"span"} id="elecPermitted" gutterBottom>
          <div className={classes.chipTitle}>Electronics Permitted:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.elecPermitted.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.elecPermitted.value,
                  "elecPermitted",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.elecPermitted.value)}
            />
          </div>
        </Typography>
      );
  };
  const smokingPermittedChips = () => {
    if (formState?.smokingPermitted.value)
      return (
        <Typography component={"span"} id="smokingPermitted" gutterBottom>
          <div className={classes.chipTitle}>Smoking Permitted:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.smokingPermitted.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.smokingPermitted.value,
                  "smokingPermitted",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.smokingPermitted.value)}
            />
          </div>
        </Typography>
      );
  };

  const handicapAccessibleChips = () => {
    if (formState?.handicapAccessible.value)
      return (
        <Typography component={"span"} id="handicapAccessible" gutterBottom>
          <div className={classes.chipTitle}>Insurance Accepted:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.handicapAccessible.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.handicapAccessible.value,
                  "handicapAccessible",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.handicapAccessible.value)}
            />
          </div>
        </Typography>
      );
  };

  const offerMedAssistedTreatmentChips = () => {
    if (formState?.offerMedAssistedTreatment.value)
      return (
        <Typography
          component={"span"}
          id="offerMedAssistedTreatment"
          gutterBottom
        >
          <div className={classes.chipTitle}>Rx-Assisted Treatment?:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.offerMedAssistedTreatment.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.offerMedAssistedTreatment.value,
                  "offerMedAssistedTreatment",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.offerMedAssistedTreatment.value)}
            />
          </div>
        </Typography>
      );
  };

  const medComplexityAcceptedChips = () => {
    if (formState?.medComplexityAccepted.value)
      return (
        <Typography component={"span"} id="medComplexityAccepted" gutterBottom>
          <div className={classes.chipTitle}>Med. Complexities:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.medComplexityAccepted.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.medComplexityAccepted.value,
                  "medComplexityAccepted",
                  contextValue
                );
              }}
              label={((data) => {
                let index = singleMaybe.findIndex((s) => s.key == data);
                if (index > -1) return singleMaybe[index].value;
              })(formState.medComplexityAccepted.value)}
            />
          </div>
        </Typography>
      );
  };

  const acceptCommitmentsChips = () => {
    if (formState?.acceptCommitments.value)
      return (
        <Typography component={"span"} id="acceptCommitments" gutterBottom>
          <div className={classes.chipTitle}>Inv. Commitments:</div>
          <div>
            <Chip
              sx={{
                "& .MuiSelect-select": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
              }}
              className={classes.chipText}
              key={formState.acceptCommitments.value}
              variant="outlined"
              size="small"
              onDelete={() => {
                handleDelete(
                  formState.acceptCommitments.value,
                  "acceptCommitments",
                  contextValue
                );
              }}
              label={((data) => {
                let index = booleanvalues.findIndex((s) => s.key == data);
                if (index > -1) return booleanvalues[index].value;
              })(formState.acceptCommitments.value)}
            />
          </div>
        </Typography>
      );
  };

  const clearChips = () => {
    if (stateClear)
      return (
        <Typography
          component={"span"}
          id="Clear"
          className={classes.clear}
          gutterBottom
          onClick={handleClear}
        >
          Clear all
        </Typography>
      );
  };

  const handleClear = () => {
    localStorage.setItem("searchProvider", JSON.stringify(cleanFilter));
    setFormState(cleanFilter);
    setAddress("");
    setDistance(50);
    setshowdistance(false);
    setStateClear(false);
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.value,
  });

  return (
    <ProviderContext.Consumer>
      {(value) => (
        <Box sx={{ mt: 3 }} style={{ maxWidth: "100%" }}>
          <Card
            sx={{
              borderBottom: "3px solid",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <CardContent>
              <div className={(classes.chip, classes.chipSpace)}>
                {showdistance && addressChip()}
                {showdistance && distanceChip()}
                {stateChips()}
                {cityChips()}
                {zipChips()}
                {countryChips()}
                {programTypesChips()}
                {genderChips()}
                {ageChips()}
                {insuranceAcceptedChips()}
                {lgbtqChips()}
                {bedChips()}
                {opportunityChip()}
                {managementChip()}
                {elecPermittedChips()}
                {smokingPermittedChips()}
                {handicapAccessibleChips()}
                {medComplexityAcceptedChips()}
                {acceptCommitmentsChips()}
                {offerMedAssistedTreatmentChips()}
                {clearChips()}
              </div>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
            style={{ maxWidth: "100%" }}
          >
            {formState && (
              <CardContent>
                <Grid container spacing={0}>
                  {/* VISIBLE FILTERS */}
                  <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                    <Autocomplete
                      disableClearable
                      fullWidth
                      required
                      options={placePredictions ? placePredictions : []}
                      getOptionLabel={(option) =>
                        option.description ? option.description : ""
                      }
                      value={addressState}
                      inputValue={addressState}
                      onChange={(e, v) => {
                        if (v) {
                          placesService?.getDetails(
                            { placeId: v.place_id },
                            (place) => {
                              setAddress(v.description);
                              setAddressSelected(v.description);
                              changeCooordinates(
                                v.description,
                                place.geometry.location.lat(),
                                place.geometry.location.lng()
                              );
                              setshowdistance(true);
                            }
                          );
                        } else {
                          changeCooordinates("", 0, 0);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          onChange={(e, values) => {
                            getPlacePredictions({ input: e.target.value });
                            setAddress(e.target.value);
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "disabled", // disable autocomplete and autofill
                          }}
                          label="Address"
                          name="address"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {showdistance && (
                    <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                      <Typography
                        component={"span"}
                        id="range-slider"
                        gutterBottom
                      >
                        Distance
                      </Typography>
                      <div className={classes.margin}>
                        <Slider
                          key={`slider-1`}
                          defaultValue={distance}
                          aria-label="Small"
                          min={1}
                          valueLabelDisplay="auto"
                          onChange={(e, values) => {
                            changeDistance(e.target.value);
                            setDistance(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                  {/* ADRESS */}
                  <Grid item md={12} xs={12} style={{ padding: "10px 0" }}>
                    <Autocomplete
                      sx={{
                        "& .MuiChip-deleteIcon": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-tag": {
                          background: "none",
                          color: "#00358E",
                          margin: "0",
                        },
                      }}
                      multiple
                      id="tags-outlined"
                      options={country}
                      getOptionLabel={(option) => option.value}
                      onChange={(e, v) => {
                        onChangeFilterM(
                          { target: { value: v.map((vv) => vv.key) } },
                          "country",
                          value
                        );
                      }}
                      value={(() => {
                        let values = [];
                        for (const key of formState.country.value) {
                          let index = country.findIndex(
                            (stat) => key == stat.key
                          );
                          if (index > -1) {
                            values.push(country[index]);
                          }
                        }
                        return values;
                      })()}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          id="country"
                          variant="outlined"
                          label="Country"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} style={{ padding: "10px 0" }}>
                    <TextField
                      id="gender-select"
                      fullWidth
                      label="Program Types"
                      name="programTypes"
                      onChange={(e) => onChangeFilterM(e, "programType", value)}
                      value={formState.programType.value}
                      select
                      variant="outlined"
                    >
                      <MenuItem key="0" value=""></MenuItem>
                      {programTypes.map((option) => {
                        return (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ padding: "10px 0" }}>
                    <TextField
                      id="gender-select"
                      fullWidth
                      label="Gender(s)"
                      name="gender"
                      onChange={(e) => onChangeFilterM(e, "gender", value)}
                      value={formState.gender.value}
                      select
                      variant="outlined"
                    >
                      <MenuItem key="0" value=""></MenuItem>
                      {gender.map((option) => {
                        return (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ padding: "10px 0" }}>
                    <TextField
                      id="age-select"
                      fullWidth
                      label="Ages"
                      name="age"
                      onChange={(e) => {
                        onChangeFilterM(e, "ageRange", value);
                      }}
                      value={formState.ageRange.value}
                      select
                      variant="outlined"
                    >
                      <MenuItem key="0" value="" />
                      {ageRange.map((option) => {
                        return (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  {/* Program Type  */}
                  {/* VISIBLE FILTERS */}
                  {/* FINANCIAL */}
                  <Grid item md={12} xs={12}>
                    <CollapsableItem
                      title="FINANCIAL"
                      inner={() => (
                        <div style={{ padding: "10px 5px" }}>
                          <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                value={formState.insuranceAccepted.value}
                                label="Insurance Accepted"
                                onChange={(e) =>
                                  onChangeFilterM(e, "insuranceAccepted", value)
                                }
                                select
                                //value={values.state}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {booleanvalues.map((option) => {
                                  if (option.key != 3) {
                                    return (
                                      <MenuItem
                                        key={option.key}
                                        value={option.key}
                                      >
                                        {option.value}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </TextField>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    ></CollapsableItem>
                  </Grid>
                  {/* FINANCIAL */}
                  {/* PROGRAM SPECIFICS */}
                  <Grid item md={12} xs={12}>
                    <CollapsableItem
                      title="PROGRAM SPECIFICS"
                      inner={() => (
                        <div style={{ padding: "10px 5px" }}>
                          <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                              <Autocomplete
                                sx={{
                                  "& .MuiChip-deleteIcon": {
                                    display: "none",
                                  },
                                  "& .MuiAutocomplete-tag": {
                                    background: "none",
                                    color: "#00358E",
                                    margin: "0",
                                  },
                                }}
                                multiple
                                id="tags-outlined"
                                options={lgbtqServiceType}
                                getOptionLabel={(option) => option.value}
                                onChange={(e, v) => {
                                  onChangeFilterM(
                                    {
                                      target: { value: v.map((vv) => vv.key) },
                                    },
                                    "lgbtqServiceType",
                                    value
                                  );
                                }}
                                value={(() => {
                                  let values = [];
                                  for (const key of formState.lgbtqServiceType
                                    .value) {
                                    let index = lgbtqServiceType.findIndex(
                                      (stat) => key == stat.key
                                    );
                                    if (index > -1) {
                                      values.push(lgbtqServiceType[index]);
                                    }
                                  }
                                  return values;
                                })()}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name="lgbtqServiceType"
                                    id="lgbtqServiceType"
                                    variant="outlined"
                                    label="LGBTQ+ Services"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                id="gender-select"
                                fullWidth
                                label="Bed Assignments"
                                name="bedAssigmentType"
                                onChange={(e) =>
                                  onChangeFilterM(e, "bedAssigmentType", value)
                                }
                                value={formState.bedAssigmentType.value}
                                select
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {bedAssigmentTypeList.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              {/* <div className={classes.margin}>
                            <Typography component={'span'}  id="range-slider" gutterBottom>
                              Number of Beds
                            </Typography>
                            <Slider
                              min={bedRange[0]}
                              step={1}
                              max={bedRange[1]}
                              value={formState.numberBeds.value}
                              onChange={(e) => onChangeFilterM(e, 'numberBeds', value)}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                            />
                          </div> */}
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Autocomplete
                                sx={{
                                  "& .MuiChip-deleteIcon": {
                                    display: "none",
                                  },
                                  "& .MuiAutocomplete-tag": {
                                    background: "none",
                                    color: "#00358E",
                                    margin: "0",
                                  },
                                }}
                                multiple
                                id="tags-outlined"
                                options={opportunityTypeList}
                                getOptionLabel={(option) => option.value}
                                onChange={(e, v) => {
                                  onChangeFilterM(
                                    {
                                      target: { value: v.map((vv) => vv.key) },
                                    },
                                    "opportunityType",
                                    value
                                  );
                                }}
                                value={(() => {
                                  let values = [];
                                  for (const key of formState.opportunityType
                                    .value) {
                                    let index = opportunityTypeList.findIndex(
                                      (stat) => key == stat.key
                                    );
                                    if (index > -1) {
                                      values.push(opportunityTypeList[index]);
                                    }
                                  }
                                  return values;
                                })()}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name="opportunityType"
                                    id="opportunityType"
                                    variant="outlined"
                                    label="Edu/Voc Opportunities"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Rx-Assisted Treatment?"
                                name="offerMedAssistedTreatment"
                                onChange={(e) =>
                                  onChangeFilterM(
                                    e,
                                    "offerMedAssistedTreatment",
                                    value
                                  )
                                }
                                select
                                value={
                                  formState.offerMedAssistedTreatment.value
                                }
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {booleanvalues.map((option) => {
                                  if (option.key != 3) {
                                    return (
                                      <MenuItem
                                        key={option.key}
                                        value={option.key}
                                      >
                                        {option.value}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Autocomplete
                                sx={{
                                  "& .MuiChip-deleteIcon": {
                                    display: "none",
                                  },
                                  "& .MuiAutocomplete-tag": {
                                    background: "none",
                                    color: "#00358E",
                                    margin: "0",
                                  },
                                }}
                                multiple
                                id="tags-outlined"
                                options={managementTypeList}
                                getOptionLabel={(option) => option.value}
                                onChange={(e, v) => {
                                  onChangeFilterM(
                                    {
                                      target: { value: v.map((vv) => vv.key) },
                                    },
                                    "managementType",
                                    value
                                  );
                                }}
                                value={(() => {
                                  let values = [];
                                  for (const key of formState.managementType
                                    .value) {
                                    let index = managementTypeList.findIndex(
                                      (stat) => key == stat.key
                                    );
                                    if (index > -1) {
                                      values.push(managementTypeList[index]);
                                    }
                                  }
                                  return values;
                                })()}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name="managementType"
                                    id="managementType"
                                    variant="outlined"
                                    label="Psych & Rx Mgmt"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    ></CollapsableItem>
                  </Grid>
                  {/* PROGRAM SPECIFICS */}
                  {/* OTHER FILTERS */}
                  <Grid item md={12} xs={12}>
                    <CollapsableItem
                      title="OTHER FILTERS"
                      inner={() => (
                        <div style={{ padding: "10px 5px" }}>
                          <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Electronics Permitted"
                                name="elecPermitted"
                                onChange={(e) =>
                                  onChangeFilterM(e, "elecPermitted", value)
                                }
                                select
                                value={formState.elecPermitted.value}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {singleMaybe.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Smoking Permitted"
                                name="smokingPermitted"
                                onChange={(e) =>
                                  onChangeFilterM(e, "smokingPermitted", value)
                                }
                                select
                                value={formState.smokingPermitted.value}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {singleMaybe.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Handicap Accessible"
                                name="handicapAccessible"
                                onChange={(e) =>
                                  onChangeFilterM(
                                    e,
                                    "handicapAccessible",
                                    value
                                  )
                                }
                                select
                                value={formState.handicapAccessible.value}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {singleMaybe.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Med. Complexities"
                                name="medComplexityAccepted"
                                onChange={(e) =>
                                  onChangeFilterM(
                                    e,
                                    "medComplexityAccepted",
                                    value
                                  )
                                }
                                select
                                value={formState.medComplexityAccepted.value}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {singleMaybe.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Inv. Commitments"
                                name="acceptCommitments"
                                onChange={(e) =>
                                  onChangeFilterM(e, "acceptCommitments", value)
                                }
                                select
                                value={formState.acceptCommitments.value}
                                variant="outlined"
                              >
                                <MenuItem key="0" value=""></MenuItem>
                                {singleMaybe.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.key}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    ></CollapsableItem>
                  </Grid>
                  {/* OTHER FILTERS */}
                </Grid>
              </CardContent>
            )}
          </Card>
        </Box>
      )}
    </ProviderContext.Consumer>
  );
};

export default FilterComponent;
