import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import UserFormEditComponent from 'src/components/users/UserEditFormComponent';
import { useParams } from 'react-router-dom';

const UserFormEdit = () => {
  const {id} = useParams();
  return(
    <>
      <Helmet>
        <title>Account | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={12} md={12} xs={12} >
              <UserFormEditComponent id={id}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UserFormEdit;
