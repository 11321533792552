import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import restApiService from '../../services/restService';
import { FILTER_VALUES, FILTER_GENDER, FILTER_AGE_RANGE, FILTER_BOOL, FILTER_VALUES_DATA } from '../../utils/Constants';
import ProviderContext from './ProviderContext';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import CustomSelect from '../shared/CustomSelect';
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const PatientPopulation = (props) => {
  useEffect(() => {
  }, [])

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik enableReinitialize initialValues={value.provider} >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                noValidate
                {...props}
              >
                <Card>
                  <CardHeader
                    title="Patient Population"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Gender"
                          name="gender"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "gender", "key")
                          }}
                          select
                          defaultValue={(() => {
                            return value.provider.gender ? value.provider.gender.key : ""
                          })()}
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.gender.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label='Age Range'
                          name="ageRange"
                          select
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "ageRange", "key")
                          }}
                          defaultValue={(() => {
                            return value.provider.ageRange ? value.provider.ageRange.key : ""
                          })()}
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.agerange.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label='Average Age of Milieu'
                          name="averageMilieu"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "averageMilieu", "value")
                          }}
                          value={value.provider.averageMilieu || ""}
                          variant="outlined"
                          helperText={values.averageMilieu ? `Number of characters ${values.averageMilieu.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label='Typical Client Profile '
                          name="clientProfile"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "clientProfile", "value")
                          }}
                          value={value.provider.clientProfile || ""}
                          variant="outlined"
                          helperText={values.clientProfile ? `Number of characters ${values.clientProfile.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer>
  )
};

export default PatientPopulation;
