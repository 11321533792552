import React, { memo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ProviderContext from '../provider/ProviderContext';
import restApiService from '../../services/restService';
import { PROVIDER_EXPORT_CSV, PROVIDER_EXPORT_PDF } from '../../utils/Constants';
import { useEffect, useState } from 'react';
import roleService from '../helpers/RoleChecker';
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = {
  flexCenter: {
    display: "flex",
    'justifyContent': 'center',
    'alignContent': 'center',
    'width': '100%',
    'height': '100%'
  },
  flex: {
    display: "flex",
    padding: '0 0.5rem',
  },
  grid: {
    display: "flex",
    "justifyContent": "center",
  },
  padding: {
    padding: '0.3rem',
  },
  textError: {

  }
};
const ProductListToolbar = (props, params) => {

  const intialState = () => {
    let searchProviderText = localStorage.getItem('searchProviderText');
    if (searchProviderText != null) {
      return JSON.parse(searchProviderText)
    } else {
      localStorage.setItem('searchProviderText', JSON.stringify([]))
      return [];
    }
  }

  const intialStateSorts = () => {
    let sort = localStorage.getItem('sort')
    return sort ? parseInt(sort) : 1
  }

  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(roleService.isStandar());
  const [loadingCsv, setloadingCsv] = useState(false);
  const [loadingPDF, setloadingPDF] = useState(false);
  const { callServiceDownload } = restApiService();
  const routeChange = () => {
    navigate('/app/add-provider');
  }
  const [words, setWords] = useState(intialState());
  const [sortValue, setSortValue] = useState(intialStateSorts());
  const [sorts, setSorts] = useState();
  const callExport = (endpoint, request, fileType, fileName) => {
    let req = { ...request }
    req.pageSize = 2147483647
    req.pageIndex = 0
    callServiceDownload('POST', endpoint, { data: req }, true, fileType, fileName).then(e => {
      setloadingPDF(false);
      setloadingCsv(false);
    }).catch(e => {
      setloadingPDF(false);
      setloadingCsv(false);
    })
  }

  const contextValue = React.useContext(ProviderContext);

  useEffect(() => {
    let searchColumn = words
    contextValue.handleSearch({ searchColumn })
  }, [words])

  useEffect(() => {
    let sort = sorts
    contextValue.handleSorts({ sorts })
  }, [sorts])

  useEffect(() => {
    let request = {}
    switch (parseInt(sortValue)) {
      case 1:
        request.sorts = [{
          asc: true,
          orderField: 'programName'
        }]
        break;
      case 2:
        request.sorts = [{
          asc: false,
          orderField: 'programName'
        }]
        break;
      case 3:
        request.sorts = [{
          asc: true,
          orderField: 'city'
        }]
        break;
      case 4:
        request.sorts = [{
          asc: false,
          orderField: 'city'
        }]
        break;
      case 5:
        request.sorts = [{
          asc: true,
          orderField: 'admissionEmail'
        }]
        break;
      case 6:
        request.sorts = [{
          asc: false,
          orderField: 'admissionEmail'
        }]
        break;
      case 7:
        request.sorts = [{
          asc: true,
          orderField: 'programType'
        }]
        break;
      case 8:
        request.sorts = [{
          asc: false,
          orderField: 'programType'
        }]
        break;
      case 9:
        request.sorts = [{
          asc: true,
          orderField: 'gender'
        }]
        break;
      case 10:
        request.sorts = [{
          asc: false,
          orderField: 'gender'
        }]
        break;
      case 11:
        request.sorts = [{
          asc: true,
          orderField: 'ageRange'
        }]
        break;
      case 12:
        request.sorts = [{
          asc: false,
          orderField: 'ageRange'
        }]
        break;
      default:
        request.sorts = [{
          asc: true,
          orderField: 'programName'
        }]
    }
    setSorts(request.sorts)
  }, [sortValue])
  const renderExports = (value) => {
    if (!localStorage.getItem("authorities").includes('READ')) {
      return (
        <div style={styles.flex}>
          {loadingCsv &&
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <CircularProgress style={styles.padding} />
              </div>
            </div>
          }
          {!loadingCsv && <Button sx={{ mx: 1 }} onClick={() => {
            setloadingCsv(true)
            callExport(PROVIDER_EXPORT_CSV, value, 'application/csv', 'providers.csv')
          }}>
            Export CSV
          </Button>}
          {loadingPDF &&
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <CircularProgress style={styles.padding} />
              </div>
            </div>
          }

          {!loadingPDF && <Button sx={{ mx: 1 }} onClick={() => {
            setloadingPDF(true)
            callExport(PROVIDER_EXPORT_PDF, value, 'application/pdf', 'providers.pdf')
          }}>
            Export PDF
          </Button>}
        </div>
      );
    }
  }

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Box {...props}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {renderExports(value.request)}
              {hasPermission && <Button color="primary" variant="contained" onClick={routeChange} >
                Add provider
              </Button>}
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={10}>
                        <Box sx={{ pt: 3 }}>
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={words}
                            defaultValue={words}
                            onChange={async (e, values) => {
                              setWords(values)
                              localStorage.setItem('searchProviderText', JSON.stringify(values))
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Search Providers by Name, Summary, City, State, and ZIP code"
                                placeholder="Search Providers by Name, Summary, City, State, and ZIP code"
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box sx={{ pt: 3 }}>
                          <TextField
                            id="handicap-accessible-select"
                            label="Sort by"
                            SelectProps={{ native: true }}
                            variant="outlined"
                            select
                            fullWidth
                            value={sortValue}
                            onChange={async (e) => {
                              localStorage.setItem('sort', e.target.value)
                              setSortValue(e.target.value)
                            }}
                          >
                            <option key={1} value={1}>{'Program Name A-Z'}</option>
                            <option key={2} value={2}>{'Program Name Z-A'}</option>
                            <option key={3} value={3}>{'City A-Z'}</option>
                            <option key={4} value={4}>{'City Z-A'}</option>
                            <option key={5} value={5}>{'Email A-Z'}</option>
                            <option key={6} value={6}>{'Email Z-A'}</option>
                            <option key={9} value={9}>{'Gender A-Z'}</option>
                            <option key={10} value={10}>{'Gender Z-A'}</option>
                            <option key={11} value={11}>{'Age Ranges ascending'}</option>
                            <option key={12} value={12}>{'Age Ranges descending'}</option>
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )
      }
    </ProviderContext.Consumer>
  )
};

export default memo(ProductListToolbar);
