import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import restApiService from '../../services/restService';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FILTER_VALUES, FILTER_STATE, GOOGLE_API_KEY, PUBLIC_FILTER_VALUES } from '../../utils/Constants';
import ProviderContext from './ProviderContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

const ContactDetail = (props) => {
  const { callService } = restApiService();
  const [states, setStates] = useState([]);
  const [statesByCountry, setStatesByCountry] = useState({
    "1": [],
    "2": [],
    "3": [],
  });
  const [delay, setDelay] = useState();
  const [debaunce, setDebaunce] = useState();
  const [place, setPlace] = useState();
  const [place2, setPlace2] = useState();
  const [place3, setPlace3] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const contextValue = useContext(ProviderContext);
  const [formInit, setFormInit] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [address2, setAddress2] = useState(false);
  const [address3, setAddress3] = useState(false);
  const [formState, setFormState] = useState({
    programName: { value: "", required: true, field: "value" },
    website: { value: "", required: false, field: "value" },
    address: { value: "", required: true, field: "value" },
    city: { value: "", required: false, field: "value" },
    state: { value: "", required: false, field: "key" },
    zip: { value: "", required: false, field: "value" },
    country: { value: "", required: false, field: "key" },
    admissionPhone: { value: "", required: false, field: "value" },
    admissionEmail: { value: "", required: false, field: "value" },
    preferredContacts: { value: "", required: false, field: "value" },
    primaryContactInfo: { value: "", required: false, field: "value" },
  });
  const [country, setcountry] = useState();
  const [state, setstate] = useState();
  const [city, setcity] = useState();
  const [zip, setzip] = useState();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
  });
  const isInvalid = () => {
    let found = false
    for (const key of Object.keys(formState)) {
      if (formState[key].required && formState[key].value === "") {
        found = true
        break
      }
    }
    return found
  }
  const handleChangeAndValidation = async (e, value, fieldName, field, emit) => {
    if (debaunce) {
      clearTimeout(debaunce)
    }
    let id = setTimeout(async () => {
      await value.updateProvider(e, fieldName, field)
      setFormState(formState => ({
        ...formState,
        [fieldName]: {
          required: formState[fieldName].required,
          value: e.target.value
        }
      }));
    }, 15);
    setDebaunce(id)
  }

  useEffect(() => {
    if (formState.country.value) {
      setStates([...statesByCountry[formState.country.value]])
      setFormState(formState => ({
        ...formState,
        "state": {
          value: formState.country.value == 1 ? formState.state.value : 0,
        }
      }));
      if (formState.country.value != 1)
        handleChangeAndValidation({ target: { value: 0 } }, contextValue, "state", "key")
    }
  }, [formState.country])

  useEffect(() => {
    if (contextValue) {
      if (delay) {
        clearTimeout(delay)
      }
      let id = setTimeout(() => {
        contextValue.updateValidator(isInvalid())
      }, 500);
      setDelay(id)
    }
  }, [formState])

  useEffect(() => {
    let isMounted = true;
    let url = props.token ? PUBLIC_FILTER_VALUES(props.token, FILTER_STATE) : FILTER_VALUES + FILTER_STATE
    callService('GET', url, {}, true, props.token)
      .then(response => {
        if (isMounted)
          if (response && response.status == 200) {
            if (formState.country.value) {
              let stat = { "1": [...response.data], "2": [], "3": [] }
              setStatesByCountry(stat)
              setStates([...stat[formState.country.value]])
            } else {
              let stat = { "1": [...response.data], "2": [], "3": [] }
              setStatesByCountry(stat)
              setStates([...response.data])
            }
          }
      });
    return () => { isMounted = false };
  }, [])

  const handleFormState = (provider) => {
    setFirstTime(false)
    let newState = {}
    for (const key of Object.keys(formState)) {
      newState[key] = { ...formState[key], value: formState[key].field == "value" ? provider[key] : provider[key] ? provider[key]['key'] : provider[key] }
    }
    setFormState(newState)
  }

  useEffect(() => {
    let isMounted = true;
    if (firstTime && isMounted)
      handleFormState(contextValue.provider)
    return () => { isMounted = false };
  }, [contextValue])

  useEffect(() => {
    if (place) {
      parceAddress(place, 'city', 'zip', 'state')
    }
  }, [place])

  useEffect(() => {
    if (place2) {
      parceAddress(place2, 'city2', 'zip2', 'state2')
    }
  }, [place2])

  useEffect(() => {
    if (place3) {
      parceAddress(place3, 'city3', 'zip3', 'state3')
    }
  }, [place3])

  const parceAddress = (place, fieldCityName, fieldZipCode, fieldState) => {
    let countryPlaceIndex = place.address_components.findIndex(e => {
      return e.types.findIndex(t => t?.toLowerCase() == 'country') >= 0
    })
    let statePlaceIndex = place.address_components.findIndex(e => {
      return e.types.findIndex(t => t?.toLowerCase() == "administrative_area_level_1") >= 0
    })
    let cityIndex = place.address_components.findIndex(e => {
      return e.types.findIndex(t => t?.toLowerCase() == "locality" || t?.toLowerCase() == "sublocality") >= 0
    })
    let postalCodePlaceIndex = place.address_components.findIndex(e => {
      return e.types.findIndex(t => t?.toLowerCase() == "postal_code") >= 0
    })
    let mapValues = {}
    if (countryPlaceIndex > -1) {
      let t1 = place.address_components[countryPlaceIndex].short_name
      let key = 3
      switch (t1.toLowerCase()) {
        case "US".toLowerCase():
          key = 1
          break;
        case "CA".toLowerCase():
          key = 2
          break;
        default:
          break;
      }
      let countryIndex = props.country.findIndex(e => {
        return e.key == key
      })
      mapValues['country'] = props.country[countryIndex]
      if (mapValues['country'].key === 1) {
        if (statePlaceIndex > -1) {
          let t2 = place.address_components[statePlaceIndex].long_name
          let stateIndex = statesByCountry[mapValues['country'].key].findIndex(e => {
            return e.value.toLowerCase() == t2.toLowerCase()
          })
          mapValues['state'] = statesByCountry[mapValues['country'].key][stateIndex]
        }
      }
    }
    if (cityIndex > -1) {
      mapValues[fieldCityName] = place.address_components[cityIndex].long_name
    }
    if (postalCodePlaceIndex > -1) {
      mapValues[fieldZipCode] = place.address_components[postalCodePlaceIndex].long_name
    }

    let r = [{ value: mapValues['country'].key, fieldName: 'country', keyOrValue: 'key' },
    { value: mapValues[fieldState]?.key || '', fieldName: fieldState, keyOrValue: 'key' },
    { value: mapValues[fieldZipCode], fieldName: fieldZipCode, keyOrValue: 'value' },
    { value: mapValues[fieldCityName], fieldName: fieldCityName, keyOrValue: 'value' }]
    contextValue.updateProviderBulk(r)
  }

  const removeAddress = (values, addressNumber, value) => {
    if(addressNumber === 2) {
      values.showAddress2 = value
      setAddress2(value)
      setAddAddress(true)
    }
    if(addressNumber === 3) {
      values.showAddress3 = value
      setAddress3(value)
      setAddAddress(true)
    }
  }

  const addAddressFnc = () => {
    if(!address2) {
      setAddress2(true)
      return
    }
    if(!address3) {
      setAddress3(true)
      setAddAddress(false)
      return
    }
  }

  const formInitFunc = (values) => {
    if(!formInit) {
      values.addAddress = false
      values.showAddress2 = false
      values.showAddress3 = false
      if(values.address2 && values.address2.length > 0) {
        values.showAddress2 = true
        setAddress2(true)
      } else {
        setAddAddress(true)
      }
      if(values.address3 && values.address3.length > 0) {
        values.showAddress3 = true
        setAddress3(true)
      } else {
        setAddAddress(true)
      }
      setFormInit(true)
    }
  }

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik
            enableReinitialize
            initialValues={value.provider}
            validationSchema={Yup.object().shape({
              programName: Yup.string().required('Program Name is required and unique'),
              address: Yup.string().required('Address is required and unique'),
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form autoComplete="off"
              >
                {removeAddress(values)}
                {formInitFunc(values)}
                <Card>
                  <CardHeader
                    title="Contact information"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Program Name"
                          name="programName"
                          onBlur={handleBlur}
                          error={Boolean(touched.programName && errors.programName)}
                          helperText={values.programName ? `Number of characters ${values.programName.length}/255` || touched.programName && errors.programName : ''}
                          onChange={async (e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "programName", "value")
                          }}
                          value={values.programName}
                          required
                          variant="outlined"
                          inputProps={{
                            maxLength: 255,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Admissions Contact Phone"
                          name="admissionPhone"
                          onBlur={handleBlur}
                          error={Boolean(touched.admissionPhone && errors.admissionPhone)}
                          helperText={touched.admissionPhone && errors.admissionPhone}
                          onChange={(e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "admissionPhone", "value")
                          }}
                          value={values.admissionPhone || ""}
                          variant="outlined"
                          type="number"
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Admissions Email"
                          name="admissionEmail"
                          onBlur={handleBlur}
                          error={Boolean(touched.admissionEmail && errors.admissionEmail)}
                          helperText={values.admissionEmail ? `Number of characters ${values.admissionEmail.length}/255` || touched.admissionEmail && errors.admissionEmail : ''}
                          onChange={(e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "admissionEmail", "value")
                          }}
                          value={values.admissionEmail || ""}
                          variant="outlined"
                          inputProps={{
                            maxLength: 255,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {!props.hide && <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Primary Contact"
                          name="preferredContacts"
                          helperText={values.preferredContacts ? `Number of characters ${values.preferredContacts.length}/255` : ''}
                          onChange={(e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "preferredContacts", "value")
                          }}
                          value={values.preferredContacts || ""}
                          variant="outlined"
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                      </Grid>}
                      {!props.hide && <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Primary Contact Information"
                          name="primaryContactInfo"
                          helperText={values.primaryContactInfo ? `Number of characters ${values.primaryContactInfo.length}/255` : ''}
                          onChange={(e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "primaryContactInfo", "value")
                          }}
                          value={values.primaryContactInfo || ""}
                          variant="outlined"
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                      </Grid>}
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Website (Example: http://mywebsite.com) "
                          name="website"
                          onBlur={handleBlur}
                          error={Boolean(touched.website && errors.website)}
                          helperText={values.website ? `Number of characters ${values.website.length}/255` || touched.website && errors.website : ''}
                          onChange={(e) => {
                            handleChange(e)
                            handleChangeAndValidation(e, value, "website", "value")
                          }}
                          value={values.website || ""}
                          variant="outlined"
                          inputProps={{
                            maxLength: 255,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={10} xs={12}>
                        <Autocomplete
                          disableClearable
                          fullWidth
                          options={placePredictions ? placePredictions : []}
                          getOptionLabel={(option) => option.description ? option.description : ""}
                          value={values.address || ""}
                          inputValue={values.address || ""}
                          onChange={(e, v) => {
                            if (v) {
                              placesService?.getDetails({ placeId: v.place_id },
                                (place) => {
                                  value.updateProvider({
                                    target: {
                                      value: {
                                        latitude: place.geometry.location.lat(),
                                        longitude: place.geometry.location.lng(),
                                        address: v.description
                                      }
                                    }
                                  }, ['latitude', 'longitude', 'address'], 'array')
                                  setFormState(formState => ({
                                    ...formState,
                                    'address': {
                                      required: formState['address'].required,
                                      value: v.description
                                    }
                                  }))
                                  setPlace(place)
                                });
                            } else {
                              value.updateProvider({
                                target: {
                                  value: {
                                    latitude: 0,
                                    longitude: 0,
                                    address: ''
                                  }
                                }
                              }, ['latitude', 'longitude', 'address'], 'array')
                            }
                          }}
                          onInputChange={(e, values) => {
                            if (e) {
                              getPlacePredictions({ input: e.target.value });
                              handleChange(e)
                            }
                          }}
                          renderInput={(params) =>
                            <TextField {...params}
                              fullWidth
                              label="Address #1"
                              name="address"
                              variant="outlined" 
                              />}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                       {addAddress && <Button variant="contained" fullWidth style={{
                              padding: "15px"
                          }} 
                          onClick={() => {
                            addAddressFnc()
                          }}
                          >Add Address   
                        </Button>
                      }
                      </Grid>
                      {address2 && <><Grid item md={10} xs={12}>
                        <Autocomplete
                          disableClearable
                          fullWidth
                          options={placePredictions ? placePredictions : []}
                          getOptionLabel={(option) => option.description ? option.description : ""}
                          value={values.address2 || ""}
                          inputValue={values.address2 || ""}
                          onChange={(e, v) => {
                            if (v) {
                              placesService?.getDetails({ placeId: v.place_id },
                                (place2) => {
                                  value.updateProvider({
                                    target: {
                                      value: {
                                        latitude2: place2.geometry.location.lat(),
                                        longitude2: place2.geometry.location.lng(),
                                        address2: v.description
                                      }
                                    }
                                  }, ['latitude2', 'longitude2', 'address2'], 'array')
                                  setFormState(formState => ({
                                    ...formState,
                                    'address': {
                                      required: formState['address'].required,
                                      value: v.description
                                    }
                                  }))
                                  setPlace2(place2)
                                });
                            } else {
                              value.updateProvider({
                                target: {
                                  value: {
                                    latitude2: 0,
                                    longitude2: 0,
                                    address2: ''
                                  }
                                }
                              }, ['latitude2', 'longitude2', 'address2'], 'array')
                            }
                          }}
                          onInputChange={(e, values) => {
                            if (e) {
                              getPlacePredictions({ input: e.target.value });
                              handleChange(e)
                            }
                          }}
                          renderInput={(params) =>
                            <TextField {...params}
                              fullWidth
                              label="Address #2"
                              name="address2"
                              variant="outlined" />}
                        /> 
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button variant="contained" fullWidth style={{
                              padding: "15px"
                          }} 
                          onClick={() => {
                              values.address2 = ''
                              values.latitude2 = ''
                              values.longitude2 = ''
                              removeAddress(values, 2, false)
                          }}  
                        >Remove Address   
                        </Button>
                      </Grid>  
                      </>
                       }
                      {address3 && <><Grid item md={10} xs={12}>
                        <Autocomplete
                          disableClearable
                          fullWidth
                          options={placePredictions ? placePredictions : []}
                          getOptionLabel={(option) => option.description ? option.description : ""}
                          value={values.address || ""}
                          inputValue={values.address3 || ""}
                          onChange={(e, v) => {
                            if (v) {
                              placesService?.getDetails({ placeId: v.place_id },
                                (place3) => {
                                  value.updateProvider({
                                    target: {
                                      value: {
                                        latitude3: place3.geometry.location.lat(),
                                        longitude3: place3.geometry.location.lng(),
                                        address3: v.description
                                      }
                                    }
                                  }, ['latitude3', 'longitude3', 'address3'], 'array')
                                  setFormState(formState => ({
                                    ...formState,
                                    'address': {
                                      required: formState['address'].required,
                                      value: v.description
                                    }
                                  }))
                                  setPlace3(place3)
                                });
                            } else {
                              value.updateProvider({
                                target: {
                                  value: {
                                    latitude3: 0,
                                    longitude3: 0,
                                    address3: ''
                                  }
                                }
                              }, ['latitude3', 'longitude3', 'address3'], 'array')
                            }
                          }}
                          onInputChange={(e, values) => {
                            if (e) {
                              getPlacePredictions({ input: e.target.value });
                              handleChange(e)
                            }
                          }}
                          renderInput={(params) =>
                            <TextField {...params}
                              fullWidth
                              label="Address #3"
                              name="address3"
                              variant="outlined" />}
                        /> 
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button variant="contained" fullWidth style={{
                              padding: "15px"
                          }} 
                          onClick={() => {
                              values.address3 = ''
                              values.latitude3 = ''
                              values.longitude3 = ''
                              removeAddress(values, 3, false)
                          }}
                          >Remove Address   
                        </Button>
                      </Grid>  
                      </>
                       }
                    </Grid>
                    
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer>
  )
};

export default ContactDetail;
