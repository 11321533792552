import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const 
CustomMultiSelect = (props) => {
    const [textBoxDisabled, setTextBoxDisabled] = useState(false);

    useEffect(() => {
        setTextBoxDisabled(props.defaultValue.includes(props.otherIndex) ? false : true);
      }, [textBoxDisabled]);

    const handleChangeDropDown = (event) => {
        if(event.target.value.includes(props.otherIndex)) {
            setTextBoxDisabled(false);
        } else {
            setTextBoxDisabled(true);
        }
        let result = [{ value: event.target.value, fieldName: props.name, keyOrValue: 'value' }]
        props.onChange(result);
    };

    const handleChangeInput = (event) => {
        let result = [{ value: event.target.value, fieldName: props.inputName, keyOrValue: 'value' }]
        props.onChange(result);
    };

    return (
        <Grid item md={6} xs={12} key={1} justify="space-between" container spacing={5}>
            <Grid item md={6} xs={6}>
                <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={props.defaultValue}
                    onChange={handleChangeDropDown}
                    fullWidth
                    startAdornment={
                        <InputAdornment position="start">
                            <PublicIcon />
                        </InputAdornment>
                    }
                >
                    {props.list.map((option, index) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}>
                            {option.value}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid >
            <Grid item md={6} xs={6}>
                <TextField
                    fullWidth
                    label={props.inputLabel}
                    name={props.inputName}
                    disabled={textBoxDisabled}
                    value={props.inputValue}
                    variant="outlined"
                    onChange={handleChangeInput}
                    helperText={props.inputValue ? `Number of characters ${props.inputValue.length}/255` : ''}
                    inputProps={{
                        maxLength: 255,
                        startAdornment: ( 
                            <InputAdornment position="start">
                                <PublicIcon />
                            </InputAdornment>
                        ),
                    }}
                />            
            </Grid>
        </Grid >)
}

export default CustomMultiSelect;
