import React, { useContext } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import ProviderContext from './ProviderContext';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import CustomSelect from '../shared/CustomSelect';
import CustomMultiSelect from '../shared/CustomMultiSelect';
import FastInput from '../shared/FastInput';
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const ProgramDetail = (props) => {
  const contextValue = useContext(ProviderContext);

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik enableReinitialize initialValues={value.provider} >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                noValidate
                {...props}
              >
                <Card>
                  <CardHeader
                    title="Program Detail"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      {!props.hide && <CustomSelect
                        label='Referred Before'
                        name="opgReferredBefore"
                        onChange={contextValue.updateProviderBulk}
                        defaultValue={value.provider.opgReferredBefore ? value.provider.opgReferredBefore : ""}
                        list={props.booleanvalues}
                        isPublic={false}
                        />}
                        
                      <CustomMultiSelect
                        label='Program Type'
                        name="programType"
                        inputName="programTypeData"
                        inputLabel="Other - Program Type"
                        inputValue={values.programTypeData}
                        onChange={contextValue.updateProviderBulk}                        
                        defaultValue={values.programType ? values.programType : []}
                        list={props.programtypes} 
                        otherIndex={5}
                        isPublic={true}
                      />

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "leisureOffered", "value")
                        }} value={values.leisureOffered}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Leisure Activities Offered"
                              name="leisureOffered"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.leisureOffered ? `Number of characters ${values.leisureOffered.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "admissionRequirements", "value")
                        }} value={values.admissionRequirements}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Admission Requirements"
                              name="admissionRequirements"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.admissionRequirements ? `Number of characters ${values.admissionRequirements.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "admissionRules", "value")
                        }} value={values.admissionRules}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Admission Rule Outs"
                              name="admissionRules"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.admissionRules ? `Number of characters ${values.admissionRules.length}/255`: ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "diagnosesConditions", "value")
                        }} value={values.diagnosesConditions}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Diagnoses/Conditions"
                              name="diagnosesConditions"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.diagnosesConditions ? `Number of characters ${values.diagnosesConditions.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "covidProtocols", "value")
                        }} value={values.covidProtocols}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="COVID Protocols"
                              name="covidProtocols"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.covidProtocols ? `Number of characters ${values.covidProtocols.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>

                      <CustomSelect
                        label='LGBTQ+ Friendly?'
                        name="lgbtqFriendly"
                        inputName="lgbtqFriendlyData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueInput={value.provider.lgbtqFriendlyData ? value.provider.lgbtqFriendlyData : ""}
                        defaultValue={value.provider.lgbtqFriendly ? value.provider.lgbtqFriendly : ""}
                        list={props.lgbtqfriendly} 
                        isPublic={true}
                        />

                        <CustomSelect
                        label='LGBTQ+ Services Offered'
                        name="lgbtqServiceType"
                        inputName="lgbtqServiceTypeData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueInput={value.provider.lgbtqServiceTypeData ? value.provider.lgbtqServiceTypeData : ""}
                        defaultValue={value.provider.lgbtqServiceType ? value.provider.lgbtqServiceType : ""}
                        list={props.lgbtqservice} />

                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "numberBeds", "value")
                        }} value={values.numberBeds}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Program Size / # of Beds or Participants"
                              name="numberBeds"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.numberBeds ? `Number of characters ${values.numberBeds.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}
                        </FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "averageLengthStay", "value")
                        }} value={values.averageLengthStay}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Average Length of Stay"
                              name="averageLengthStay"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.averageLengthStay ? `Number of characters ${values.averageLengthStay.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "minLengthStay", "value")
                        }} value={values.minLengthStay}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Minimum Length of Stay"
                              name="minLengthStay"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.minLengthStay ? `Number of characters ${values.minLengthStay.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "maxLengthStay", "value")
                        }} value={values.maxLengthStay}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Maximum Length of Stay"
                              name="maxLengthStay"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.maxLengthStay ? `Number of characters ${values.maxLengthStay.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "extendStay", "value")
                        }} value={values.extendStay}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Option to Extend stay"
                              name="extendStay"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.extendStay ? `Number of characters ${values.extendStay.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <CustomSelect
                        label='Are There Separate Wings by Gender?'
                        name="separateByGender"
                        inputName="separateByGenderData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueInput={value.provider.separateByGenderData ? value.provider.separateByGenderData : ""}
                        defaultValue={value.provider.separateByGender ? value.provider.separateByGender : ""}
                        list={props.bygenderlist}
                        isPublic={true} />

                      <CustomMultiSelect
                        label='Bed Assignments'
                        name="bedAssigmentType"
                        inputName="bedAssigmentTypeData"
                        inputLabel="Other - Bed Assignments"
                        inputValue={values.bedAssigmentTypeData}
                        onChange={contextValue.updateProviderBulk}                        
                        defaultValue={values.bedAssigmentType ? values.bedAssigmentType : []}
                        list={props.bedassigmentlist}
                        otherIndex={6}
                        isPublic={false}
                      />

                      {!props.hide &&
                        <Grid item md={6} xs={12}>
                          <FastInput updateProvider={(e) => {
                            value.updateProvider(e, "meals", "value")
                          }} value={values.meals}>
                            {(innerProps) => {
                              return <TextField
                                fullWidth
                                label="Meals"
                                name="meals"
                                onChange={(e) => {
                                  innerProps.handleChange(e)
                                }}
                                value={innerProps.value || ""}
                                variant="outlined"
                                helperText={values.meals ? `Number of characters ${values.meals.length}/255` : ''}
                                inputProps={{
                                maxLength: 255,
                                  startAdornment: ( 
                                      <InputAdornment position="start">
                                          <PublicIcon />
                                      </InputAdornment>
                                  ),
                                }}
                              />
                            }}</FastInput>
                        </Grid>
                      }

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Edu / Voc Opportunities?"
                          name="opportunityType"
                          select
                          SelectProps={{
                            multiple: true,
                            value: values.opportunityType ? values.opportunityType : [],
                            onChange: (e) => {
                              handleChange(e)
                              value.updateProvider(e, "opportunityType", "value")
                            }
                          }}
                          variant="outlined"
                          helperText={values.opportunityType ? `Number of characters ${values.opportunityType.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}>
                          {props.optypelist.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          id="onsite-medical-clearence-select"
                          fullWidth
                          label="Family Therapy or Family Component?"
                          name="familyTherapyOrComponent"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "familyTherapyOrComponent", "key")
                          }}
                          value={(() => { 
                            if(value.provider.familyTherapyOrComponent){
                              let t = value.provider.familyTherapyOrComponent.key > 2 ? 1 : value.provider.familyTherapyOrComponent.key; 
                              return t ? t : '' 
                            } else {
                              return ''
                            }
                          })()}
                          select
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.singletype.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "frequencyMeetFamilyPsy", "value")
                        }} value={values.frequencyMeetFamilyPsy}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Frequency of Meetings with Family Therapist"
                              name="frequencyMeetFamilyPsy"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.frequencyMeetFamilyPsy ? `Number of characters ${values.frequencyMeetFamilyPsy.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "familyInfo", "value")
                        }} value={values.familyInfo}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Additional Family Component Information"
                              name="familyInfo"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              helperText={values.familyInfo ? `Number of characters ${values.familyInfo.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "treatmentModalities", "value")
                        }} value={values.treatmentModalities}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Treatment Modalities"
                              name="treatmentModalities"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.treatmentModalities ? `Number of characters ${values.treatmentModalities.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField id="onsite-medical-clearence-select" fullWidth label="Type(s) of Therapy Hour" name="therapyHoursType" SelectProps={{
                          multiple: true,
                          value: values.therapyHoursType ? values.therapyHoursType : [],
                          onChange: (e) => {
                            handleChange(e)
                            value.updateProvider(e, "therapyHoursType", "value")
                          }
                        }}
                          select
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}>
                          {props.therapylist?.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "therapyHours", "value")
                        }} value={values.therapyHours}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Frequency of Meetings with a Therapist"
                              name="therapyHours"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.therapyHours ? `Number of characters ${values.therapyHours.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "clinicalHighlights", "value")
                        }} value={values.clinicalHighlights}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Clinical / Other Service Highlights"
                              name="clinicalHighlights"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.clinicalHighlights ? `Number of characters ${values.clinicalHighlights.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "specialtyOffered", "value")
                        }} value={values.specialtyOffered}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Specialty Programs / Tracks Offered"
                              name="specialtyOffered"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.specialtyOffered ? `Number of characters ${values.specialtyOffered.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>

                      <CustomSelect
                        label='Smoking Cessation Services'
                        name="smokingCessationServices"
                        onChange={contextValue.updateProviderBulk}
                        defaultValue={value.provider.smokingCessationServices ? value.provider.smokingCessationServices : ""}
                        list={props.booleanvalues} 
                        isPublic={true}/>

                      <CustomSelect
                        label='Addictive Medication Allowed'
                        name="addictiveMedAllowed"
                        inputName="addictiveMedAllowedData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueInput={value.provider.addictiveMedAllowedData ? value.provider.addictiveMedAllowedData : ""}
                        defaultValue={value.provider.addictiveMedAllowed ? value.provider.addictiveMedAllowed : ""}
                        list={props.lgbtqfriendly} 
                        isPublic={true}/>

                      <Grid item md={6} xs={12}>
                        <TextField
                          id="offers-medication-assested-treatment-select"
                          fullWidth
                          label="Offers Medication Assisted Treatment"
                          name="offerMedAssistedTreatment"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "offerMedAssistedTreatment", "key")
                          }
                          }
                          value={values.offerMedAssistedTreatment ? values.offerMedAssistedTreatment.key : ""}
                          select
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.booleanvalues.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          id="on-size-medical-clearance"
                          fullWidth
                          label="On-site Medical Clearance"
                          name="medicalClearance"
                          onChange={(e) => {
                            handleChange(e)
                            value.updateProvider(e, "medicalClearance", "key")
                          }
                          }
                          value={values.medicalClearance ? values.medicalClearance.key : ""}
                          select
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.booleanvalues.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Psychiatry & Rx Management"
                          name="managementType"
                          select
                          SelectProps={{
                            multiple: true,
                            value: values.managementType ? values.managementType : [],
                            onChange: (e) => {
                              handleChange(e)
                              value.updateProvider(e, "managementType", "value")
                            }
                          }}
                          variant="outlined"
                          inputProps={{
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        >
                          {props.managementlist.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "frequencyMeetPsy", "value")
                        }} value={values.frequencyMeetPsy}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Frequency of Meetings with a Psychiatrist"
                              name="frequencyMeetPsy"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.frequencyMeetPsy ? `Number of characters ${values.frequencyMeetPsy.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "shortTermAssessmentServices", "value")
                        }} value={values.shortTermAssessmentServices}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Short Term Assessment Services"
                              name="shortTermAssessmentServices"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.shortTermAssessmentServices ? `Number of characters ${values.shortTermAssessmentServices.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "medicalNursingOversight", "value")
                        }} value={values.medicalNursingOversight}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Is there Medical and Nursing Oversight"
                              name="medicalNursingOversight"
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.medicalNursingOversight ? `Number of characters ${values.medicalNursingOversight.length}/255` : ''}
                              inputProps={{
                                maxLength: 255,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FastInput updateProvider={(e) => {
                          value.updateProvider(e, "summary", "value")
                        }} value={values.summary}>
                          {(innerProps) => {
                            return <TextField
                              fullWidth
                              label="Summary"
                              name="summary"
                              multiline
                              rows={10}
                              maxRows={10}
                              onChange={(e) => {
                                innerProps.handleChange(e)
                              }}
                              value={innerProps.value || ""}
                              variant="outlined"
                              helperText={values.summary ? `Number of characters ${values.summary.length}/65525` : ''}
                              inputProps={{
                                maxLength: 65525,
                                startAdornment: ( 
                                    <InputAdornment position="start">
                                        <PublicIcon />
                                    </InputAdornment>
                                ),
                              }}
                            />
                          }}</FastInput>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer >
  )
};

export default ProgramDetail;
