import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProvidersList from 'src/pages/ProvidersList';
import IndividualsList from './pages/IndividualsList';
import ProviderForm from 'src/pages/ProviderForm';
import IndividualFormSection from 'src/pages/IndividualFormSection';
import ProviderFormPublic from 'src/pages/ProviderFormPublic';
import IndividualFormPublic from 'src/pages/IndividualFormPublic';
import Directory from 'src/pages/Directory';
import UserList from 'src/pages/UserList';
import UserForm from 'src/pages/UserForm';
import UserFormEdit from 'src/pages/UserFormEdit';
import UserFormChangePassword from 'src/pages/UserFormChangePassword';
import ChangePassword from 'src/pages/ChangePassword';
import Settings from 'src/pages/Settings';
import ShowProvider from 'src/pages/ShowProvider';
import ShowIndividual from './pages/ShowIndividual';
import GuardedRoute from './components/helpers/AuthGuard';

const routes = [
  {
    path: 'app',
    element: <GuardedRoute component={DashboardLayout} />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'add-provider', element: <ProviderForm /> },
      { path: 'add-individual', element: <IndividualFormSection />},
      { path: 'edit-provider/:id', element: <ProviderForm /> },
      { path: 'edit-individual/:id', element: <IndividualFormSection /> },
      { path: 'providers', element: <ProvidersList /> },
      { path: 'individuals', element: <IndividualsList />},
      { path: 'show-provider/:id', element: <ShowProvider /> },
      { path: 'show-individual/:id', element: <ShowIndividual /> },
      { path: 'users', element: <UserList /> },
      { path: 'add-user', element: <UserForm /> },
      { path: 'edit-user/:id', element: <UserFormEdit /> },
      { path: 'change-password/:id', element: <UserFormChangePassword /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'access-edit-public-provider', element: <Directory /> },
      { path: 'public-access/:type', element: <Directory /> },
      { path: 'edit-public-provider', element: <ProviderFormPublic /> },
      { path: 'edit-public-individual', element: <IndividualFormPublic /> },
      { path: 'change-password', element: <ChangePassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/providers" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];
export default routes;
