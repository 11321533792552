import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Logo from '../components/Logo';
import restApiService from '../services/restService';
import { values } from 'lodash-es';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { LOGIN_URL } from '../utils/Constants'

const Login = () => {
  const navigate = useNavigate();
  const { callService } = restApiService();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [request, setRequest] = React.useState('');

  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    let isMounted = true;
    if (request) {
      callService('POST', LOGIN_URL, { data: request }, false)
        .then(response => {
          if (isMounted)
            if (response.status == 200) {
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('authorities', response.data.authorities);
              if (response.data.firstLogin) {
                navigate('/change-password');
              } else {
                navigate('/app/providers');
              }
            } else {
              setMessage(response.data.message);
              handleToOpen();
            }
        });
      return () => { isMounted = false };
    }
  },
    [request] // Only call effect if debounced search term changes
  );
  return (
    <>
      <Helmet>
        <title>Login | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, actions) => {
              let request2 = {
                username: values.email,
                password: values.password
              }
              actions.setSubmitting(false);
              setRequest(request2)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Logo />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }} >
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => { isSubmitting = true }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom"
            }} open={open} autoHideDuration={3000} onClose={handleToClose} >
            <Alert variant="filled" onClose={handleToClose} severity="error">
              {message}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </>
  );
};

export default Login;
