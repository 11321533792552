import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import ProviderContext from './ProviderContext';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
const ProviderForm = (props) => {
  const [showInputs, setShowInputs] = useState(false);
  const contextValue = useContext(ProviderContext);

  const update = (form) => {
    if (form.enabledShared.value) {
      setShowInputs(true)
    }
  }


  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      update(contextValue.provider)
    return () => { isMounted = false };
  }, [contextValue])

  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik enableReinitialize initialValues={value.provider} >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                noValidate
                {...props}>
                <Card>
                  <CardHeader
                    title="Other details"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Enable Share?"
                          name="enabledShared"
                          select
                          SelectProps={{
                            value: values.enabledShared && values.enabledShared.key ? values.enabledShared.key : "",
                            onChange: (e) => {
                              let state = props.singletype.find(row => {
                                if (row.key == e.target.value) {
                                  return true
                                }
                                return false
                              })
                              setShowInputs(false)
                              if (state.canAddData)
                                setShowInputs(true)
                              value.updateProvider(e, "enabledShared", "key")
                            }
                          }}
                          variant="outlined">
                          {props.singletype.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {showInputs && [<Grid key={1}
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Email"
                          name="enabledSharedData"
                          onChange={(e) => { value.updateProvider(e, "enabledSharedData", "value") }}
                          value={values.enabledSharedData || ""}
                          variant="outlined"
                          helperText={values.enabledSharedData ? `Number of characters ${values.enabledSharedData.length}/255` : ''}
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                      </Grid>,
                      <Grid key={2}
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Name"
                          name="enabledSharedDataName"
                          onChange={(e) => { value.updateProvider(e, "enabledSharedDataName", "value") }}
                          value={values.enabledSharedDataName || ""}
                          variant="outlined"
                          helperText={values.enabledSharedDataName ? `Number of characters ${values.enabledSharedDataName.length}/255` : ''}
                          inputProps={{
                            maxLength: 255
                          }}
                        />
                      </Grid>]}
                    </Grid>

                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer >
  )
};

export default ProviderForm;