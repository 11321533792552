import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ProviderListToolbar from 'src/components/product/ProviderListToolbar';
import ProductCard from 'src/components/product//ProductCard';
import restApiService from '../services/restService';
import { PROVIDERS_URL } from '../utils/Constants';
import ProductFilter from '../components/provider/FilterComponent';
import ProviderContext from '../components/provider/ProviderContext';
import TablePaginator from '../components/shared/TablePaginator';
import useDebounce from 'src/components/helpers/debounceTime';

const ProvidersList = ({ provider, ...rest }) => {
  const { callService } = restApiService();
  const [providerResponse, setProviderResponse] = useState({
    listOfElements: [],
    pageNumber: 0,
    totalOfElements: 0,
    totalPages: 0
  });
  const [request, setRequest] = useState(undefined);
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState("")
  const debouncedSearchTerm = useDebounce(request, 500);

  useEffect(() => {
    let isMounted = true;
    if (debouncedSearchTerm) {
      callService('POST', PROVIDERS_URL, { data: debouncedSearchTerm }, true)
        .then(response => {
          setLoading(false)
          if (isMounted)
            if (response && response.status === 200) {
              setProviderResponse({ ...response.data }, response.data)
              if (response && response.data.listOfElements.length === 0)
                setMessage("There are no providers that match your criteria. Please remove some of the filters and try again.")
              else
                setMessage("")
            }
        });
    }
    return () => { isMounted = false };
  },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleChangePage = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      ...value
    }))
  }

  const handleFilter = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      ...value
    }))
  }

  const handleSearch = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      "searchColumn": value.searchColumn
    }))
  }

  const handleSorts = (value) => {
    setLoading(true)
    setRequest(prevState => ({
      ...prevState,
      "sorts": value.sorts
    }))
  }

  const styles = {
    flexCenter: {
      display: "flex",
      'justifyContent': 'center',
      'alignContent': 'center',
      'width': '100%',
      'height': '100%'
    },
    grid: {
      display: "flex",
      "justifyContent": "center",
      padding: "50px",
    },
    textError: {

    }
  };
  return (
    <>
      <Helmet>
        <title>Provider | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ProviderContext.Provider value={{ request: request, handleSearch: handleSearch, handleSorts: handleSorts }}>
            <ProviderListToolbar providers={providerResponse} />
          </ProviderContext.Provider>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} >
              <Box>
                <Grid container spacing={1}>
                  <ProviderContext.Provider value={{ request: request, handleFilter: handleFilter }}>
                    <ProductFilter />
                  </ProviderContext.Provider>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} >
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={3} >
                  {!loading && !message && providerResponse.listOfElements?.map((p) => (
                    <Grid
                      item
                      key={p.id}
                      lg={4}
                      md={6}
                      xs={12}
                    >
                      <ProductCard provider={p} />
                    </Grid>
                  ))}

                </Grid>
              </Box>
              {!loading && message &&
                <div style={styles.grid}>
                  <div style={styles.flexCenter}>
                    <Typography
                      align="left"
                      color="black"
                      variant="body1"
                    >
                      <span>{message}</span>
                    </Typography>
                  </div>
                </div>
              }
              {loading &&
                <div style={styles.grid}>
                  <div style={styles.flexCenter}>
                    <CircularProgress />
                  </div>
                </div>
              }
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 3
                }}
              >
                <ProviderContext.Provider value={{}}>
                  <TablePaginator totalOfElements={providerResponse.totalOfElements} onPageChange={handleChangePage}></TablePaginator>
                </ProviderContext.Provider>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box >
    </>
  )
};

export default ProvidersList;
