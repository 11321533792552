import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, Grid } from "@material-ui/core";
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const 
CustomMultiSelectAutocomplete = (props) => {
    const [textBoxDisabled, setTextBoxDisabled] = useState(true);

    useEffect(() => {
        if (props.inputValue)
            setTextBoxDisabled(false)
        else {
            setTextBoxDisabled(true)
            let result = [{ value: '', fieldName: props.inputName, keyOrValue: 'value' }]
            props.onChangeInput(result)
        }
    }, [])

    const handleChangeDropDown = (event, selectValues) => {
        if(selectValues.includes(props.otherIndex)) {
            setTextBoxDisabled(false)
        } else {
            setTextBoxDisabled(true)
            let result = [{ value: '', fieldName: props.inputName, keyOrValue: 'value' }]
            props.onChangeInput(result)
        }
        props.onChangeAutoComplete(props.name, selectValues);
    };

    const handleChangeInput = (event) => {
        let result = [{ value: event.target.value, fieldName: props.inputName, keyOrValue: 'value' }]
        props.onChangeInput(result);
    };

    return (
        <Grid item md={12} xs={12} key={1} justify="space-between" container spacing={5}>
            <Grid item md={6} xs={6}>
                    <Autocomplete
                        multiple
                        id="custom-multiselect-autocomplete"
                        options={props.listElements.map((option) => option.value)}
                        defaultValue={
                            () => {
                                let selectedValues = []         
                                props.defaultValue.forEach(selectedElement => {
                                    props.listElements.forEach(element => {
                                        if(selectedElement === element.key) 
                                            selectedValues.push(element.value)
                                    })
                                })
                                return selectedValues
                            }
                        }
                        filterSelectedOptions
                        onChange={async (e, selectedValues) => {
                            let newValues = []
                            props.listElements.forEach(element => {
                                selectedValues.forEach(selectedElement => {
                                    if(selectedElement === element.value) 
                                        newValues.push(element.key)
                                })
                            })
                            handleChangeDropDown(e, newValues)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={props.label}
                            />
                        )}
                    />
            </Grid >
            <Grid item md={6} xs={6}>
                <TextField
                    fullWidth
                    label={props.inputLabel}
                    name={props.inputName}
                    disabled={textBoxDisabled}
                    value={textBoxDisabled ? '' : props.inputValue}
                    variant="outlined"
                    onChange={handleChangeInput}
                    helperText={props.inputValue ? `Number of characters ${props.inputValue.length}/255` : ''}
                    inputProps={{
                        maxLength: 255,
                        startAdornment: ( 
                            <InputAdornment position="start">
                                <PublicIcon />
                            </InputAdornment>
                        ),
                    }}
                />            
            </Grid>
        </Grid >)
}

export default CustomMultiSelectAutocomplete;
