import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Button
} from '@material-ui/core';
import restApiService from '../services/restService';
import { GET_PROVIDER, EXPORT_CONSULTAN, PROVIDER_EXPORT_PLACEMENT } from '../utils/Constants';
import { useParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';

const ProductList = (props) => {
  const styles = {
    spaceBetween: {
      display: "flex",
      'justifyContent': 'space-between',
      'width': '100%',
      'alignItems': 'center'
    },
    black: {
      fontWeight: 600
    },
    marginStyle: {
      marginLeft: 20
    },
  };

  const { id } = useParams();
  const { callService, callServiceDownload } = restApiService();
  const navigate = useNavigate();

  useEffect(() => {
    callService('GET', GET_PROVIDER + id, {}, true)
      .then(response => {
        if (response.status == 200) {
          setProvider({ ...response.data }, response.data)
        }
      });
  }, [])

  /* CONTEXT INFORMATION */
  const [provider, setProvider] = useState({
    acceptCommitments: null,
    addictiveMedAllowed: null,
    address: null,
    address2: null,
    admissionEmail: null,
    admissionPhone: null,
    afterAdmissions: null,
    ageRange: null,
    averageAdmissionProcess: null,
    averageLengthStay: null,
    city: null,
    country: null,
    gender: null,
    handicapAccessible: null,
    insuranceAccepted: null,
    lgbtqFriendly: null,
    medicalClearance: null,
    monthlyFinancialCost: null,
    notes: null,
    numberBeds: null,
    offerMedAssistedTreatment: null,
    opgReferredBefore: null,
    preferredClinicians: null,
    preferredContacts: null,
    privatePayMonthlyRate: null,
    programName: null,
    shortTermAssessmentServices: null,
    smokingCessationDescription: null,
    smokingCessationServices: null,
    smokingPermitted: null,
    state: null,
    summary: null,
    therapyHours: null,
    website: null,
    zip: null
  });

  const callExportWord = (endpointFunction, id, programName, reportName) => {
    callServiceDownload('GET', endpointFunction(id), undefined, true,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", programName +'-'+ reportName + '.docx');
  }

  const printMultipleValuesField = (values, excludeValue, additionalInformation) => {
    var result = "";
    if(values.length > 0) {
      values.forEach(function(entry) {
        if(entry.key != excludeValue) {
          if(result === "")
            result = entry.value;
          else
            result = result + ", " + entry.value;
        }
      });
    } else {
      return "-";
    }
    if(additionalInformation) {
      if(additionalInformation && result ==="")
        result = additionalInformation;
      else
        result = result + ", " + additionalInformation;
      return result;
    } else {
      if(values && values.length === 0 )
        return "-";
      else
        return result;
    }
  };

  return (
    <>
      <Helmet>
        <title>Provider | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
            <Button sx={{ mx: 1 }} onClick={() => { callExportWord(PROVIDER_EXPORT_PLACEMENT, provider.id, provider.programName, "Placement") }}>
              Placement Report
            </Button>
            <Button sx={{ mx: 1 }} onClick={() => { callExportWord(EXPORT_CONSULTAN, provider.id, provider.programName, "Consultant") }}>
              Consult Report
            </Button>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <div style={{ color: "black" }}
              title="Provider details">
              <div style={{
                padding: "15px", display: "flex", justifyContent: "space-between", alignItems: "center", fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontWeight: 700, fontSize: '1.1rem', lineHeight: '1.5', letterSpacing: '0.00938em', textAlign: 'left', color: 'black'
              }}>
                <span>
                  Provider Detail
                </span>
                <Button
                  startIcon={<ArrowBackIos />}
                  color="primary"
                  onClick={() => navigate('/app/providers')}
                  variant="contained">
                  Go back
                </Button>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={6}
                  style={{ color: "black" }}
                >
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>CONTACT INFORMATION</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Program name:</span> <span>{provider.programName ? provider.programName : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Address #1:</span> <span>{provider.address ? provider.address : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Address #2:</span> <span>{provider.address2 ? provider.address2 : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Address #3:</span> <span>{provider.address3 ? provider.address3 : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Admissions Contact Phone:</span> <span>{provider.admissionPhone ? provider.admissionPhone : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Admissions Email:</span> <span>{provider.admissionEmail ? provider.admissionEmail : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Primary Contact:</span> <span>{provider.preferredContacts ? provider.preferredContacts : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Primary Contact Information:</span> <span>{provider.primaryContactInfo ? provider.primaryContactInfo : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Website:</span> <span>{provider.website ? provider.website : '-'}</span></div>
                  </Typography>
                  <br></br>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>PROGRAM DETAIL</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Referred Before:</span> <span>{provider.opgReferredBefore ? provider.opgReferredBefore.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Program Type:</span> <span>{provider.programType ? printMultipleValuesField(provider.programType, 5, provider.programTypeData) : "-" }</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Leisure Activities Offered:</span> <span>{provider.leisureOffered ? provider.leisureOffered : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Admission Requirements:</span> <span>{provider.admissionRequirements ? provider.admissionRequirements : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Admission Rule Outs:</span> <span>{provider.admissionRules ? provider.admissionRules : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Diagnoses/Conditions:</span> <span>{provider.diagnosesConditions ? provider.diagnosesConditions : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>COVID Protocols:</span> <span>{provider.covidProtocols ? provider.covidProtocols : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>LGBTQ+ Friendly?:</span> <span>{provider.lgbtqFriendly ? provider.lgbtqFriendly.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>LGBTQ+ Friendly Aditional Information:</span> <span>{provider.lgbtqFriendlyData ? provider.lgbtqFriendlyData : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>LGBTQ+ Services Offered:</span> <span>{provider.lgbtqServiceType && provider.lgbtqServiceTypeData === null ? provider.lgbtqServiceType.value : provider.lgbtqServiceTypeData}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Other - LGBTQ+ Services Offered:</span> <span>{provider.lgbtqServiceTypeData ? provider.lgbtqServiceTypeData.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Program Size / # of Beds or Participants:</span> <span>{provider.numberBeds ? provider.numberBeds : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Average Length of Stay:</span> <span>{provider.averageLengthStay ? provider.averageLengthStay : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Minimum Length of Stay:</span> <span>{provider.minLengthStay ? provider.minLengthStay : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Maximum Length of Stay:</span> <span>{provider.maxLengthStay ? provider.maxLengthStay : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Option to Extend stay:</span> <span>{provider.extendStay ? provider.extendStay : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Are There Separate Wings by Gender?:</span> <span>{provider.separateByGender && provider.separateByGenderData === null ? provider.separateByGender.value : provider.separateByGenderData }</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Bed Assignments:</span> <span>{provider.bedAssigmentType ? printMultipleValuesField(provider.bedAssigmentType, 5, provider.bedAssigmentTypeData) : "-"}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Meals:</span> <span>{provider.meals ? provider.meals : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Edu / Voc Opportunities?:</span> <span>{provider.opportunityType ?  printMultipleValuesField(provider.opportunityType, undefined, undefined) : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Family Therapy or Family Component?:</span> <span>{provider.familyTherapyOrComponent ? provider.familyTherapyOrComponent.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Frequency of Meetings with Family Therapist:</span> <span>{provider.frequencyMeetFamilyPsy ? provider.frequencyMeetFamilyPsy : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Additional Family Component Information:</span> <span>{provider.familyInfo ? provider.familyInfo : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Treatment Modalities:</span> <span>{provider.treatmentModalities ? provider.treatmentModalities : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Type(s) of Therapy Hour:</span> <span>{provider.therapyHoursType ? printMultipleValuesField(provider.therapyHoursType, undefined, undefined) : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Frequency of Meetings with a Therapist:</span> <span>{provider.therapyHours ? provider.therapyHours : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Clinical / Other Service Highlights:</span> <span>{provider.clinicalHighlights ? provider.clinicalHighlights : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Specialty Programs / Tracks Offered:</span> <span>{provider.specialtyOffered ? provider.specialtyOffered : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Smoking Cessation Services:</span> <span>{provider.smokingCessationServices ? provider.smokingCessationServices.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Offers Medication Assisted Treatment:</span> <span>{provider.addictiveMedAllowed ? provider.addictiveMedAllowed.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>On-site Medical Clearance:</span> <span>{provider.medicalClearance ? provider.medicalClearance.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Psychiatry & Rx Management:</span> <span>{provider.managementType ? printMultipleValuesField(provider.managementType, undefined, undefined) : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Frequency of Meetings with a Psychiatrist:</span> <span>{provider.frequencyMeetPsy ? provider.frequencyMeetPsy : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Is there Medical and Nursing Oversight:</span> <span>{provider.medicalNursingOversight ? provider.medicalNursingOversight : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Short Term Assessment Services:</span> <span>{provider.shortTermAssessmentServices ? provider.shortTermAssessmentServices : '-'}</span></div>
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                  style={styles.black}
                >
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>PATIENT POPULATON</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Gender:</span> <span>{provider.gender ? provider.gender.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Age Range:</span> <span>{provider.ageRange ? provider.ageRange.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Average Age of Milieu:</span> <span>{provider.averageMilieu ? provider.averageMilieu : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Typical Client Profile :</span> <span>{provider.clientProfile ? provider.clientProfile : '-'}</span></div>
                  </Typography>
                  <br></br>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>FINANCIAL DETAIL</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Monthly $ w/ Insurance:</span> <span>{provider.monthlyFinancialCost ? provider.monthlyFinancialCost : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Monthly $ out of pocket:</span> <span>{provider.privatePayMonthlyRate ? provider.privatePayMonthlyRate : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Insurance Accepted:</span> <span>{provider.insuranceAccepted ? provider.insuranceAccepted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Insurance Policies Accepted:</span> <span>{provider.insurancePoliciesAccepted ? provider.insurancePoliciesAccepted : '-'}</span></div>
                  </Typography>
                  <br></br>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>OTHER DETAIL</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Electronics Permitted?:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  {provider.elecPermittedData &&  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Electronics Permitted Aditional Information:</span> <span>{provider.elecPermittedData ? provider.elecPermittedData : '-'}</span></div>
                  </Typography>}
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Electronics Protocol:</span> <span>{provider.electronicsProtocol ? provider.electronicsProtocol : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>License & Accreditation:</span> <span>{provider.licenseAccreditation ? provider.licenseAccreditation : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Smoking Permitted:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Vaping Permitted:</span> <span>{provider.vapingPermitted ? provider.vapingPermitted : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Handicap Accessible:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Medical Complexities Accepted:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Medical Complexities Accepted:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Accepts Involuntary Commitments:</span> <span>{provider.elecPermitted ? provider.elecPermitted.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Extended Care / Step Down Options:</span> <span>{provider.extendedCare ? provider.extendedCare : '-'}</span></div>
                  </Typography>
                  <br></br>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>SHARED</span></div>
                  </Typography>
                  <hr></hr>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Enable Share?:</span> <span>{provider.enabledShared ? provider.enabledShared.value : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Email:</span> <span>{provider.enabledSharedData ? provider.enabledSharedData : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Name:</span> <span>{provider.enabledSharedDataName ? provider.enabledSharedDataName : '-'}</span></div>
                  </Typography>
                  <br></br>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Token:</span> <span>{provider.token ? provider.token : '-'}</span></div>
                  </Typography>
                  <Typography component={'span'}
                    align="left"
                    color="black"
                    variant="body1"
                  >
                    <div style={styles.spaceBetween}><span style={styles.black}>Summary:</span> <span style={styles.marginStyle}>{provider.summary ? provider.summary : '-'}</span></div>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
};

export default ProductList;
