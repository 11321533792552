// in the useDebounce file
import { memo, useCallback, useEffect, useState } from 'react';
/**
 * @description for use in functions with side-effects but no return value
 * @export useDebouncedFunction
 */
export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}