import React, { useState, useEffect, useCallback } from "react"
import { Prompt, useNavigate } from "react-router-dom"
import { 
    Button,
    StepContent,
    Step,
    StepButton,
    Stepper,
    Typography,
    CircularProgress,
    Snackbar,
    Alert
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { GET_INDIVIDUAL, POST_INDIVIDUAL, PUT_INDIVIDUAL, FILTER_VALUES, FILTER_VALUES_DATA, FILTER_COUNTRY, FILTER_BOOL } from "src/utils/Constants"
import restApiService from "src/services/restService"
import IndividualsContext from "./IndividualsContext"
import FirstStep from "./GeneralInformation"
import SecondStep from "./Cost.js"
import ThirdStep from "./Access"
import FourthStep from "./Shared.js"
import { indexOf } from "lodash"

const IndividualForm = ({ id }) => {
    // STYLING
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%"
        },
        buttonRight: {
            display: "flex",
            width: "100%",
            justifyContent: "flex-end"
        },
        button: {
            marginTop: 1,
            marginRight: 1,
        },
        actionsContainer: {
            marginBottom: 1
        },
        centerSpinner: {
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center"
        }
    }))
    const classes = useStyles()

    const { callService } = restApiService()
    const navigate = useNavigate()

    const [invalidForm, setInvalidForm] = useState(true)
    const [saving, setSaving] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [loadedIndividual, setLoadedIndividual] = useState(false)
    const [loadedDependencies, setLoadedDependencies] = useState(false)
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState('success')

    const delay = (function () {
        var timer = 0
        return function (callback, ms) {
          clearTimeout(timer)
          timer = setTimeout(callback, ms)
        }
    })()
    
    const [individual, setIndividual] = useState({
        id: undefined,
        latitude: undefined,
        longitude: undefined,
        permissions: undefined,
        // General Information
        firstName: '',
        lastName: '',
        groupPractice: '',
        directPhoneNumber: undefined,
        mainPhone: undefined,
        emailAddress: '',
        officeAddress: undefined,
        country: undefined,
        state: undefined,
        zip: undefined,
        city: undefined,
        latitude: undefined,
        longitude: undefined,
        type: undefined,
        clientAgeRanges: [],
        website: '',
        psychologyTodayLink: '',
        briefSummary: undefined,
        // Cost
        networkWith: [],
        networkWithData: '',
        initialSessionFee: undefined,
        ongoingAppointmentFee: undefined,
        offersSuperBill: undefined, 
        // Access
        availableInPerson: undefined,
        offersTelehealth: undefined,
        statesWithLicensure: [],
        // Shared
        enabledShared: undefined,
        enabledSharedData: '',
        enabledSharedDataName: ''
    })

    useEffect(() => {
        if (id) {
            callService("GET", GET_INDIVIDUAL + id, {}, true)
                .then((response) => {
                    if (response && response.status === 200) {
                        let data = {...response.data}
                        var clientRangesArr = []
                        var statesWithLicensureArr = []
                        var networksWithArr = []
                        data.clientAgeRanges.forEach( e => clientRangesArr.push(e.key))
                        data.statesWithLicensure.forEach( e => statesWithLicensureArr.push(e.key))
                        data.networkWith.forEach( e => networksWithArr.push(e.key))
                        data.clientAgeRanges =  clientRangesArr
                        data.statesWithLicensure = statesWithLicensureArr
                        data.networkWith = networksWithArr
                        data.enabledSharedData = data.enabledSharedData ? data.enabledSharedData : ''
                        data.enabledSharedDataName = data.enabledSharedDataName ? data.enabledSharedDataName : ''
                        data.groupPractice = data.groupPractice ? data.groupPractice : '' 
                        data.website = data.website ? data.website : '' 
                        setIndividual(data , individual)
                        setLoadedIndividual(true)
                    }
                })
        } else {
            setLoadedIndividual(true)
        }
    }, [])

    const [type, setType] = useState([])
    const [clientAgeRanges, setClientAgeRanges] = useState([])
    const [networkWith, setNetworkWith] = useState([])
    const [offersSuperBill, setOffersSuperBill] = useState([])
    const [availableInPerson, setAvailableInPerson] = useState([])
    const [offersTelehealth, setOffersTelehealth] = useState()
    const [statesWithLicensure, setStatesWithLicensure] = useState([])
    const [singleType, setSingleType] = useState([])
    const [country, setCountry] = useState([])

    useEffect(() => {
        let isMounted = true
        Promise.all([
            /** 0 */ callService("GET", FILTER_VALUES + "type", {}, true),
            /** 1 */ callService("GET", FILTER_VALUES + "clientRanges", {}, true),
            /** 2 */ callService("GET", FILTER_VALUES_DATA + "network", {}, true),
            /** 3 */ callService("GET", FILTER_VALUES + "state", {}, true),
            /** 4 */ callService("GET", FILTER_VALUES_DATA + "shared", {}, true),
            /** 5 */ callService('GET', FILTER_VALUES + FILTER_BOOL, {}, true),
            /** 6 */ callService('GET', FILTER_VALUES + FILTER_COUNTRY, {}, true),
        ]).then(response => {
            if (isMounted) {
                if (response && response[0].status === 200) {
                    setType([...response[0].data])
                    setClientAgeRanges([...response[1].data])
                    setNetworkWith([...response[2].data])
                    setStatesWithLicensure([...response[3].data])
                    setSingleType([...response[4].data])
                    setAvailableInPerson([...response[4].data])
                    setOffersSuperBill([...response[4].data])
                    setOffersTelehealth([...response[4].data])
                    setCountry([...response[6].data])
                    setLoadedDependencies(true)
                }
            }
        })
        return () => { isMounted = false }
    }, [])

    //  CONTEXT DATA
    const updateIndividual = useCallback((e, fieldName, keyOrValue) => {
        try {
          let individualCopy = { ...individual }
          if (keyOrValue === "value") {
            individualCopy[fieldName] = e.target.value
          }
          if (keyOrValue === "array") {
            if (fieldName.includes("officeAddress")) {
                fieldName.forEach(key => {
                    individualCopy[key] = e.target.value[key];
                })
            } else {
                let arr = Array.from(individualCopy[fieldName])
                arr.concat([e.target.value])
            }
          }
          if (keyOrValue === "object") {
            individualCopy[fieldName] = { key: e.target.value }
          }
          setIndividual(individualCopy)
          setShowPrompt(true)
        } catch (error) {}
    }, [individual])
    
    const updateIndividualWithoutEvent = useCallback((fieldName, value) => {
        try {
          let individualCopy = { ...individual }
          individualCopy[fieldName] = value
          setIndividual(individualCopy)
          setShowPrompt(true)
        } catch (error) {}
    }, [individual])

    const updateIndividualBulk = useCallback((fields) => {
        debugger
        let individualCopy = { ...individual }
        if (fields && fields.length)
            fields.forEach(fl => {
            if (fl.keyOrValue === "value") {
                individualCopy[fl.fieldName] = fl.value
            } else {
                individualCopy[fl.fieldName] = { key: fl.value }
            }
            setIndividual(individualCopy)
            setShowPrompt(true)
            })
        }, [individual]
    )
    
    const saveIndividual = () => {
        individual.initialSessionFee = (Math.floor(parseFloat(individual.initialSessionFee) * 100) / 100).toFixed(2)
        individual.ongoingAppointmentFee = (Math.floor(parseFloat(individual.ongoingAppointmentFee) * 100) / 100).toFixed(2)
        debugger
        const request = {
            id: 2,
            latitude: individual.latitude,
            longitude: individual.longitude,
            // General Information
            firstName: individual.firstName,
            lastName: individual.lastName,
            groupPractice: individual.groupPractice,
            directPhoneNumber: individual.directPhoneNumber,
            mainPhone: individual.mainPhone,
            emailAddress: individual.emailAddress,
            officeAddress: individual.officeAddress,
            country: individual.country?.key,
            state: individual.state?.key,
            zip: individual.zip,
            city: individual.city,
            latitude: individual.latitude,
            longitude: individual.longitude,
            type: individual.type?.key,
            clientAgeRanges: individual.clientAgeRanges,
            website: individual.website === "" ? undefined : individual.website,
            psychologyTodayLink: individual.psychologyTodayLink,
            briefSummary: individual.briefSummary,
            // Cost
            networksWith: individual.networkWith,
            networkWithData: individual.networkWithData,
            initialSessionFee: individual.initialSessionFee !== "NaN" ? individual.initialSessionFee : undefined,
            ongoingAppointmentFee: individual.ongoingAppointmentFee !== "NaN" ? individual.ongoingAppointmentFee : undefined,
            offersSuperBill: individual.offersSuperBill?.key,
            // Access
            availableInPerson: individual.availableInPerson?.key,
            offersTelehealth: individual.offersTelehealth?.key,
            statesWithLicensure: individual.statesWithLicensure,
            // Shared
            enabledShared: individual.enabledShared?.key,
            enabledSharedData: individual.enabledSharedData,
            enabledSharedDataName: individual.enabledSharedDataName
        }
        
        if (id) {
            callService("PUT", PUT_INDIVIDUAL + id, { data: request }, true)
                .then((response) => {
                    if (response.status === 200) {
                        setShowPrompt(false)
                        setMessage("The individual has been edit successfully")
                        setSeverity("success")
                        handleOpen()
                        delay(function () {
                            navigate("/app/individuals/")
                        }, 3000)
                    } else {
                        setSaving(undefined)
                        var errors = "ERRORS: "
                        if (response) {
                            if (response.data && response.data.errors && response.data.errors.length) {
                                for (var e of response.data.errors) {
                                    errors = errors + ' ' + e + ''
                                }
                            }
                            setMessage(errors)
                            setSeverity("error")
                            handleOpen()
                        }
                    }
                })
        } else {
            callService("POST", POST_INDIVIDUAL, { data: request }, true)
            .then((response) => {
                if (response.status === 200) {
                    setShowPrompt(false)
                    setMessage('The individual has been created successfully')
                    setSeverity('success')
                    handleOpen()
                    delay(function () {
                        navigate('/app/show-individual/' + response.data.id)
                    }, 3000)
                } else {
                    setSaving(false)
                    var errors = "ERRORS: "
                    if (response.data && response.data.errors && response.data.errors.length) {
                        for (var e of response.data.errors) {
                            errors = errors + ' ' + e + ''
                        }
                    }
                    setMessage(errors)
                    setSeverity('error')
                    handleOpen()
                }
            })
        }
    }

    const handleSave = () => {
        setShowPrompt(false)
        setSaving(true)
        saveIndividual()
    }

    function getSteps() {
        return ["General Information", "Cost", "Access", "Shared"]
    }

    const [activeStep, setActiveStep] = useState(0)
    const steps = getSteps()

    const handleStep = (step) => () => {
        setActiveStep(step)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        if (activeStep === steps.length - 1) { saveIndividual() }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        navigate("/app/individuals")
    }

    function getStepContent(step) {
        if (loadedIndividual && loadedDependencies) {
            switch (step) {
                    case 0:
                        return (
                            <IndividualsContext.Provider value={{
                                individual: individual,
                                updateIndividual: updateIndividual,
                                updateIndividualBulk: updateIndividualBulk,
                                updateIndividualWithoutEvent: updateIndividualWithoutEvent,
                                updateInvalidForm: (isInvalid) => { setInvalidForm(isInvalid) },
                                saving: saving
                            }}>
                                <FirstStep
                                    type={type}
                                    clientAgeRanges={clientAgeRanges}
                                    country={country}
                                />
                            </IndividualsContext.Provider>
                        )
                    case 1:
                        return (
                            <IndividualsContext.Provider value={{
                                individual: individual,
                                updateIndividual: updateIndividual,
                                updateIndividualBulk: updateIndividualBulk,
                                updateIndividualWithoutEvent: updateIndividualWithoutEvent,
                                updateInvalidForm: (isInvalid) => { setInvalidForm(isInvalid) }
                            }}>
                                <SecondStep
                                    networkWith={networkWith}
                                    offersSuperBill={offersSuperBill}
                                />
                            </IndividualsContext.Provider>
                        )
                    case 2:
                        return (
                            <IndividualsContext.Provider value={{
                                individual: individual,
                                updateIndividual: updateIndividual,
                                updateIndividualBulk: updateIndividualBulk,
                                updateIndividualWithoutEvent: updateIndividualWithoutEvent,
                                updateInvalidForm: (isInvalid) => { setInvalidForm(isInvalid) }
                            }}>
                                <ThirdStep
                                    availableInPerson={availableInPerson}
                                    offersTelehealth={offersTelehealth}
                                    statesWithLicensure={statesWithLicensure}
                                />
                            </IndividualsContext.Provider>
                        )
                    case 3:
                        return (
                            <IndividualsContext.Provider value={{
                                individual: individual,
                                updateIndividual: updateIndividual,
                                updateIndividualBulk: updateIndividualBulk,
                                updateInvalidForm: (isInvalid) => { setInvalidForm(isInvalid) }
                            }}>
                                <FourthStep
                                    singleType={singleType}
                                />
                            </IndividualsContext.Provider>
                        )
                    default:
                        return "Unkown step"
                }   
            }
    }

    const getStepper = () => {
        if (loadedIndividual && loadedDependencies) {
            return (
                <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                >
                    {
                        steps.map((label, i) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(i)}>{label}</StepButton>
                                <StepContent>
                                    <Typography component={"span"}>{getStepContent(i)}</Typography>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0 || saving}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            {
                                                (activeStep !== steps.length - 1) &&
                                                <Button
                                                    disabled={saving}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    Next
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))
                    }
                </Stepper>
            )
        } else {
            return (
                <div className={classes.centerSpinner}>
                    <CircularProgress />
                </div>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Prompt 
                when={showPrompt}
                message="You are about to leave without saving changes."
            />
            <div className={classes.buttonRight}>
                <Button
                    disabled={saving}
                    onClick={handleCancel}
                    className={classes.button}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={invalidForm || saving}
                    onClick={handleSave}
                    className={classes.button}
                >
                    Finish
                </Button>
            </div>

            {getStepper()}

            <div className={classes.buttonRight}>
                <Button
                    disabled={saving}
                    onClick={handleCancel}
                    className={classes.button}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={invalidForm || saving}
                    onClick={handleSave}
                    className={classes.button}
                >
                    Finish
                </Button>
            </div>

            <Snackbar
                anchorOrigin={{
                horizontal: "center",
                vertical: "bottom"
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default IndividualForm