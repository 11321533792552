import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, Card, CardContent, Grid, Icon, Typography } from "@material-ui/core";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const CollapsableItem = (props) => {
    const [showContent, setShowContent] = useState(false);
    const styles = {
        spaceBetween: {
            display: "flex",
            'justifyContent': 'space-between',
            'width': '100%',
            'padding': '1.5em 0',
            'alignItems': 'center'
        },
        dropDown: {
            display: "flex",
            width: "100%",
            "flexDirection": 'column',
        },
        center: {
            display: "flex",
            'alignItems': 'center'
        },
        listIn: {
            "listStyleType": "none"
        },
        listOut: {
            "listStyleType": "none",
            "height": "0",
            "display": "none",
            "transition": "all 0.5s ease"
        },
        list: {
            display: "flex",
            "paddingLeft": "25px"
        },
        flex: {
            display: "flex"
        }
    };
    const Inner = props.inner

    const handleClick = (e) => {
        setShowContent(!showContent)
    }

    const renderDropDown = (title) => {
        return <div style={styles.dropDown}>
            <Button
                onClick={handleClick}
                sx={{
                    color: 'black',
                    fontWeight: 'medium',
                    justifyContent: 'flex-start',
                    padding: '0 5px !important',
                    letterSpacing: 0,
                    background: showContent ? '#e6e6e6' : undefined,
                    py: 1.25,
                    textTransform: 'none',
                    width: '100%',
                    '& svg': {
                        mr: 1
                    }
                }}>
                <div style={styles.spaceBetween}>
                    <div style={styles.center}>
                        <span> {title}</span>
                    </div>
                    {showContent && (
                        <FaCaretUp size="10" />
                    )}
                    {!showContent && (
                        <FaCaretDown size="10" />
                    )}
                </div>
            </Button>
        </div >
    }
    return (

        <div style={{ maxWidth: '100%', border: '1px black' }}>
            {renderDropDown(props.title)}
            {showContent && <Inner></Inner>}
        </div>
    )
}

export default CollapsableItem;
