import React, { useState } from "react"
import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom"
import { Box, Card, CardContent, IconButton, Snackbar, Typography } from "@material-ui/core"
import  VisibilityIcon from "@material-ui/icons/Visibility"
import  DeleteIcon from "@material-ui/icons/Delete"
import  EditIcon from "@material-ui/icons/Edit"
import  CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/styles"
import ConfirmDialog from "../dialogs/ConfirmDialog"
import restApiService from "src/services/restService"
import { DELETE_INDIVIDUAL } from "src/utils/Constants"

const IndividualCard = ({ individual, ...rest }) => {
    const navigate = useNavigate()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const { callService } = restApiService()

    const fontSizeAdjust = (individualName) => {
        if (individualName.length > 50) {
            return "h6"
        } else {
            if (individualName.length < 30) {
                return "h4"
            } else {
                return "h5"
            }
        }
    }

    const styles = {
        flexRight: {
            display: "flex",
            "justifyContent": "center",
            "width": "100%"
        }
    }

    const useStyles = makeStyles(() => ({
        space: {
            paddingTop: "5px"
        },
        spaceText: {
            padding: "2px 0"
        },
        formControl: {
            display: "flex",
            "justifyContent": "space-between",
            "width": "100%"
        },
        bolder: {
            fontWeight: 800,
            color: "#333",
            fontSize: "0.9em"
        },
        alignRight: {
            display: "flex",
            justifyContent: "flex-end",
            color: "#4c4c4c",
            fontSize: "0.9em"
        }
    }))

    const classes = useStyles()

    const renderActionButtonsRead = () => {
        if (individual.permissions.includes("READ")) {
            return (
                <IconButton color="inherit" onClick={() => navigate("/app/show-individual/" + individual.id)}>
                    <VisibilityIcon color="action" />
                </IconButton>
            )
        }
    }

    const renderActionButtonsDelete = () => {
        if (individual.permissions.includes("DELETE")) {
            return (
                <IconButton color="inherit" onClick={() => { setConfirmOpen(true) }}>
                    <DeleteIcon color="action" />
                </IconButton>
            )
        }
    }

    const renderActionButtonsChangePassword = () => {
        if (individual.permissions.includes("WRITE")) {
            return (
                <IconButton color="inherit" onClick={() => navigate("/app/edit-individual/" + individual.id)}>
                    <EditIcon color="action" />
                </IconButton>
            )
        }
    }

    const delay = () => {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    }

    const toTitleCase = (str) => {
        if (str) {
            return str.toString().replace(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                }
            );
        }
    }

    const deleteIndividual = (id) => {
        callService("DELETE", DELETE_INDIVIDUAL + id, {}, true)
            .then(response => {
                if (response.status === 200) {
                    setMessage("The individual was successfully removed.")
                    setOpen(true)
                    window.location.reload()
                    delay(function () {
                        window.location.reload()
                    }, 1000)
                } else {
                    var errors = "ERRORS: "
                    if (response.data.errors) {
                        for (var e of response.data.errors) {
                            errors = errors + " " + e + ""
                        }
                    } else {
                        errors = errors + response.data.error
                    }
                    setMessage(errors)
                    setOpen(true)
                }
            })
    }

    function printMultipleValues(array) {
        let result = ""

        array.forEach((item) => {
            if (array.indexOf(item) === array.length - 1) {
                result += item.value
            } else {
                result += item.value + ", "
            }

        })

        return result
    }

    const headerInformation = [
        ["First Name: ", individual.firstName],
        ["Last Name: ", individual.lastName],
        ["Group Practice: ", individual.groupPractice],
        ["City and State: ", (individual.city + (individual.state === null ? "" : ", " + individual.state.value) )],
        ["Client Age Ranges: ", printMultipleValues(individual.clientAgeRanges)],
        ["State(s) With Licensure: ", printMultipleValues(individual.statesWithLicensure)],
    ]

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "unset",
                minHeight: 250
            }}
            {...rest}
        >
            <CardContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        pb: 1
                    }}
                ></Box>
                <div style={styles.flex}>
                    <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant={fontSizeAdjust(individual.firstName)}
                    >
                       {individual.firstName + " " + individual.lastName}
                    </Typography>
                    <div className={classes.space}>
                        {
                            headerInformation.map((line, i) => (
                                <Typography className={classes.spaceText} align="left" color="black" component={"span"} variant="body1" key={i}>
                                    <div className={classes.formControl}><span className={classes.bolder}>{line[0]}</span><span className={classes.alignRight}>{toTitleCase(line[1] ? line[1] : "-")}</span></div>
                                </Typography>
                            ))
                        }
                    </div>
                </div>
                <div style={styles.flexRight}>
                    { renderActionButtonsRead() }
                    { renderActionButtonsDelete() } 
                    { renderActionButtonsChangePassword() }
                    
                    <ConfirmDialog
                        title="Delete individual"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={(e) => { deleteIndividual(individual.id) }}
                    >
                        <Typography>Are you sure you want to delete {individual.individualName}?</Typography>
                    </ConfirmDialog>
                </div>
                <Snackbar
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                        backgroundColor: "red"
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setOpen(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                </Snackbar>
            </CardContent>
        </Card>
    )
}


IndividualCard.propTypes = {
    individual: PropTypes.object.isRequired
}  

export default IndividualCard