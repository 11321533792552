import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import roleService from './helpers/RoleChecker';

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);
const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => {
    if (roleService.isAdmin()) {
      return {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 80,
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 200
        }
      }
    } else {
      return {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 80
      }
    }
  }
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const showSidebar = (setMobileNavOpen, isMobileNavOpen) => {
  if (roleService.isAdmin())
    return <DashboardSidebar
      onMobileClose={() => setMobileNavOpen(false)}
      openMobile={isMobileNavOpen}
    />
}

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {showSidebar(setMobileNavOpen, isMobileNavOpen)}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
