import { useState, useEffect } from 'react';
import * as React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import { PATCH_USER } from '../../utils/Constants';
import restApiService from '../../services/restService';
import { User } from 'react-feather';
import * as Yup from 'yup';
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { GET_USER } from '../../utils/Constants';

const UserFormChangePasswordComponent = (props) => {
  const { callService } = restApiService();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleToClose = () => {
    setOpen(false);
  };
  
  const handleToOpen = () => {
    setOpen(true);
  };

  const delay = ( function() {
    var timer = 0;
    return function(callback, ms) {
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
  })();

  useEffect(() => {
    if (props.id) {
      callService('GET', GET_USER + props.id, {}, true)
      .then(response => {
        if(response.status == 200){
          setUser({...response.data}, response.data)
        }else{
          setMessage(response.data.message);
          handleToOpen();
        }
      });
    }
  }, [])

  return (
    <Container maxWidth={false}>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().max(255).required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.').matches(
            /^.*((?=.*[A-Z]){1}).*$/,"Password must contain at least 1 uppercase characters."
          ),
          confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        })}
        onSubmit={(values, actions) => {
          let request = {
            password: values.password,
            confirmPassword: values.confirmPassword
          }
          callService('PATCH', PATCH_USER + props.id, { data: request }, true)
            .then(response => {
              if(response.status == 200){
                handleToOpen();
                setMessage("The user " + user.email + " has been change successfully");
                delay(function(){
                  navigate('/app/users');
                }, 3000 );
              }else{
                var errors = 'ERRORS: ';
                if(response.data.errors){
                  for (var e of response.data.errors) {
                    errors = errors + ' ' + e + '';
                  }
                }else{
                  errors = errors + response.data.error;
                }
                setMessage(errors)
                handleToOpen();
                actions.setSubmitting(false);
              }
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardContent>
              <Typography
                align="left"
                color="black"
                variant="body1"
              >
                First name: {user.firstName}
              </Typography>
              <Typography
                align="left"
                color="black"
                variant="body1"
              >
                Last name: {user.lastName}
              </Typography>
              <Typography
                align="left"
                color="black"
                variant="body1"
              >
                Email: {user.email}
              </Typography>
              <br></br>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      required
                      value={values.password}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      onBlur={handleBlur}
                      label="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      required
                      value={values.confirmPassword}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={{marginRight:10}}
                  color="primary"
                  variant="contained"
                  onClick={() => {navigate('/app/users')}}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                  onPress={isSubmitting = true}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
          backgroundColor: "red"
        }}
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={handleToClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleToClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};

export default UserFormChangePasswordComponent;
