import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Box, Button, Divider, Container, Card, CardContent, Grid, Typography } from "@material-ui/core"
import { ArrowBackIos } from "@material-ui/icons"
import { GET_INDIVIDUAL } from "src/utils/Constants"
import restApiService from "src/services/restService"
import { indexOf } from "lodash"


const ShowIndividual = () => {
    const { id } = useParams()
    const { callService } = restApiService()
    const navigate = useNavigate()

    const styles = {
        spaceBetween: {
            display: "flex",
            "justifyContent": "space-between",
            "width": "100%",
            "alignItems": "center"
        },
        black: {
            fontWeight: 600
        },
        alignRight: {
            "float": "right"
        }
    }

    const [individual, setIndividual] = useState({
        id: undefined,
        latitude: undefined,
        longitude: undefined,
        permissions: undefined,
        // General Information
        firstName: undefined,
        lastName: undefined,
        groupPractice: undefined,
        directPhoneNumber: undefined,
        mainPhone: undefined,
        emailAddress: undefined,
        officeAddress: undefined,
        country: undefined,
        state: undefined,
        zip: undefined,
        city: undefined,
        type: undefined,
        clientAgeRanges: undefined,
        website: undefined,
        psychologyTodayLink: undefined,
        briefSummary: undefined,
        // Cost
        networkWith: undefined,
        networkWithData: undefined,
        initialSessionFee: undefined,
        ongoingAppointmentFee: undefined,
        offersSuperBill: undefined, 
        // Access
        availableInPerson: undefined,
        offersTelehealth: undefined,
        statesWithLicensure: undefined,
        // Shared
        enabledShared: undefined,
        enabledSharedData: undefined,
        enabledSharedDataName: undefined
    })

    useEffect(() => {
        callService("GET", GET_INDIVIDUAL + id, {}, true)
            .then(response => {
                if (response.status === 200) {
                    setIndividual({ ...response.data }, response.data)
                }
            })
    }, [])

    function printMultipleValues(array) {
        let result = ""

        array.forEach((item) => {
            if (array.indexOf(item) === array.length - 1) {
                result += item.value
            } else {
                result += item.value + ", "
            }
        })

        return result
    }
    
    return (
        <>
            <Helmet>
                <title>Individual | Directory - O'Connor Professional Group</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Card
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%"
                        }}
                    >
                        <div style={{ color: "black" }} title="Individual details">
                            <div style={{
                                padding: "15px", display: "flex", justifyContent: "space-between", alignItems: "center", fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                fontWeight: 700, fontSize: '1.1rem', lineHeight: '1.5', letterSpacing: '0.00938em', textAlign: 'left', color: 'black'
                            }}>
                                <span>Individual Details</span>
                                <Button
                                    startIcon={<ArrowBackIos />}
                                    color="primary"
                                    onClick={() => navigate("/app/individuals")}
                                    variant="contained"
                                >
                                    Go back
                                </Button>
                            </div>
                        </div>
                        <Divider />
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    style={{ color:"black" }}
                                >   
                                    {/** HEADER */}
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>GENERAL INFORMATION</span></div>
                                    </Typography>

                                    <hr></hr>

                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>First Name: </span><span>{individual.firstName ? individual.firstName : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Last Name: </span><span>{individual.lastName ? individual.lastName : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Group Practice: </span><span>{individual.groupPractice ? individual.groupPractice : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Direct Phone Number: </span>{individual.directPhoneNumber ? individual.directPhoneNumber : "-"}</div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Main Office Number</span><span>{individual.mainPhone ? individual.mainPhone : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Email Address: </span><span>{individual.emailAddress ? individual.emailAddress : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Office Address: </span><span>{individual.officeAddress ? individual.officeAddress : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Type: </span><span>{individual.type ? individual.type.value : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Client Age Ranges: </span><span>{individual.clientAgeRanges ? printMultipleValues(individual.clientAgeRanges) : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Website: </span><span>{individual.website ? individual.website : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Psychology Today Link: </span><span>{individual.psychologyTodayLink ? individual.psychologyTodayLink : "-"}</span></div>
                                    </Typography>
                                    <Typography
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between" }}><span style={styles.black}>Brief Summary: </span><span style={{ width: "75%", textAlign: "justify" }}>{individual.briefSummary ? individual.briefSummary : "-"}</span></div>
                                    </Typography>
                                </Grid>

                                <Grid
                                item
                                md={6}
                                xs={6}
                                style={{ color:"black" }}
                                >   
                                    {/** HEADER */}
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>COST</span></div>
                                    </Typography>
                                    <hr></hr>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>In Network With: </span><span>{individual.networkWith ? printMultipleValues(individual.networkWith) : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>In Network With Data: </span><span>{individual.networkWithData ? individual.networkWithDataz : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Initial Session Fee: </span><span>{individual.initialSessionFee ? ("$" + individual.initialSessionFee) : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>On-Going Appointment Fee: </span><span>{individual.ongoingAppointmentFee ? ("$" + individual.ongoingAppointmentFee) : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Offers Super Bill for OoN Plans: </span><span>{individual.offersSuperBill ? individual.offersSuperBill.value : "-"}</span></div>
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={6}
                                    style={{ color: "black" }}
                                >
                                    {/** HEADER */}
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>ACCESS</span></div>
                                    </Typography>
                                    <hr></hr>

                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Available in Person: </span><span>{individual.availableInPerson ? individual.availableInPerson.value : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Offers Telehealth: </span><span>{individual.offersTelehealth ? individual.offersTelehealth.value : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>State(s) With Licensure: </span><span>{individual.statesWithLicensure ? printMultipleValues(individual.statesWithLicensure) : "-"}</span></div>
                                    </Typography>
                                </Grid>

                                <Grid item md={6} xs={6} style={{ color: "black" }}>
                                    {/** HEADER */}
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>SHARED</span></div>
                                    </Typography>
                                    <hr></hr>

                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Enabled Shared?</span><span>{individual.enabledShared ? individual.enabledShared.value : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Shared email: </span><span>{individual.enabledSharedData ? individual.enabledSharedData : "-"}</span></div>
                                    </Typography>
                                    <Typography 
                                        component={"span"}
                                        align="left"
                                        color="black"
                                        variant="body1"
                                    >
                                        <div style={styles.spaceBetween}><span style={styles.black}>Shared name: </span><span>{individual.enabledSharedDataName ? individual.enabledSharedDataName : "-"}</span></div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    )
}

export default ShowIndividual