import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import UserListToolbar from 'src/components/users/UserListToolbar';
import UserCard from 'src/components/users/UsersCard';
import products from 'src/__mocks__/products';
import restApiService from '../services/restService';
import { SEARCH_USERS } from '../utils/Constants';
import UserContext from '../components/users/UserContext';
import TablePaginator from '../components/shared/TablePaginator';

const UserList = () => {
  const { callService } = restApiService();
  const [usersRequest, setUsersRequest] = useState({
    pageIndex: 0,
    pageSize: 10,
    query: "",
    sorts: [{
      asc: true,
      orderField: 'firstName'
    }]
  }
  );
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState("")
  const [usersResponse, setUsersResponse] = useState({
    listOfElements: [],
    pageNumber: 0,
    totalOfElements: 0,
    totalPages: 0
  });

  useEffect(() => {
    setLoading(true)
    callService('POST', SEARCH_USERS, { data: usersRequest }, true)
      .then(response => {
        if (response.status == 200) {
          setUsersResponse(response.data)
          setLoading(false)
          if (response.data.listOfElements.length == 0)
            setMessage("There are no users that match your search.")
          else
            setMessage("")
        }
      });
  }, [usersRequest])

  const handleChangePage = (value) => {
    let r = { ...usersRequest }
    r.pageIndex = value.pageIndex
    r.pageSize = value.pageSize
    setUsersRequest(r)
  }

  const styles = {
    flexCenter: {
      display: "flex",
      'justifyContent': 'center',
      'alignContent': 'center',
      'width': '100%',
      'height': '100%'
    },
    grid: {
      display: "flex",
      "justifyContent": "center",
      padding: "50px",
    },
    textError: {

    }
  };
  return (
    <>
      <Helmet>
        <title> Users | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <UserContext.Provider value={{ usersRequest: usersRequest, setUsersRequest: setUsersRequest }}>
            <UserListToolbar />
          </UserContext.Provider>
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              {!loading && !message && usersResponse.listOfElements.map((u) => (
                <Grid
                  item
                  key={u.id}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <UserCard user={u} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {!loading && message &&
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <Typography
                  component={'span'}
                  align="left"
                  color="black"
                  variant="body1"
                >
                  <span>{message}</span>
                </Typography>
              </div>
            </div>
          }
          {loading &&
            <div style={styles.grid}>
              <div style={styles.flexCenter}>
                <CircularProgress />
              </div>
            </div>
          }
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <TablePaginator totalOfElements={usersResponse.totalOfElements} onPageChange={handleChangePage}></TablePaginator>
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default UserList;
