import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';
import { useState } from 'react';

const NavItem = ({
  href,
  icon: Icon,
  title,
  children,
  ...rest
}) => {
  const location = useLocation();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click)
  const styles = {
    spaceBetween: {
      display: "flex",
      'justifyContent': 'space-between',
      'width': '100%',
      'alignItems': 'center'
    },
    dropDown: {
      display: "flex",
      width: "100%",
      "flexDirection": 'column',
    },
    center: {
      display: "flex",
      'alignItems': 'center'
    },
    listIn: {
      "listStyleType": "none"
    },
    listOut: {
      "listStyleType": "none",
      "height": "0",
      "display": "none",
      "transition": "all 0.5s ease"
    },
    list: {
      display:"flex",
      "paddingLeft": "25px"
    },
    flex:{
      display:"flex"
    }
  };

  const active = (href) => {
    return href ? !!matchPath({ path: href, end: false }, location.pathname) : false
  };

  const activeInactiveButton = () => {
    return active(href) ? {color:'primary.main'} : {color:'black'}
  }

  const renderButton = (href, title, Icon, padding) => {
    return <RouterLink style={{width:'100%'}} to={href}>
      <Button
        sx={{
          color: 'black',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active(href) && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          },
          '& a': {
            ...(activeInactiveButton())
          }
        }}>
        <div style={padding ? styles.list :  styles.flex }>
            {Icon && (
              <Icon size="20" />
            )}
            <span>
              {title}
            </span>
        </div>
      </Button>
    </RouterLink>
  }

  const renderDropDown = (title, Icon, children) => {
    return <div style={styles.dropDown}>
      <Button
        onClick={handleClick}
        sx={{
          color: 'black',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active(href) && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}>
        <div style={styles.spaceBetween}>
          <div style={styles.center}>
            {Icon && (<Icon size="20" />)}
            <span> {title}</span>
          </div>
          {click && (
            <FaCaretUp size="10" />
          )}
          {!click && (
            <FaCaretDown size="10" />
          )}
        </div>
      </Button>
      <ul style={click ? styles.listIn : styles.listOut}>
        {children.map((navItem, index) => {
          return (
            <li key={index} >
              {renderButton(navItem.href, navItem.title, navItem.icon, true)}
            </li>
          )
        })}
      </ul>
    </div>
  }

  const buttonSelector = () => {
    if (!children) {
      return renderButton(href, title, Icon, false)
    }
    else {
      return renderDropDown(title, Icon, children)
    }
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      {buttonSelector()}
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
