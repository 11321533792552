//  COOKIES PARAMS
export const BEARER = 'Bearer'
export const GOOGLE_API_KEY = 'AIzaSyAiwZ-9yWrbPoohKHuHS48LLL3vFlKLAWk'
//  PATHS
export const INDEX = '/';
// API URLS
export const LOGIN_URL = '/login'
export const PUBLIC_PUT_PROVIDER = "/api/public-providers?token="
export const PUBLIC_PROVIDERS_DIRETORY_TOKEN = '/api/public-providers?token='
export const PUBLIC_INDIVIDUALS_DIRETORY_TOKEN = '/api/public-individuals?token='
export const PROVIDERS_URL = '/api/providers-search'
export const INDIVIDUALS_URL = "/api/individuals/search"
export const POST_PROVIDER = "/api/providers-create"
export const POST_INDIVIDUAL = "/api/individuals/"
export const PUT_PROVIDER = "/api/providers-update/"
export const PUT_INDIVIDUAL = "/api/individuals/"
export const PUT_PUBLIC_INDIVIDUAL = "/api/public-individuals?token="
export const DELETE_PROVIDER = "/api/providers-delete/"
export const DELETE_INDIVIDUAL = "/api/individuals/"
export const PROVIDER_EXPORT_CSV = "/api/providers-search/export/csv"
export const PROVIDER_EXPORT_PDF = "/api/providers-search/export/pdf"
export const INDIVIDUALS_EXPORT_CSV = "/api/individuals/export/csv"

export const PROVIDER_EXPORT_PLACEMENT = (id) => "/api/providers-get/" + id + '/export/treatment';
export const EXPORT_CONSULTAN = (id) => "/api/providers-get/" + id + '/export/consultant';

export const GET_PROVIDER = "/api/providers-get/"
export const GET_INDIVIDUAL = "/api/individuals/";
export const GET_ROLE = "/api/role-get"
//USER
export const POST_USER = "/api/user-create"
export const SEARCH_USERS = "/api/user-search"
export const DELETE_USER = "/api/user-delete/"
export const PUT_USER = "/api/user-search/"
export const GET_USER = "/api/user-search/"
export const PATCH_USER = "/api/user-reset-password/"
export const PATCH_USER_WITHOUTH_ID = "/api/user-reset-password"
//FILTER VALUES
export const FILTER_VALUES = "/api/filter-values?type="
export const FILTER_VALUES_DATA = "/api/filter-values-data?type="
export const PUBLIC_FILTER_VALUES = (token, type) => "/api/public-providers/filter-values?token=" + token + "&type=" + type
export const PUBLIC_FILTER_VALUES_DATA = (token, type) => "/api/public-providers/filter-values-data?token=" + token + "&type=" + type
export const PUBLIC_INDIVIDUALS_FILTER_VALUES = (token, type) => "/api/public-individuals/filter-values?token=" + token + "&type=" + type
export const PUBLIC_INDIVIDUALS_FILTER_VALUES_DATA = (token, type) => "/api/public-individuals/filter-values-data?token=" + token + "&type=" + type
export const FILTER_VALUES_RANGED = "/api/ranges?type="
export const FILTER_STATE = "state"
export const FILTER_GENDER = "gender"
export const FILTER_AGE_RANGE = "age"
export const FILTER_BOOL = "bool"
export const FILTER_AVERAGE = "average"
export const FILTER_COUNTRY = "country"
export const FILTER_CITY = "city"
export const FILTER_ZIP = "zip"
export const FILTER_BEDS = "numberBeds"

export const ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR"
export const ROLE_STANDARD = "ROLE_STANDARD"

export const LGBTQ_SERVICE = "lgbtqServiceType"
export const SINGLE_MAYBE = "singleMaybe"
export const SINGLE_OTHER = "singleOther"
export const PROGRAM_TYPE = "programType"
export const BED_ASSINGMENT = "bedAssigmentType"
export const OPPORTUNITY_TYPE = "opportunityType"
export const THERAPY_TYPE = "therapyHoursType"
export const MANAGEMENT_TYPE = "managementType"
export const SHARED = "shared"

export const TYPE = "type"
export const AGE_RANGE = "clientRanges"