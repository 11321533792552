import React, { useState, useEffect } from "react"
import IndividualsContext from "./IndividualsContext"
import { Card, CardHeader, CardContent, Divider, Grid, MenuItem, TextField } from "@material-ui/core"
import { Formik } from "formik"
import CustomMultiSelectAutocomplete from "../shared/CustomMultiSelectAutocomplete"

const Cost = ({ networkWith, offersSuperBill }) => {
    return(
        <IndividualsContext.Consumer>
            {
                (value) => (
                    <Formik
                        enableReinitialize
                        initialValues={value.individual}
                    >
                        {
                            ({ errors, handleBlur, handleChange, touched, values }) => (
                                <form autoComplete="off" noValidate>
                                    <Card>
                                        <CardHeader title="Cost"/>
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item md={6} xs={12}>
                                                    <CustomMultiSelectAutocomplete
                                                        label='In-Network With'
                                                        name="networkWith"
                                                        inputName="networkWithData"
                                                        inputLabel="Other - In-Network With"
                                                        inputValue={values.networkWithData}
                                                        onChangeAutoComplete={value.updateIndividualWithoutEvent}
                                                        onChangeInput={value.updateIndividualBulk}                        
                                                        defaultValue={values.networkWith ? values.networkWith : []}
                                                        listElements={networkWith ? networkWith : []} 
                                                        otherIndex={9}
                                                        isPublic={true}
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Initial Session Fee"
                                                        name="initialSessionFee"
                                                        onChange={async (e) => {
                                                            handleChange(e)
                                                            await value.updateIndividual(e, "initialSessionFee", "value")
                                                        }}
                                                        value={values.initialSessionFee || ""}
                                                        variant="outlined"
                                                        type="number"
                                                    />
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="On-Going Appointment Fee"
                                                        name="onGoingAppointmentFee"
                                                        onChange={async (e) => {
                                                            handleChange(e)
                                                            await value.updateIndividual(e, "ongoingAppointmentFee", "value")
                                                        }}
                                                        value={values.ongoingAppointmentFee || ""}
                                                        variant="outlined"
                                                        type="number"
                                                    />
                                                </Grid>
                                                
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Offers Super Bill for OoN Plans"
                                                        name="offersSuperBillForOoNPlans"
                                                        onChange={async (e) => {
                                                            handleChange(e)
                                                            value.updateIndividual(e, "offersSuperBill", "object")
                                                        }}
                                                        value={values.offersSuperBill?.key || values.offersSuperBill || ""}
                                                        variant="outlined"
                                                        select
                                                    >
                                                        {offersSuperBill.map((option) => (
                                                            <MenuItem
                                                                key={option.key}
                                                                value={option.key}
                                                            >
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </form>
                            )
                        }
                    </Formik>
                )
            }
        </IndividualsContext.Consumer>
    );
}

export default Cost;
