import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  TextField
} from '@material-ui/core';
import Logo from '../components/Logo';
import restApiService from '../services/restService';
import Snackbar from "@material-ui/core/Snackbar";
import { PUBLIC_PROVIDERS_DIRETORY_TOKEN, PUBLIC_INDIVIDUALS_DIRETORY_TOKEN } from '../utils/Constants'

const Directory = () => {
  const {type} = useParams();
  const navigate = useNavigate();
  const { callService } = restApiService();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Login | Directory - O'Connor Professional Group</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              token: ''
            }}
            validationSchema={Yup.object().shape({
              token: Yup.string().max(255).required('Token is required')
            })}
            onSubmit={(values, actions) => {
              if (type === "individuals") {
                callService('GET', PUBLIC_INDIVIDUALS_DIRETORY_TOKEN + values.token, {}, false, true)
                .then(response => {
                  if (response.status === 200) {
                    navigate('/edit-public-individual', { state: JSON.stringify({ individual: response.data, token: values.token }) });
                    actions.setSubmitting(false);
                  } else {
                    setMessage(response.data.message);
                    handleToOpen();
                    actions.setSubmitting(false);
                  }
                })
              } else {
                callService('GET', PUBLIC_PROVIDERS_DIRETORY_TOKEN + values.token, {}, false, true)
                .then(response => {
                  if (response.status === 200) {
                    navigate('/edit-public-provider', { state: JSON.stringify({ provider: response.data, token: values.token }) });
                    actions.setSubmitting(false);
                  } else {
                    setMessage(response.data.message);
                    handleToOpen();
                    actions.setSubmitting(false);
                  }
                })
              }    
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Logo />
                <TextField
                  error={Boolean(touched.token && errors.token)}
                  fullWidth
                  helperText={touched.token && errors.token}
                  label="Token"
                  margin="normal"
                  name="token"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.token}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onPress={isSubmitting = true}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    View directory
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom"
            }} open={open} autoHideDuration={3000} onClose={handleToClose} >
            <Alert variant="filled" onClose={handleToClose} severity="error">
              {message}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </>
  );
};

export default Directory;
