import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import {
  Box,
  Container
} from '@material-ui/core';
import IndividualForm from 'src/components/individuals/IndividualForm';
import {  useParams } from 'react-router-dom';

const IndividualFormSection = () => {
  const {id} = useParams();
  
  return (
    <>
      <Helmet>
        <title>Individuals</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <IndividualForm id={id}/>
        </Container>
      </Box>
    </>
  )
};

export default IndividualFormSection;