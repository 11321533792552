import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Card,
  CardContent,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  Chip,
  Slider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import restApiService from "src/services/restService"
import IndividualsContext from "./IndividualsContext"
import {
  GOOGLE_API_KEY,
  FILTER_VALUES,
  FILTER_VALUES_DATA,
  FILTER_VALUES_RANGED
} from "src/utils/Constants"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import MenuItem from "@material-ui/core/MenuItem"
import CollapsableItem from "../shared/CollapsableItem"
import { isArray } from "lodash-es"

const useStyles = makeStyles(() => ({
  chip: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  chipSpace: {
    "& > *": {
      margin: "2px",
    },
  },
  chipTitle: {
    fontWeight: 500,
    color: "#333",
  },
  clear: {
    display: "inline-block",
    cursor: "pointer",
    margin: "0 !important",
    paddingTop: "15px",
  },
  margin: {
    paddingRight: 15,
    paddingLeft: 15,
  },
}))

const cleanFilter = {
  officeAddress: { value: "" },
  coordinates: { op: "<=>", value: [] },
  type: { op: "#", value: "" },
  clientRanges: { op: "#", value: "" },
  initialSessionFee: { op: "<=>", value: "" },
  ongoingAppointmentFee: { op: "<=>", value: "" },
  availableInPerson: { op: "#", value: "" },
  offersSuperBill: { op: "#", value: "" },
  offersTelehealth: { op: "#", value: "" },
}

const validate = (filter) => {
  let keys = Object.keys(cleanFilter)
  let found = true
  keys.forEach((key) => {
    if (!filter[key]) found = false
  })
  return found
}

const FilterComponent = () => {
  const transformFilter = (state, fieldName) => {
    let query = {}

    if (state.value == "") {
      return query
    } else {
      query[fieldName] = {}
      let f = query[fieldName]
      f.operation = state.op
      
      if (fieldName === "initialSessionFee" || fieldName === "ongoingAppointmentFee") {
        f.value = (state.value[0] + state.value[1]) / 2
      } else {
        f.value = state.value
      }

      query[fieldName] = f

      return query
    }
  }

  const transformFilterArray = (state, fieldName) => {
    let query = {}

    if (
      state.value.length === 0 ||
      (state.isRange &&
        state.value[0] === state.initial[0] &&
        state.value[1] === state.initial[1])
    ) {
      return query
    } else {
      query[fieldName] = {}
      let f = query[fieldName]
      f.operation = state.op
      f.value = state.value.join(",")
      query[fieldName] = f

      return query
    }
  }

  const changeQuery = async (value) => {
    let query = {}

    Object.keys(formState).forEach((key) => {
      if (isArray(formState[key].value)) {
        query = Object.assign(query, transformFilterArray(formState[key], key))
      } else {
        let response = transformFilter(formState[key], key)
        query = Object.assign(query, response)
      }
    })

    value.handleFilter({ query })
  }

  const changeCoordinates = (e, lat, long) => {
    let newArr = [lat, long, distance]
    localStorage.setItem(
      "searchIndividuals",
      JSON.stringify({
        ...formState,
        coordinates: {
          op: formState["coordinates"].op,
          value: newArr,
        },
      })
    )
    setFormState((formState) => ({
      ...formState,
      coordinates: {
        op: formState["coordinates"].op,
        value: newArr,
      },
    }))
  }

  const changeDistance = (distance) => {
    let newArr = [
      formState["coordinates"].value[0],
      formState["coordinates"].value[1],
      distance,
    ]
    localStorage.setItem(
      "searchIndividuals",
      JSON.stringify({
        ...formState,
        coordinates: {
          op: formState["coordinates"].op,
          value: newArr,
        },
      })
    )
    setFormState((formState) => ({
      ...formState,
      coordinates: {
        op: formState["coordinates"].op,
        value: newArr,
      },
    }))
  }

  const initialState = () => {
    let searchIndividual = localStorage.getItem("searchIndividual")

    if (searchIndividual !== null) {
      return validate(JSON.parse(JSON.stringify(searchIndividual)))
        ? JSON.parse(searchIndividual)
        : cleanFilter
    } else {
      localStorage.setItem("searchIndividual", JSON.stringify(cleanFilter))
      return cleanFilter
    }
  }

  const { callService } = restApiService()
  const { placePredictions, placesService, getPlacePredictions } =
    usePlacesService({ apiKey: GOOGLE_API_KEY })
  const contextValue = useContext(IndividualsContext)

  // STYLING
  const classes = useStyles()

  // All these states will coming from the callService function
  let address = localStorage.getItem("officeAddressSelected")
  const [addressState, setAddress] = useState(address ? address : "")
  const [addressStateSelected, setAddressSelected] = useState(
    address ? address : ""
  )

  const [distance, setDistance] = useState(50)
  const [showDistance, setShowDistance] = useState(address ? true : false)
  const [type, setType] = useState([])
  const [clientRanges, setClientRanges] = useState([])
  const [loadRanges, setLoadRanges] = useState(false)
  const [initialSessionFeeValue, setInitialSessionFeeValue] = useState([])
  const [initialSessionFeeLimits, setInitialSessionFeeLimits] = useState([])
  const [ongoingAppointmentFeeValues, setOngoingAppointmentFeeValues] = useState([])
  const [ongoingAppointmentFeeLimits, setOngoingAppointmentFeeLimits] = useState([])
  const [availableInPerson, setAvailableInPerson] = useState([])
  const [offersSuperBill, setOffersSuperBill] = useState([])
  const [offersTelehealth, setOffersTelehealth] = useState([])

  const [stateClear, setStateClear] = useState(false)
  const [formState, setFormState] = useState(initialState())

  // COMMON TO ALL FIELDS
  useEffect(() => {
    let isMounted = true
    Promise.all([
      /** 0 */ callService("GET", FILTER_VALUES + "type", {}, true),
      /** 1 */ callService("GET", FILTER_VALUES + "clientRanges", {}, true),
      /** 2 */ callService("GET", FILTER_VALUES_DATA + "shared", {}, true),
      /** 3 */ callService("GET", FILTER_VALUES_RANGED + "initialSessionFee", {}, true),
      /** 4 */ callService("GET", FILTER_VALUES_RANGED + "ongoingAppointmentFee", {}, true),
    ]).then((response) => {
      if (isMounted) {
        if (response && response[0].status === 200) {
          setType([...response[0].data])
          setClientRanges([...response[1].data])
          setAvailableInPerson([...response[2].data])
          setOffersSuperBill([...response[2].data])
          setOffersTelehealth([...response[2].data])
          debugger
          let valuesRangesSessionFee = {...response[3].data}
          setInitialSessionFeeValue([parseInt(valuesRangesSessionFee.min), parseInt(valuesRangesSessionFee.max)])
          setInitialSessionFeeLimits([parseInt(valuesRangesSessionFee.min), parseInt(valuesRangesSessionFee.max)])
          let valuesOngoin = {...response[4].data}
          setOngoingAppointmentFeeValues([parseInt(valuesOngoin.min), parseInt(valuesOngoin.max)])
          setOngoingAppointmentFeeLimits([parseInt(valuesOngoin.min), parseInt(valuesOngoin.max)])
          setLoadRanges(true)
        }
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (contextValue) {
      changeQuery(contextValue)
      let found = 0
      for (const key of Object.keys(formState)) {
        if (isArray(formState[key].value)) {
          if (formState[key].isRange) {
            if (
              formState[key].value[0] !== formState[key].initial[0] ||
              formState[key].value[1] !== formState[key].initial[1]
            ) {
              found = found + 1
            }
          } else {
            found = found + formState[key].value.length
          }
        } else {
          if (formState[key].value != "" && formState[key].value != 0) {
            found = found + 1
          }
        }
      }

      if (found > 0) {
        setStateClear(true)
      } else {
        setStateClear(false)
      }
    }
  }, [formState])

  const handleChange = (event, fieldName) => {
    let r = {
      ...formState,
      [fieldName]: {
        op: formState[fieldName].op,
        initial: formState[fieldName].initial,
        isRange: formState[fieldName].isRange,
        isArray: formState[fieldName].isArray,
        value: !formState[fieldName].isArray
          ? event.target.value
          : event.target.value
          ? JSON.parse("[" + event.target.value + "]")
          : undefined,
      },
    }

    localStorage.setItem("searchIndividual", JSON.stringify(r))
    setFormState(r)
  }

  const handleDelete = (fieldName) => {
    localStorage.setItem(
      "searchIndividual",
      JSON.stringify({
        ...formState,
        [fieldName]: {
          op: formState[fieldName].op,
          value: "",
        },
      })
    )

    setFormState((formState) => ({
      ...formState,
      [fieldName]: {
        op: formState[fieldName].op,
        value: "",
      },
    }))
  }

  const handleClear = () => {
    localStorage.setItem("searchIndividual", JSON.stringify(cleanFilter))
    setFormState(cleanFilter)
    setStateClear(false)
    setAddress("")
    setDistance(50)
    setShowDistance(false)
  }

  // CHIPS
  const addressChip = () => {
    if (addressStateSelected) {
      localStorage.setItem("officeAddressSelected", addressStateSelected)
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>Address:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              key={addressState}
              label={addressStateSelected}
              variant="outlined"
              size="small"
              onDelete={(e) => {
                setShowDistance(false)
                setAddress("")
                localStorage.setItem("officeAddressSelected", "")
                localStorage.setItem(
                  "searchProvider",
                  JSON.stringify({
                    ...formState,
                    coordinates: {
                      op: formState["coordinates"].op,
                      value: [],
                    },
                  })
                )
                setFormState((formState) => ({
                  ...formState,
                  coordinates: {
                    op: formState["coordinates"].op,
                    value: [],
                  },
                }))
                setDistance(50)
              }}
            />
          </div>
        </Typography>
      )
    }
  }

  const distanceChip = () => {
    if (distance) {
      return (
        <Typography componente={"span"} gutterBottom>
          <div className={classes.chipTitle}>Distance:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              key={distance}
              variant="outlined"
              size="small"
              onDelete={() => {
                localStorage.setItem(
                  "searchIndividual",
                  JSON.stringify({
                    ...formState,
                    coordinates: {
                      op: formState["coordinates"].op,
                      value: [],
                    },
                  })
                )
                localStorage.setItem("officeAddressSelected", "")
                setFormState((formState) => ({
                  ...formState,
                  coordinates: {
                    op: formState["coordinates"].op,
                    value: [],
                  },
                }))
                setAddress("")
                setShowDistance(false)
                setDistance(50)
              }}
              label={distance + " miles"}
            />
          </div>
        </Typography>
      )
    }
  }

  const typeChip = () => {
    if (formState?.type.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>Type:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              key={formState.type.value}
              onDelete={(e) => handleDelete("type")}
              label={((data) => {
                let index = type.findIndex((s) => s.key === data)
                if (index > -1) {
                  let arr = type[index].value.split(" ")
                  let result = arr[arr.length - 1]
                  result = result.replace("(", "").replace(")", "")
                  return result
                }
              })(formState.type.value)}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const clientAgeRangeChip = () => {
    if (formState?.clientRanges.value) {
      return (
        <Typography component={"span"} id="clientRanges" gutterBottom>
          <div className={classes.chipTitle}>Client Age Ranges:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={((data) => {
                let index = clientRanges.findIndex((s) => s.key === data)
                if (index > -1) {
                  return clientRanges[index].value
                }
              })(formState.clientRanges.value)}
              onDelete={(e) => handleDelete("clientRanges")}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const initialSessionFeeChip = () => {
    if (formState?.initialSessionFee.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>Initial Session Fee:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={`$${formState.initialSessionFee.value[0]?.toFixed(2)} - $${formState.initialSessionFee.value[1]?.toFixed(2)}`}
              onDelete={(e) => handleDelete("initialSessionFee")}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const onGoingAppointmentFeeChip = () => {
    if (formState?.ongoingAppointmentFee.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>On-Going Appointment Fee:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={`$${formState.ongoingAppointmentFee.value[0]?.toFixed(2)} - $${formState.ongoingAppointmentFee.value[1]?.toFixed(2)}`}
              onDelete={(e) => handleDelete("ongoingAppointmentFee")}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const availableInPersonChip = () => {
    if (formState?.availableInPerson.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>Available in Person:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={((data) => {
                let index = availableInPerson.findIndex((s) => s.key === data)
                if (index > -1) {
                  return availableInPerson[index].value
                }
              })(formState.availableInPerson.value)}
              onDelete={(e) => handleDelete("availableInPerson")}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const offersSuperBillChip = () => {
    if (formState?.offersSuperBill.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>
            Offers Super Bill for OoN Plans:
          </div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={((data) => {
                let index = offersSuperBill.findIndex((s) => s.key === data)
                if (index > -1) {
                  return offersSuperBill[index].value
                }
              })(formState.offersSuperBill.value)}
              onDelete={(e) => handleDelete("offersSuperBill")}
              variant="outlined"
              size="small"
            />
          </div>
        </Typography>
      )
    }
  }

  const offersTelehealthChip = () => {
    if (formState?.offersTelehealth.value) {
      return (
        <Typography component={"span"} gutterBottom>
          <div className={classes.chipTitle}>Offers Telehealht:</div>
          <div>
            <Chip
              sx={{
                color: "#333",
                margin: "2px",
                height: "100%",
                padding: "2px 0",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(209, 121, 5, 0.66)",
                },
                "& .MuiChip-label ": {
                  whiteSpace: "normal",
                },
              }}
              label={((data) => {
                let index = offersTelehealth.findIndex((s) => s.key === data)
                if (index > -1) {
                  return offersTelehealth[index].value
                }
              })(formState.offersTelehealth.value)}
              variant="outlined"
              size="small"
              onDelete={(e) => handleDelete("offersTelehealth")}
            />
          </div>
        </Typography>
      )
    }
  }

  const clearChips = () => {
    if (stateClear) {
      return (
        <div className={classes.chip}>
          <Typography
            component={"span"}
            id="Clear"
            className={classes.clear}
            gutterBottom
            onClick={handleClear}
          >
            Clear all
          </Typography>
        </div>
      )
    }
  }

  return (
    <IndividualsContext.Consumer>
      {(value) => (
        <Box sx={{ mt: 3 }} style={{ maxWidth: "100%" }}>
          <Card
            sx={{
              borderBottom: "3px solid",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <CardContent>
              <div className={classes.chip}>
                {showDistance && addressChip()}
                {showDistance && distanceChip()}
                {typeChip()}
                {clientAgeRangeChip()}
                {initialSessionFeeChip()}
                {onGoingAppointmentFeeChip()}
                {availableInPersonChip()}
                {offersSuperBillChip()}
                {offersTelehealthChip()}
                {clearChips()}
              </div>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
            style={{ maxWidth: "100%" }}
          >
            <CardContent>
              <Grid container spacing={0}>
                <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                  <Autocomplete
                    disableClearable
                    fullWidth
                    required
                    value={addressState}
                    inputValue={addressState}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={placePredictions ? placePredictions : []}
                    getOptionLabel={(option) =>
                      option.description ? option.description : ""
                    }
                    onChange={(e, v) => {
                      if (v) {
                        placesService?.getDetails(
                          { placeId: v.place_id },
                          (place) => {
                            setAddress(v.description)
                            setAddressSelected(v.description)
                            setShowDistance(true)
                            changeCoordinates(
                              v.description,
                              place.geometry.location.lat(),
                              place.geometry.location.lng()
                            )
                          }
                        )
                      } else {
                        changeCoordinates("", 0, 0)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          getPlacePredictions({ input: e.target.value })
                          setAddress(e.target.value)
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                        fullWidth
                        label="Address"
                        name="officeAddress"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {showDistance && (
                  <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                    <Typography
                      component={"span"}
                      id="range-slider"
                      gutterBottom
                    >
                      Distance
                    </Typography>
                    <div className={classes.margin}>
                      <Slider
                        key={"slider-1"}
                        value={distance}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          changeDistance(e.target.value)
                          setDistance(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                )}

                <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                  <TextField
                    id="type-selection"
                    name="type"
                    label="Type"
                    fullWidth
                    select
                    value={formState.type.value}
                    defaultValue={formState.type.value}
                    onChange={(e) => handleChange(e, "type")}
                    variant="outlined"
                  >
                    {type.map((option) => {
                      return (
                        <MenuItem key={option.key} value={option.key}>
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>

                <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                  <TextField
                    id="age-ranges-selection"
                    name="clientRanges"
                    label="Client Age Ranges"
                    fullWidth
                    select
                    value={formState.clientRanges.value}
                    defaultValue={formState.clientRanges.value}
                    onChange={(e) => handleChange(e, "clientRanges")}
                    variant="outlined"
                  >
                    {clientRanges.map((option) => {
                      return (
                        <MenuItem key={option.key} value={option.key}>
                          {option.value}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>

                {/** FINANCIAL */}
                {initialSessionFeeValue && loadRanges && ongoingAppointmentFeeValues && (
                  <>
                    <Grid style={{ padding: "10px 5px" }} item md={12} xs={12}>
                      <Typography
                        component={"span"}
                        style={{ color: "black" }}
                        id="initialSessionFee"
                        gutterBottom
                      >
                        Initial Session Fee
                      </Typography>
                      <Slider
                        key={"slide-2"}
                        valueLabelDisplay="auto"
                        defaultValue={initialSessionFeeValue}
                        min={initialSessionFeeLimits[0]}
                        max={initialSessionFeeLimits[1]}
                        getAriaLabel={() => "Small"}
                        onChange={(e) => {
                          handleChange(e, "initialSessionFee")
                          setInitialSessionFeeValue(e.target.value)
                        }}
                      />
                    </Grid>

                    <Grid style={{ padding: "10px 5px" }} item md={12} xs={12}>
                      <Typography
                        component={"span"}
                        id="onGoingAppointmentFee"
                        style={{ color: "black" }}
                        gutterBottom
                      >
                        On-Going Appointment Fee
                      </Typography>
                      <Slider
                        key={"slider-3"}
                        value={ongoingAppointmentFeeValues || ""}
                        min={ongoingAppointmentFeeLimits[0]}
                        max={ongoingAppointmentFeeLimits[1]}
                        getAriaLabel={() => "Small"}
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          handleChange(e, "ongoingAppointmentFee")
                          setOngoingAppointmentFeeValues(e.target.value)
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid style={{ padding: "10px 0" }} item md={12} xs={12}>
                  <CollapsableItem
                    title="OTHER FILTERS"
                    inner={() => (
                      <div style={{ padding: "10px 5px" }}>
                        <Grid
                          style={{ padding: "10px 0" }}
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            key="available-in-person-selection"
                            fullWidth
                            label="Available In-Person"
                            name="availableInPerson"
                            select
                            variant="outlined"
                            onChange={(e) =>
                              handleChange(e, "availableInPerson")
                            }
                            value={formState.availableInPerson.value}
                          >
                            {availableInPerson.map((option) => {
                              return (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            id="offers-super-bill-for-OoN-plans-selection"
                            fullWidth
                            label="Offers Super Bill for OoN Plans"
                            name="offersSuperBill"
                            select
                            variant="outlined"
                            onChange={(e) => handleChange(e, "offersSuperBill")}
                            value={formState.offersSuperBill.value}
                          >
                            {offersSuperBill.map((option) => {
                              return (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Grid>
                        <Grid
                          style={{ padding: "10px 0" }}
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            id="offers-telehealth"
                            fullWidth
                            label="Offers Telehealth"
                            name="offersTelehealth"
                            select
                            variant="outlined"
                            onChange={(e) =>
                              handleChange(e, "offersTelehealth")
                            }
                            value={formState.offersTelehealth.value}
                          >
                            {offersTelehealth.map((option) => {
                              return (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Grid>
                      </div>
                    )}
                  ></CollapsableItem>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </IndividualsContext.Consumer>
  )
}

export default FilterComponent
