import React, { useEffect, useState } from "react";
import useDebounce from "../helpers/debounceTime";
const FastInput = (props) => {
    const Inner = props.children
        const [innerState, setInnerState] = useState({ target: { value: props.value } })
    const debouncedUpdate = useDebounce(innerState, 100);
    useEffect(() => {
        if (debouncedUpdate) {
            props.updateProvider(debouncedUpdate)
        }
    },
        [debouncedUpdate]
    );
    let innerProps = { value: innerState?.target?.value, handleChange: setInnerState }
    return (Inner(innerProps))
}

export default FastInput;
