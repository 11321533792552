import {
    Pagination,
    TablePagination,
    TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';

const TablePaginator = (props) => {
    const rows = [15, 30, 45, 60];
    const getValue = () => {
        let c = localStorage.getItem("step")
        if (c && c != "") {
            return (parseInt(c))
        } else {
            return rows[0]
        }

    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(getValue());

    useEffect(() => {
        props.onPageChange({ pageIndex: page, pageSize: rowsPerPage })
    }, [page, rowsPerPage])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem("step", event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <TablePagination
        showLastButton={true}
        showFirstButton={true}
        component="div"
        count={props.totalOfElements}
        page={page}
        rowsPerPageOptions={rows}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
}

export default TablePaginator;
