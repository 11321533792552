import axios from 'axios'
import { Navigate } from 'react-router';
// import cookie from 'js-cookie'
//import { useHistory } from 'react-router-dom'
import { BEARER, LOGIN_URL } from '../utils/Constants'

const restApiService = () => {
  //const history = useHistory()
  const callService = async (method, url, options = {}, withCredentials = true, publicMethod = false) => {
    try {
      const token = localStorage.getItem('token');
      let apiUrl = process.env.REACT_APP_API_HOST
      if (!withCredentials && !publicMethod)
        apiUrl = process.env.REACT_APP_API_HOST_LOGIN
      // const language = cookie.get(LANGUAGE) ? cookie.get(LANGUAGE) : ''
      return await axios({
        method,
        baseURL: options.baseUrl || apiUrl,
        url,
        data: options.data,
        headers: {
          'Content-Type': 'application/json',
          ...(withCredentials && !publicMethod && { Authorization: `${BEARER} ${token}` }),
        }
        /* headers: {
          Language: language,
          ...(withCredentials && { Authorization: `${BEARER} ${token}` }),
          ...options.headers
        } */
      })
    } catch (error) {
      // The user is not authenticated or has invalid credentials.
      // Redirect him to the login page
      /* if (error.response && error.response.status === 401) {
        cookie.remove(TOKEN)
        history.push(INDEX)
      } */
      if (error.response && error.response.status == 403 || error.response && error.response.status == 401) {
        localStorage.clear();

      }

      return error.response;
    }
  }

  const callServiceDownload = async (method, url, options = {}, withCredentials = true, fileType, fileName) => {
    try {
      const token = localStorage.getItem('token');
      let apiUrl = process.env.REACT_APP_API_HOST
      if (!withCredentials)
        apiUrl = process.env.REACT_APP_API_HOST_LOGIN
      return await axios({
        method,
        baseURL: options.baseUrl || apiUrl,
        url,
        data: options.data,
        responseType: "arraybuffer",
        responseEncoding: "binary",
        headers: {
          'Content-Type': 'application/json',
          ...(withCredentials && { Authorization: `${BEARER} ${token}` }),
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    } catch (error) {
      if (error.response && error.response.status == 403 || error.response && error.response.status == 401) {
        localStorage.clear();
      }
      return error.response;
    }
  }

  return { callService, callServiceDownload }
}

export default restApiService