import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Grid } from "@material-ui/core";
import FastInput from "./FastInput";
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const CustomSelect = (props) => {
    
    const enableInput = () => {
        if (props.list && props.list.length) {
            let index = props.list.findIndex(e => e.key == props.defaultValue.key)
            if (index > -1)
                if (props.list[index].canAddData) {
                    return true
                } else {
                    return false
                }
        }
    }
    const [canAddData, setCanAddData] = useState(enableInput());

    const handleChange = (event) => {
        if (props.list && props.list.length) {
            let index = props.list.findIndex(e => e.key == event.target.value)
            if (index > -1) {
                if (props.list[index].canAddData) {
                    setCanAddData(true)
                } else {
                    setCanAddData(false)
                }
                let result = [{ value: event.target.value, fieldName: props.name, keyOrValue: 'key' }]
                if (props.inputName) {
                    result.push({ value: '', fieldName: props.inputName, keyOrValue: 'value' })
                }
                props.onChange(result);
            } else {
                let result = [{ value: '', fieldName: props.name, keyOrValue: 'key' }]
                if (props.inputName) {
                    result.push({ value: props.defaultValueInput, fieldName: props.inputName, keyOrValue: 'value' })
                }
                props.onChange(result);
            }
        }
    };

    const handleInputChange = (e) => {
        let result = [{ value: props.defaultValue?.key, fieldName: props.name, keyOrValue: 'key' }]
        if (props.inputName) {
            result.push({ value: e.target.value, fieldName: props.inputName, keyOrValue: 'value' })
        }
        props.onChange(result);
    }

    const filter = (e) => {
        if (!canAddData) {
            e.splice(1, 1)
        }
        else
            e.splice(0, 1)
        return e
    }

    function validate(e) {
        if (!e.target.value) {
            let result = [{ value: '', fieldName: props.name, keyOrValue: 'key' }]
            if (props.inputName) {
                result.push({ value: '', fieldName: props.inputName, keyOrValue: 'value' })
            }
            props.onChange(result);
            setCanAddData(false)
        }
    }
    
    function getValue(option) {
        if (props.list && props.list.length) {
            let index = props.list.findIndex(e => e.key == option.key)
            if (index > -1)
                return props.list[index].value
        }
        return undefined
    }

    return (props.list && props.list.length && filter([
        <Grid item md={6} xs={12} key={1}>
            <Grid item md={12} xs={12}>
                <TextField
                    fullWidth
                    label={props.label}
                    name={props.name}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    select
                    value={props.defaultValue.key ? props.defaultValue : ''}
                    variant="outlined"
                    SelectProps={{
                        renderValue: option => getValue(option)
                    }}
                    inputProps={{
                        startAdornment: ( 
                            props.isPublic && <InputAdornment position="start">
                                <PublicIcon />
                            </InputAdornment>
                        ),
                    }}
                >
                    {props.list.map((option, index) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}>
                            {<ListItemText primary={option.value} />}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid >
        </Grid >,
        <Grid item md={6} xs={12} key={2}>
            <Grid item md={12} xs={12} >
                <FastInput updateProvider={(e) => { handleInputChange(e) }} value={props.defaultValueInput || ''}>
                    {(innerProps) => {
                        return <div style={{ 'display': 'flex' }}>
                            <TextField
                                fullWidth
                                autoFocus
                                onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                id="outlined-multiline-flexible"
                                label={props.label}
                                multiline
                                maxRows={4}
                                value={innerProps.value || ''}
                                onChange={(e) => {
                                    innerProps.handleChange(e)
                                }}
                                helperText={innerProps.value ? `Number of characters ${innerProps.value.length}/255` : ''}
                                inputProps={{
                                    maxLength: 255
                                  }}
                                onBlur={(e) => { validate(e) }}
                            />
                        </div>
                    }}
                </FastInput>
            </Grid >
        </Grid >]))
}

export default CustomSelect;
