import React, { useCallback, useEffect, useState } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography'
import FirstStep from './ContactDetail'
import SecondStep from './ProgramDetail'
import ThirdStep from './PatientPopulation'
import FourthStep from './FinancialDetails'
import FifthStep from './OtherDetails'
import SixthStep from './SharedForm'
import {
  Snackbar,
  IconButton,
  CircularProgress,
  Alert
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/styles';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import ProviderContext from './ProviderContext';
import { POST_PROVIDER, GET_PROVIDER, PUT_PROVIDER, FILTER_VALUES_DATA, FILTER_BOOL, FILTER_VALUES, FILTER_GENDER, FILTER_AGE_RANGE, FILTER_COUNTRY } from '../../utils/Constants';
import restApiService from '../../services/restService';
import { Prompt, useNavigate } from 'react-router-dom';

const ProviderForm = (props) => {
  const { callService } = restApiService();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [loaded, setLoaded] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [invalidForm, setInvalidForm] = useState(true);
  const [saving, setSaving] = useState(undefined);

  const [booleanValues, setBooleanValues] = useState([]);
  const [programTypes, setProgramtypes] = useState([]);
  const [lgbtqFriendly, setLgbtqFriendly] = useState([]);
  const [singleMaybe, setSingleMaybe] = useState([]);
  const [lgbtqServiceType, setLgbtqServiceType] = useState([]);
  const [separateByGenderList, setSeparateByGenderList] = useState([]);
  const [bedAssigmentTypeList, setBedAssigmentTypeList] = useState([]);
  const [opTypeList, setOpTypeList] = useState([]);
  const [managementTypeList, setManagementTypeList] = useState([]);
  const [therapyHoursTypeList, setTherapyHoursTypeList] = useState([]);
  const [singleType, setSingleType] = useState([]);
  const [gender, setGenders] = useState([]);
  const [ageRange, setAgeRange] = useState([]);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    let isMounted = true;
    Promise.all([
      /*0*/callService('GET', FILTER_VALUES_DATA + 'lgbtqServiceType', {}, true),
      /*1*/callService('GET', FILTER_VALUES_DATA + 'singleMaybe', {}, true),
      /*2*/callService('GET', FILTER_VALUES_DATA + 'singleOther', {}, true),
      /*3*/callService('GET', FILTER_VALUES_DATA + 'programType', {}, true),
      /*4*/callService('GET', FILTER_VALUES_DATA + 'bedAssigmentType', {}, true),
      /*5*/callService('GET', FILTER_VALUES + FILTER_BOOL, {}, true),
      /*6*/callService('GET', FILTER_VALUES + 'opportunityType', {}, true),
      /*7*/callService('GET', FILTER_VALUES + 'therapyHoursType', {}, true),
      /*8*/callService('GET', FILTER_VALUES + 'managementType', {}, true),
      /*9*/callService('GET', FILTER_VALUES_DATA + 'shared', {}, true),
      /*10*/callService('GET', FILTER_VALUES + FILTER_GENDER, {}, true),
      /*11*/callService('GET', FILTER_VALUES + FILTER_AGE_RANGE, {}, true),
      /*12*/callService('GET', FILTER_VALUES + FILTER_COUNTRY, {}, true)])
      .then(response => {
        if (isMounted)
          if (response && response[0].status == 200) {
            setLgbtqServiceType([...response[0].data])
            setLgbtqFriendly([...response[1].data])
            setSingleMaybe([...response[1].data])
            setSeparateByGenderList([...response[2].data])
            setProgramtypes([...response[3].data])
            setBedAssigmentTypeList([...response[4].data])
            setBooleanValues([...response[5].data])
            setOpTypeList([...response[6].data])
            setTherapyHoursTypeList([...response[7].data])
            setManagementTypeList([...response[8].data])
            setSingleType([...response[9].data])
            setGenders([...response[10].data])
            setAgeRange([...response[11].data])
            setCountry([...response[12].data])
          }
      });
    return () => { isMounted = false };
  }, [])

  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };

  const delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const saveProvider = () => {
    const request = {
      ////////////////////// CONTACT DETAILS
      programName: provider.programName,
      website: provider.website == "" ? null : provider.website,
      address: provider.address,
      address2: provider.address2,
      address3: provider.address3,
      latitude: provider.latitude,
      latitude2: provider.latitude2,
      latitude3: provider.latitude3,
      longitude: provider.longitude,
      longitude2: provider.longitude2,
      longitude3: provider.longitude3,
      city: provider.city,
      city2: provider.city2,
      city3: provider.city3,
      state: parseInt(provider.state?.key || undefined),
      state2: parseInt(provider.state2?.key || undefined),
      state3: parseInt(provider.state3?.key || undefined),
      zip: provider.zip,
      zip2: provider.zip2,
      zip3: provider.zip3,
      country: parseInt(provider.country?.key || undefined),
      contactName: provider.contactName,
      admissionPhone: provider.admissionPhone,
      admissionEmail: provider.admissionEmail == "" ? null : provider.admissionEmail,
      preferredContacts: provider.preferredContacts,
      primaryContactInfo: provider.primaryContactInfo,
      ////////////////////// CONTACT DETAILS

      ////////////////////// PROGRAM DETAILS
      //   - OPG Referred Before (keep as current)
      opgReferredBefore: parseInt(provider.opgReferredBefore?.key || undefined),
      opgReferredBeforeData: provider.opgReferredBeforeData,
      programType: provider.programType,
      programTypeData: provider.programTypeData,
      leisureOffered: provider.leisureOffered,
      admissionRequirements: provider.admissionRequirements,
      admissionRules: provider.admissionRules,
      diagnosesConditions: provider.diagnosesConditions,
      covidProtocols: provider.covidProtocols,
      lgbtqFriendly: parseInt(provider.lgbtqFriendly?.key || undefined),
      lgbtqFriendlyData: provider.lgbtqFriendlyData,
      lgbtqServiceType: parseInt(provider.lgbtqServiceType?.key || undefined),
      lgbtqServiceTypeData: provider.lgbtqServiceTypeData,
      numberBeds: provider.numberBeds,
      averageLengthStay: provider.averageLengthStay,
      minLengthStay: provider.minLengthStay,
      maxLengthStay: provider.maxLengthStay,
      extendStay: provider.extendStay,
      separateByGender: parseInt(provider.separateByGender?.key || undefined),
      separateByGenderData: provider.separateByGenderData,
      bedAssigmentType: provider.bedAssigmentType,
      bedAssigmentTypeData: provider.bedAssigmentTypeData,
      meals: provider.meals,
      opportunityType: provider.opportunityType,
      familyTherapyOrComponent: parseInt(provider.familyTherapyOrComponent?.key || undefined),
      frequencyMeetFamilyPsy: provider.frequencyMeetFamilyPsy,
      familyInfo: provider.familyInfo,
      treatmentModalities: provider.treatmentModalities,
      therapyHoursType: provider.therapyHoursType,
      therapyHours: provider.therapyHours,
      clinicalHighlights: provider.clinicalHighlights,
      specialtyOffered: provider.specialtyOffered,
      smokingCessationServices: parseInt(provider.smokingCessationServices?.key || undefined),
      addictiveMedAllowed: parseInt(provider.addictiveMedAllowed?.key || undefined),
      addictiveMedAllowedData: provider.addictiveMedAllowedData,
      offerMedAssistedTreatment: parseInt(provider.offerMedAssistedTreatment?.key || undefined),
      medicalClearance: parseInt(provider.medicalClearance?.key || undefined),
      managementType: provider.managementType,
      frequencyMeetPsy: provider.frequencyMeetPsy,
      shortTermAssessmentServices: provider.shortTermAssessmentServices,
      medicalNursingOversight: provider.medicalNursingOversight,
      summary: provider.summary,
      ////////////////////// PROGRAM DETAILS

      ////////////////////// PATIENT POPULATION
      gender: parseInt(provider.gender?.key || undefined),
      ageRange: parseInt(provider.ageRange?.key || undefined),
      averageMilieu: provider.averageMilieu,
      clientProfile: provider.clientProfile,
      ////////////////////// PATIENT POPULATION

      ////////////////////// FINANCIAL DETAILS
      // - Insurance Accepted? (keep as current)
      insuranceAccepted: parseInt(provider.insuranceAccepted?.key || undefined),
      // - Insurance Types Accepted (renamed from "Monthly $ with Insurance")
      monthlyFinancialCost: provider.monthlyFinancialCost,
      // - Cost, Self-Pay (renamed from "Monthly $ out of pocket")
      privatePayMonthlyRate: provider.privatePayMonthlyRate,
      // - Insurance Policies Accepted
      insurancePoliciesAccepted: provider.insurancePoliciesAccepted,
      ////////////////////// FINANCIAL DETAILS

      ////////////////////// OTHER DETAILS
      // - Electronics Permitted? (new field - dropdown: Yes, No, Maybe - if "Maybe", open a text input)
      elecPermitted: parseInt(provider.elecPermitted?.key || undefined),
      elecPermittedData: provider.elecPermittedData,
      // - License & Accreditation (new field - text)
      licenseAccreditation: provider.licenseAccreditation,
      // - Smoking Permitted (keep as current)
      smokingPermitted: parseInt(provider.smokingPermitted?.key || undefined),
      smokingPermittedData: provider.smokingPermittedData,
      // - Handicap Accessible (keep as current)
      handicapAccessible: parseInt(provider.handicapAccessible?.key || undefined),
      // - Medical Complexities Accepted (new field - dropdown: Yes, No, Maybe - if "Maybe", open a text input)
      medComplexityAccepted: parseInt(provider.medComplexityAccepted?.key || undefined),
      medComplexityAcceptedData: provider.medComplexityAcceptedData,
      // - Accepts Involuntary Commitments (keep as current)
      acceptCommitments: parseInt(provider.acceptCommitments?.key || undefined),
      // - Electronics Protocol
      electronicsProtocol: provider.electronicsProtocol,
      // - Vaping Permitted
      vapingPermitted: provider.vapingPermitted, 
      // - Extended Care / Step Down Options
      extendedCare: provider.extendedCare,
      ////////////////////// OTHER DETAILS

      ////////////////////// SHARE WITH PROVIDER
      // - Enable Share? (new field - radio button: Yes, No - default No)
      enabledShared: parseInt(provider.enabledShared?.key || undefined),
      enabledSharedData: provider.enabledSharedData,
      enabledSharedDataName: provider.enabledSharedDataName,
      // See additional use case for sharing enabled.
      // - If "Yes" is selected, a text input called "Email" will be displayed. where
      ////////////////////// SHARE WITH PROVIDER
    }

    if (props.id) {
      callService('PUT', PUT_PROVIDER + props.id, { data: request }, true)
        .then(response => {
          if (response.status == 200) {
            setShowPrompt(false)
            setMessage('The provider has been edit successfully')
            setSeverity('success')
            handleToOpen();
            delay(function () {
              navigate('/app/providers');
            }, 3000);
          } else {
            setSaving(undefined)
            var errors = 'ERRORS: ';
            if (response) {
              if (response.data && response.data.errors && response.data.errors.length)
                for (var e of response.data.errors) {
                  errors = errors + ' ' + e + '';
                }
              setMessage(errors)
              setSeverity('error')
              handleToOpen();
            }
          }
        });
    } else {
      callService('POST', POST_PROVIDER, { data: request }, true)
        .then(response => {
          if (response.status == 200) {
            setShowPrompt(false)
            setMessage('The provider has been created successfully')
            setSeverity('success')
            handleToOpen();
            delay(function () {
              navigate('/app/show-provider/' + response.data.id);
            }, 3000);
          } else {
            setSaving(undefined)
            var errors = 'ERRORS: ';
            if (response.data && response.data.errors && response.data.errors.length)
              for (var e of response.data.errors) {
                errors = errors + ' ' + e + '';
              }
            setMessage(errors)
            setSeverity('error')
            handleToOpen();
          }
        });
    }
  }

  /* CONTEXT INFORMATION */
  const [provider, setProvider] = useState({
    programName: "",
    website: "",
    address: "",
    address2: "",
    address3: "",
    latitude: 0,
    longitude: 0,
    city: "",
    city2: "",
    city3: "",
    state: '',
    zip: "",
    zip2: "",
    zip3: "",
    country: '',
    contactName: "",
    admissionPhone: "",
    admissionEmail: "",
    preferredContacts: "",
    primaryContactInfo: "",
    //second
    opgReferredBefore: { key: undefined },
    opgReferredBeforeData: "",
    programType: [],
    programTypeData: "",
    leisureOffered: "",
    admissionRequirements: "",
    admissionRules: "",
    diagnosesConditions: "",
    covidProtocols: "",
    lgbtqFriendly: undefined,
    lgbtqFriendlyData: "",
    lgbtqServiceType: undefined,
    lgbtqServiceTypeData: "",
    numberBeds: undefined,
    averageLengthStay: "",
    minLengthStay: "",
    maxLengthStay: "",
    separateByGender: undefined,
    separateByGenderData: "",
    bedAssigmentType: [],
    bedAssigmentTypeData: "",
    meals: "",
    opportunityType: [],
    familyTherapyOrComponent: { key: undefined },
    treatmentModalities: "",
    therapyHoursType: [],
    therapyHours: "",
    clinicalHighlights: "",
    specialtyOffered: "",
    smokingCessationServices: { key: undefined },
    addictiveMedAllowed: '',
    addictiveMedAllowedData: "",
    offerMedAssistedTreatment: { key: undefined },
    medicalClearance: { key: undefined },
    managementType: [],
    frequencyMeetPsy: "",
    shortTermAssessmentServices: "",
    summary: "",
    //second
    //PATIENT POPULATION
    gender: { key: "" },
    ageRange: { key: "" },
    averageMilieu: '',
    clientProfile: '',
    //PATIENT POPULATION
    //FINANCIAL DETAILS
    insuranceAccepted: { key: undefined },
    monthlyFinancialCost: undefined,
    privatePayMonthlyRate: undefined,
    //FINANCIAL DETAILS
    //OTHER DETAIL
    elecPermitted: undefined,
    elecPermittedData: '',
    licenseAccreditation: '',
    smokingPermitted: undefined,
    smokingPermittedData: '',
    handicapAccessible: { key: undefined },
    medComplexityAccepted: undefined,
    medComplexityAcceptedData: '',
    acceptCommitments: { key: undefined },
    //OTHER DETAIL
    enabledShared: { key: undefined },
    enabledSharedData: '',
    enabledSharedDataName: '',
  });

  useEffect(() => {
    if (props.id) {
      callService('GET', GET_PROVIDER + props.id, {}, true)
        .then(response => {
          if (response && response.status === 200) {
            let providerResponse = { ...response.data };
            providerResponse.programType = providerResponse.programType.map(x => {return x.key})
            providerResponse.bedAssigmentType = providerResponse.bedAssigmentType.map(x => {return x.key})
            providerResponse.therapyHoursType = providerResponse.therapyHoursType && providerResponse.therapyHoursType.length > 0 ? providerResponse.therapyHoursType.filter(e => e.key != 3).map(e => e.key) : []
            providerResponse.managementType = providerResponse.managementType && providerResponse.managementType.length > 0 ? providerResponse.managementType.filter(e => e.key != 3).map(e => e.key) : []
            providerResponse.opportunityType = providerResponse.opportunityType && providerResponse.opportunityType.length > 0 ? providerResponse.opportunityType.filter(e => e.key != 3).map(e => e.key) : []
            setProvider(providerResponse, provider)
            setLoaded(true)
          }
        });
    } else {
      setLoaded(true)
    }
  }, [])

  const updateProvider = useCallback((e, fieldName, keyOrValue) => {
    try {
      let providerCopy = { ...provider };
      if (keyOrValue === "value") {
        providerCopy[fieldName] = e.target.value;
      } else if (keyOrValue === "array") {
        fieldName.forEach(key => {
          providerCopy[key] = e.target.value[key];
        })
      } else {
        providerCopy[fieldName] = { key: e.target.value };
      }
      setProvider(providerCopy)
      setShowPrompt(true)
    } catch (error) {
    }
  }, [provider])

  const updateProviderBulk = useCallback((fields) => {
    let providerCopy = { ...provider };
    if (fields && fields.length)
      fields.forEach(fl => {
        if (fl.keyOrValue === "value") {
          providerCopy[fl.fieldName] = fl.value;
        } else {
          providerCopy[fl.fieldName] = { key: fl.value };
        }
        setProvider(providerCopy)
        setShowPrompt(true)
      })
  }, [provider])
  /* END CONTEXT */

  //PRO
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    button: {
      marginTop: 1,
      marginRight: 1,
    },
    actionsContainer: {
      marginBottom: 1,
    },
    resetContainer: {
      padding: 1,
    },
    buttonRigth: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    },
    centerSpinner: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignContent: "center"
    }
  }));

  function getSteps() {
    return ['Contact Information', 'Program Details', 'Patient Population', 'Financial Details', 'Other Details', 'Shared'];
  }

  function getStepContent(step) {
    if (loaded)
      switch (step) {
        case 0:
          return (
            <ProviderContext.Provider value={{
              provider: provider, updateProvider: updateProvider, updateProviderBulk: updateProviderBulk, updateValidator: (isInvalid) => {
                setInvalidForm(isInvalid)
              },
              saving: saving
            }}>
              <FirstStep
                country={country}
              ></FirstStep>
            </ProviderContext.Provider>
          )
        case 1:
          return (
            <ProviderContext.Provider value={{ provider: provider, updateProviderBulk: updateProviderBulk, updateProvider: updateProvider }}>
              <SecondStep
                booleanvalues={booleanValues}
                programtypes={programTypes}
                lgbtqfriendly={lgbtqFriendly}
                lgbtqservice={lgbtqServiceType}
                bygenderlist={separateByGenderList}
                bedassigmentlist={bedAssigmentTypeList}
                optypelist={opTypeList}
                managementlist={managementTypeList}
                therapylist={therapyHoursTypeList}
                singletype={singleType}
              ></SecondStep>
            </ProviderContext.Provider>
          )
        case 2:
          return (
            <ProviderContext.Provider value={{ provider: provider, updateProvider: updateProvider }}>
              <ThirdStep
                agerange={ageRange}
                gender={gender}
              ></ThirdStep>
            </ProviderContext.Provider>
          )
        case 3:
          return (
            <ProviderContext.Provider value={{ provider: provider, updateProvider: updateProvider }}>
              <FourthStep
                booleanvalues={booleanValues}
              ></FourthStep>
            </ProviderContext.Provider>
          )
        case 4:
          return (
            <ProviderContext.Provider value={{ provider: provider, updateProviderBulk: updateProviderBulk, updateProvider: updateProvider }}>
              <FifthStep
                singlemaybe={singleMaybe}
                singletype={singleType}
                booleanvalues={booleanValues}
              ></FifthStep>
            </ProviderContext.Provider>
          )
        case 5:
          return (
            <ProviderContext.Provider value={{ provider: provider, updateProvider: updateProvider }}>
              <SixthStep
                singlemaybe={singleMaybe}
                singletype={singleType}
              ></SixthStep>
            </ProviderContext.Provider>
          )
        default:
          return 'Unknown step';
      }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      saveProvider();
    }
  };

  const handleSave = () => {
    setShowPrompt(false)
    setSaving(true)
    saveProvider();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleCancel = () => {
    navigate('/app/providers');
  };

  const isvalid = () => {
    return invalidForm
  }
  const getStepper = () => {
    if (loaded) {
      return (<Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>
              {label}
            </StepButton>
            <StepContent>
              <Typography component={'span'} >{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0 || saving}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {(activeStep != steps.length - 1) && <Button
                    disabled={saving}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {'Next'}
                  </Button>}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>)
    } else {
      return (
        <div className={classes.centerSpinner}>
          <CircularProgress />
        </div>
      )
    }
  }
  return (
    <div className={classes.root}>
      <Prompt
        when={showPrompt}
        message="You are about to leave without saving changes."
      />
      <div className={classes.buttonRigth}>
        <Button
          disabled={saving}
          onClick={handleCancel}
          className={classes.button} > Back</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isvalid() || saving}
          onClick={handleSave}
          className={classes.button}>
          {'Finish'}
        </Button>
      </div>
      {getStepper()}
      <div className={classes.buttonRigth}>
        <Button
          disabled={saving}
          onClick={handleCancel}
          className={classes.button} > Back</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isvalid() || saving}
          onClick={handleSave}
          className={classes.button}>
          {'Finish'}
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }} open={open} autoHideDuration={3000} onClose={handleToClose} >
        <Alert variant="filled" onClose={handleToClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProviderForm;
