import React, { useContext } from 'react';
import {
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import ProviderContext from './ProviderContext';
import { Formik } from 'formik';
import CustomSelect from '../shared/CustomSelect';
import PublicIcon from '@material-ui/icons/Public';
import InputAdornment from '@material-ui/core/InputAdornment';

const ProviderForm = (props) => {
  const contextValue = useContext(ProviderContext);
  return (
    <ProviderContext.Consumer>
      {
        (value) => (
          <Formik enableReinitialize initialValues={value.provider} >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                noValidate
                {...props}
              >
                <Card>
                  <CardHeader
                    title="Other details"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <CustomSelect
                        label='Electronics Permitted?'
                        name="elecPermitted"
                        inputName="elecPermittedData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueInput={value.provider.elecPermittedData ? value.provider.elecPermittedData : ""}
                        defaultValue={value.provider.elecPermitted ? value.provider.elecPermitted : ""}
                        list={props.singlemaybe}
                        isPublic={true} />
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Electronics Protocol"
                          name="electronicsProtocol"
                          onChange={(e) => { value.updateProvider(e, "electronicsProtocol", "value") }}
                          value={values.electronicsProtocol || ''}
                          variant="outlined"
                          helperText={values.electronicsProtocol ? `Number of characters ${values.electronicsProtocol.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="License & Accreditation"
                          name="licenseAccreditation"
                          onChange={(e) => { value.updateProvider(e, "licenseAccreditation", "value") }}
                          value={values.licenseAccreditation || ''}
                          variant="outlined"
                          helperText={values.licenseAccreditation ? `Number of characters ${values.licenseAccreditation.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <CustomSelect
                        label='Smoking Permitted'
                        name="smokingPermitted"
                        nameData="smokingPermittedData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueData={(() => {
                          return value.provider.smokingPermittedData ? value.provider.smokingPermittedData : ""
                        })()}
                        defaultValue={(() => {
                          return value.provider.smokingPermitted ? value.provider.smokingPermitted : ""
                        })()}
                        list={props.singlemaybe} 
                        isPublic={true}/>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Vaping Permitted"
                          name="vapingPermitted"
                          onChange={(e) => { value.updateProvider(e, "vapingPermitted", "value") }}
                          value={values.vapingPermitted || ''}
                          variant="outlined"
                          helperText={values.vapingPermitted ? `Number of characters ${values.vapingPermitted.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <CustomSelect
                        label='Handicap Accessible'
                        name="handicapAccessible"
                        onChange={contextValue.updateProviderBulk}
                        defaultValue={(() => {
                          return value.provider.handicapAccessible ? value.provider.handicapAccessible : ""
                        })()}
                        list={props.singlemaybe} 
                        isPublic={true}/>

                      <CustomSelect
                        label='Medical Complexities Accepted'
                        name="medComplexityAccepted"
                        nameData="medComplexityAcceptedData"
                        onChange={contextValue.updateProviderBulk}
                        defaultValueData={(() => {
                          return value.provider.medComplexityAcceptedData ? value.provider.medComplexityAcceptedData : ""
                        })()}
                        defaultValue={(() => {
                          return value.provider.medComplexityAccepted ? value.provider.medComplexityAccepted : ""
                        })()}
                        list={props.singlemaybe} 
                        isPublic={true}/>

                      <CustomSelect
                        label='Accepts Involuntary Commitments'
                        name="acceptCommitments"
                        onChange={contextValue.updateProviderBulk}
                        defaultValue={(() => {
                          return value.provider.acceptCommitments ? value.provider.acceptCommitments : ""
                        })()}
                        list={props.booleanvalues} 
                        isPublic={true}/>
                      
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Extended Care / Step Down Options "
                          name="extendedCare"
                          onChange={(e) => { value.updateProvider(e, "extendedCare", "value") }}
                          value={values.extendedCare || ''}
                          variant="outlined"
                          helperText={values.extendedCare ? `Number of characters ${values.extendedCare.length}/255` : ''}
                          inputProps={{
                            maxLength: 255,
                            startAdornment: ( 
                                <InputAdornment position="start">
                                    <PublicIcon />
                                </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        )
      }
    </ProviderContext.Consumer>
  )
};

export default ProviderForm;