import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DELETE_USER } from '../../utils/Constants';
import restApiService from '../../services/restService';
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    display: "flex",
    'justifyContent': 'space-between',
    'width': '100%',
  },
  bolder: {
    fontWeight: 800,
    color: "#333",
    fontSize: '0.9em'
  },
  alingRight: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#4c4c4c",
    fontSize: '0.9em'
  },
  space: {
    paddingTop: "5px"
  },
  spaceText: {
    padding: "2px 0",
  },
  spaceText2: {
    padding: "15px 0",
  },
  grid: {
    display: 'grid',
    gridTempplateColumns: '1fr 1fr'
  },
  flex: {
    display: 'flex',
    'justifyContent': 'space-between',
  }, padding: {
    padding: "15px 0"
  }
}));

const UserCard = ({ user, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { callService } = restApiService();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const deleteUser = () => {
    callService('DELETE', DELETE_USER + user.id, {}, true)
      .then(response => {
        if (response.status == 200) {
          window.location.reload();
        } else {
          setMessage(response.data.message);
          handleToOpen();
        }
      });
  }

  const handleToClose = () => {
    setOpen(false);
  };

  const handleToOpen = () => {
    setOpen(true);
  };

  const styles = {
    flexRight: {
      display: "flex",
      'justifyContent': 'center',
      'width': '100%',
    }
  };

  const renderActionButtonsDelete = () => {
    if (user.permissions.includes('DELETE')) {
      return (
        <IconButton color="inherit" onClick={() => { setConfirmOpen(true) }}>
          <DeleteIcon color="action" />
        </IconButton>
      );
    }
  }

  const renderActionButtonsEdit = () => {
    if (user.permissions.includes('WRITE')) {
      return (
        <IconButton color="inherit" onClick={() => navigate('/app/change-password/' + user.id)}>
          <LockOpenIcon color="action" />
        </IconButton>
      );
    }
  }

  const renderActionButtonsChangePassword = () => {
    if (user.permissions.includes('WRITE')) {
      return (
        <IconButton color="inherit" onClick={() => navigate('/app/edit-user/' + user.id)}>
          <EditIcon color="action" />
        </IconButton>
      );
    }
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      {...rest}
    >
      <CardContent>
        <Typography
          component={'span'}
          align='center'
          color="textPrimary"
          gutterBottom
          variant="h4" >
          {user.firstName + " " + user.lastName}
        </Typography>
        <div className={classes.padding}>
          <Typography
            component={'span'}
            align="left"
            color="black"
            variant="body1"
          >
            <div className={classes.formControl}><span className={classes.bolder}>Email: </span><span className={classes.alingRight}>{user.email}</span></div>
          </Typography>
          <Typography
            component={'span'}
            align="left"
            color="black"
            variant="body1"
          >
            <div className={classes.formControl}><span className={classes.bolder}>Role: </span><span className={classes.alingRight}>{user.roles[0].description || "N/A"}</span></div>
          </Typography></div>
        <div style={styles.flexRight}>
          {renderActionButtonsDelete()}
          {renderActionButtonsEdit()}
          {renderActionButtonsChangePassword()}
          <ConfirmDialog
            title="Delete suser"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deleteUser}
          >
            Are you sure you want to delete {user.firstName + " " + user.lastName}  ?
          </ConfirmDialog>
        </div>
      </CardContent>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
          backgroundColor: "red"
        }}
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={handleToClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleToClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Card>
  )
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserCard;
