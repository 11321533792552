import React, { useContext } from "react";
import IndividualsContext from "./IndividualsContext";
import { Formik } from "formik";
import { Card, CardContent, CardHeader, Divider, Grid, TextField, MenuItem, FormControl, InputLabel, Autocomplete } from "@material-ui/core";
import * as Yup from "yup";

const Access = ({ availableInPerson, offersTelehealth, statesWithLicensure }) => {
    return(
        <IndividualsContext.Consumer>
            {
                (value) => (
                    <Formik 
                        enableReinitialize
                        initialValues={value.individual}
                        validationSchema={Yup.object().shape({
                            stateWithLicensure: Yup.string().required(
                              "State with licensure is required"
                            ),
                          })}
                    >
                        {
                            ({ errors, handleBlur, handleChange, touched, values }) => (
                                <form autoComplete="off" noValidate>
                                    <Card>
                                        <CardHeader title="Access"/>
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item md={6} xs={12}>
                                                    <TextField 
                                                        fullWidth
                                                        label="Available In-Person"
                                                        name="availableInPerson"
                                                        onChange={async (e) => {
                                                            handleChange(e)
                                                            await value.updateIndividual(e, "availableInPerson", "object")
                                                        }}
                                                        value={values.availableInPerson?.key || values.availableInPerson || ""}
                                                        select
                                                        variant="outlined"
                                                    >
                                                        {availableInPerson.map((option) => (
                                                            <MenuItem
                                                                key={option.key}
                                                                value={option.key}
                                                            >
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <TextField 
                                                        fullWidth
                                                        label="Offers Telehealth"
                                                        name="offersTelehealth"
                                                        variant="outlined"
                                                        onChange={async (e) => {
                                                            handleChange(e)
                                                            await value.updateIndividual(e, "offersTelehealth", "object")
                                                        }}
                                                        value={values.offersTelehealth?.key || values.offersTelehealth || ""}
                                                        select
                                                    >
                                                        {offersTelehealth.map((option) => (
                                                            <MenuItem
                                                                key={option.key}
                                                                value={option.key}
                                                            >
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            name="stateWithLicensure"
                                                            multiple
                                                            id="state-with-licensure"
                                                            options={statesWithLicensure.map((option) => option.value)}
                                                            defaultValue={
                                                                () => {
                                                                    let selectedValues = []
                                                                    values.statesWithLicensure.forEach((elementSelected) => {
                                                                        statesWithLicensure.forEach(element => {
                                                                            if (elementSelected === element.key) {
                                                                                selectedValues.push(element.value)
                                                                            }
                                                                        })
                                                                    })
                                                                    return selectedValues
                                                                }
                                                            }
                                                            filterSelectedOptions
                                                            onChange={async (e, selectedValues) => {
                                                                let newValues = []
                                                                statesWithLicensure.forEach(element => {
                                                                    selectedValues.forEach(selectedElement => {
                                                                        if(selectedElement === element.value) 
                                                                            newValues.push(element.key)
                                                                    })
                                                                })
                                                                value.updateIndividualWithoutEvent('statesWithLicensure', newValues)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    required
                                                                    name="stateWithLicensure"
                                                                    {...params}
                                                                    label="State with Licensure"
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </form>
                            )
                        }
                    </Formik>
                )
            }
        </IndividualsContext.Consumer>
    )
}

export default Access;